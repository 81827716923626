import React from 'react';
import { List, Datagrid, TextField, Filter, ReferenceInput, AutocompleteInput, EditButton,
    DateField,
    BooleanInput,
    BooleanField,
    downloadCSV
    
} from 'react-admin';
import {SetDeceasedButton} from './set-deceased-button'
import {unparse as convertToCSV } from 'papaparse/papaparse.min'


const exporter = residents => {
    const residentsForExport = residents.map(resident => {
        // add a field from an embedded resource
        resident.facility = resident.facility.name
        return resident;
    });
    const csv = convertToCSV({
        data: residentsForExport,
        // select and order fields in the export
        fields: ["nameOrId", "facility", "deceased", "hasUnceasedAms", "hasUnceasedPsa", "lastCr", "lastPsaUpdate", "underChemicalRestraint" ]
    });
    downloadCSV(csv, 'residents'); // download as 'posts.csv` file
}

const FacilityFilter = (props) => {
    return(
    <Filter {...props}>
        {/* <TextInput label="Search" source="q" alwaysOn /> */}
        <ReferenceInput label="Organisation" source="organisationId" reference="organisations" >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
         <ReferenceInput label="Facility" source="facilityId" reference="facilities" >
             <AutocompleteInput optionText="name" />
         </ReferenceInput>
         <BooleanInput label="Under chem restraint" source="underRestraint" />
         <BooleanInput label="Show discharged/deceased" source="showDeceased" alwaysOn />
    </Filter>
)}

const BulkActionButtons = props => (
    <React.Fragment>
        <SetDeceasedButton {...props} />
    </React.Fragment>
)

export const ResidentListed = props => (
    <List {...props} 
        empty={false}
        bulkActionButtons={<BulkActionButtons />}
        title="Resident management"
        filters={<FacilityFilter/>}
        exporter={exporter}
        sort={{field: "nameOrId", order: "ASC"}}
        >
        <Datagrid>
            <TextField source="nameOrId" sortable={true} label="Name or ID"/>
            <DateField source="deceased" label="Date deceased/discharged" />
            <TextField source="facility.name" sortable={false} label="Facility" />
            <TextField source="facility.organisation.name" sortable={false} label="Organisation" />
            <DateField source="lastCr" label="Last CR Review" />
            <DateField source="lastPsaUpdate" label="Last PSA Update" />
            <BooleanField source="hasUnceasedAms" sortable={false}/>
            <BooleanField source="hasUnceasedPsa" sortable={false}/>
            <BooleanField source="underChemicalRestraint" sortable={false}/>
            <EditButton />
        </Datagrid>
    </List>
);