import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
  DateField,
  BooleanInput,
  BooleanField,
  CardActions,
} from "react-admin";
import NewCrField from "./create-cr-field";
import EmailButton from "../../components/email-dialog";

const PostActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
}) => (
  <div>
    <CardActions>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <EmailButton
        facId={filterValues.facilityId}
        disabled={!filterValues.facilityId}
        subject="Chemical restraint reviews updated"
        // body="Chemical restraint reviews have been updated in PPASS-3, please login to view these changes and action where required.&#13;&#10;https://ppass3.choiceagedcare.com.au&#13;&#10;Information can be found here: https://drive.google.com/file/d/1c-QHh2eiyOtcE8kUWQ368q5k1BqCiajZ/view?usp=sharing"
        body="Chemical restraint reviews have been updated in PPASS-3, please login to view: https://ppass3.choiceagedcare.com.au
            &#13;&#10;&#13;&#10;
            Residents on chemical restraint may also be referred for a comprehensive residential medication management review (RMMR). A referral form is available here: https://drive.google.com/file/d/1n7N8A6ZTlHnKjuYw0qCvcpt8hHJ7Kr96/view?usp=sharing
            &#13;&#10;&#13;&#10;
            Additional information on these chemical restraint reviews can be found here: https://drive.google.com/file/d/1c-QHh2eiyOtcE8kUWQ368q5k1BqCiajZ/view?usp=sharing"
      />
    </CardActions>
  </div>
);

const FacilityFilter = (props) => {
  return (
    <Filter {...props}>
      {/* <TextInput label="Search" source="q" alwaysOn /> */}
      <ReferenceInput
        label="Organisation"
        source="organisationId"
        reference="organisations"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Facility"
        source="facilityId"
        reference="facilities"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput
        alwaysOn
        label="Under chem restraint"
        source="underRestraint"
      />
      <BooleanInput
        alwaysOn
        label="Show discharged/deceased"
        source="showDeceased"
      />
      <BooleanInput
        alwaysOn
        label="Only residents with unceased PSA"
        source="hasUnceasedPsa"
      />
    </Filter>
  );
};

export const CrCreateListed = (props) => (
  <List
    {...props}
    title="Chemical Restraint Create"
    filters={<FacilityFilter />}
    exporter={false}
    filterDefaultValues={{
      underRestraint: true,
      showDeceased: false,
      hasUnceasedPsa: true,
    }}
    actions={<PostActions />}
  >
    <Datagrid>
      <TextField source="nameOrId" label="Name or ID" />
      <TextField source="facility.name" sortable={false} label="Facility" />
      <TextField
        source="facility.organisation.name"
        sortable={false}
        label="Organisation"
      />
      <DateField source="lastCr" label="Last CR Review" />
      <DateField source="lastPsaUpdate" label="Last PSA Update" />
      <BooleanField source="underChemicalRestraint" sortable={false} />
      <NewCrField source="resident.nameOrId" label="Create CR" />
    </Datagrid>
  </List>
);
