import {
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
  Grid,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const TdontdItemHeader = ({
  resident,
  audit,
  user,
  dispatch,
  catheter,
  racsId,
}) => {
  const classes = useStyles();
  return (
    <div style={{ width: "350px", padding: "10px" }}>
      <Typography>Resident: {resident.nameOrId}</Typography> <br />
      <Typography>Date audit: {audit.auditDate}</Typography>
      <br />
      <Typography>Audit by: {user.firstName + " " + user.lastName}</Typography>
      <br />
      <TextField
        style={{ marginTop: "8px", marginBottom: "4px" }}
        variant="filled"
        fullWidth
        label="Your designation"
        value={user.designation || ""}
        onChange={(e) =>
          dispatch({
            type: "updateUser",
            payload: { designation: e.target.value },
          })
        }
      />
      <br />
      <TextField
        style={{ marginTop: "8px", marginBottom: "4px" }}
        variant="filled"
        fullWidth
        label="RACS ID"
        value={racsId || ""}
        onChange={(e) =>
          dispatch({
            type: "updateRoot",
            payload: { racsId: e.target.value },
          })
        }
      />
      <br />
      <TextField
        style={{ marginTop: "8px", marginBottom: "4px" }}
        variant="filled"
        fullWidth
        type="date"
        value={resident.dob || ""}
        label="DOB"
        onChange={(e) =>
          dispatch({
            type: "updateResident",
            payload: { dob: e.target.value },
          })
        }
        InputLabelProps={{
          shrink: true,
        }}
      />
      <br />
      <FormControl
        style={{ marginTop: "8px", marginBottom: "4px" }}
        variant="filled"
      >
        <InputLabel>Sex</InputLabel>
        <Select
          autoWidth
          style={{ width: "350px" }}
          value={resident.sex || ""}
          onChange={(e) =>
            dispatch({
              type: "updateResident",
              payload: { sex: e.target.value },
            })
          }
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
        </Select>
      </FormControl>
      <br />
      <FormControl
        style={{ marginTop: "8px", marginBottom: "4px" }}
        variant="filled"
      >
        <InputLabel>Antibiotic allergies / adverse effect</InputLabel>
        <Select
          autoWidth
          style={{ width: "350px" }}
          value={resident.antibioticAllergiesAdverseEffect || ""}
          onChange={(e) =>
            dispatch({
              type: "updateResident",
              payload: { antibioticAllergiesAdverseEffect: e.target.value },
            })
          }
        >
          <MenuItem value="Y">Yes</MenuItem>
          <MenuItem value="N">No</MenuItem>
          <MenuItem value="unknown">Unknown / not documented</MenuItem>
        </Select>
      </FormControl>
      <br />
      <TextField
        style={{ marginTop: "8px", marginBottom: "4px" }}
        variant="filled"
        fullWidth
        label="If allergy / adverse effect, nature and severity"
        value={resident.antibioticAllergyNatureSeverity || ""}
        onChange={(e) =>
          dispatch({
            type: "updateResident",
            payload: { antibioticAllergyNatureSeverity: e.target.value },
          })
        }
      />
      <br />
      <FormControl style={{ marginTop: "8px", marginBottom: "4px" }}>
        <FormHelperText>
          Did resident have a urinary catheter at onset of UTI symptoms and
          signs?
        </FormHelperText>
        <Select
          variant="filled"
          style={{ width: "350px" }}
          value={catheter || ""}
          error={!catheter}
          onChange={(e) =>
            dispatch({
              type: "updateRoot",
              payload: { catheter: e.target.value },
            })
          }
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
          <MenuItem value="Unknown">Unknown</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default TdontdItemHeader;
