import React, { useState } from "react";
import { useDataProvider, useQuery, useNotify } from "react-admin";

import { Tooltip, TextField, Button, Snackbar } from "@material-ui/core";
import { authLevel } from "../funcs/funcs";

export const NumResidentsUpdate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [numRes, setNumRes] = useState({
    numResidents: null,
    date: new Date(),
    facilityId: Number(props.facilityId),
    id: 0,
  });

  const [numResTooltip, setNumResTooltip] = useState(false);

  const { loading } = useQuery(
    {
      type: "getList",
      resource: "numresidents",
      payload: {
        pagination: {},
        sort: {},
        filter: { facilityId: Number(props.facilityId) },
      },
    },
    {
      onSuccess: ({ data }) => {
        if (data.length === 1) {
          setNumRes(data[0]);
        }
      },
    }
  );

  const update = () => {
    let tempNumRes = numRes;
    tempNumRes.id = 0;
    tempNumRes.date = new Date();
    dataProvider
      .create("numresidents", { data: tempNumRes })
      .then((resp) => {
        setNumRes(resp.data);
        notify(`Update success for number of residents`, {type: "success"});
      })
      .catch((e) => {
        notify(`Could not update number of residents`, {type: "warning"});
      });
  };

  const handleSetNumRes = (e) => {
    setNumRes({
      ...numRes,
      numResidents: Number(e.target.value),
    });
  };

  return (
    <div>
      {!loading && !numRes.id && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          message={<span>Update total residents before adding any rows</span>}
        />
      )}
      <Tooltip
        title="Enter the number of currently filled beds at the facility"
        open={numResTooltip}
      >
        <TextField
          error={
            numRes.numResidents === null ? false : !(numRes.numResidents >= 5)
          }
          onFocus={() => setNumResTooltip(true)}
          onBlur={() => setNumResTooltip(false)}
          disabled={loading}
          helperText="Number of currently admitted residents"
          type="number"
          InputProps={{ inputProps: { min: 5, max: 99999 } }}
          onChange={handleSetNumRes}
          value={numRes.numResidents || null}
        />
      </Tooltip>
      <Button
        variant="contained"
        disabled={loading || authLevel() === "external"} //TODO use loading in hook
        style={{
          marginLeft: "10px",
        }}
        onClick={update} //TODO create with hook
      >
        Update number of residents
      </Button>
    </div>
  );
};
