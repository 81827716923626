import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { Email } from "@material-ui/icons";
import { showNotification } from "react-admin";
import { connect } from "react-redux";
// import Menu from 'react-select/src/components/Menu';

class EmailDialog extends React.Component {
  state = {
    to: [],
    subject: this.props.subject,
    body: this.props.body,
    recipientSelect: [],
    sent: false,
  };
  handleClose = () => {
    this.props.onClose(true);
  };

  componentDidMount() {
    this.getRecipients();
  }

  handleChange = (key, val) => {
    this.setState({
      [key]: val,
    });
  };

  handleToChange = (e) => {
    const val = Number(e.target.value);
    const itemIndex = this.state.to.indexOf(val);
    //add to list
    if (itemIndex === -1) {
      this.setState((prevState) => ({
        to: [...prevState.to, val],
      }));
      return;
    }

    this.setState((prevState) => {
      prevState.to.splice(itemIndex, 1);
      return {
        to: prevState.to,
      };
    });
  };

  getRecipients = () => {
    const fetchUrl = `/v1/facilities/${this.props.facId}/users`;
    fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        authorization: localStorage.getItem("token"),
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then((resp) => {
        this.setState({
          recipientSelect: resp,
        });
      })
      .catch((err) => console.error(err));
  };

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">Notify of changes</DialogTitle>

        <DialogContent>
          {/* <FormControl component="fieldset" className={classes.formControl}> */}
          <FormControl component="fieldset">
            <FormHelperText>Recipients</FormHelperText>
            <FormGroup>
              {this.state.recipientSelect.map((user) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.to.includes(user.id)}
                      value={user.id}
                      onChange={this.handleToChange}
                    />
                  }
                  label={user.name}
                />
              ))}
            </FormGroup>
          </FormControl>
          <div>
            <TextField
              style={{ width: "500px" }}
              label="Subject"
              value={this.state.subject}
              required
              onChange={(e) => this.handleChange("subject", e.target.value)}
            />
          </div>

          <div style={{ paddingTop: "15px" }}>
            <TextField
              label="Message"
              style={{ width: "500px", whiteSpace: "pre-line" }}
              value={this.state.body}
              multiline
              rows={5}
              variant="outlined"
              onChange={(e) => this.handleChange("body", e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="red">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!(this.state.to.length > 0 && this.state.subject)}
            onClick={() =>
              this.props.submitEmail({
                to: this.state.to,
                subject: this.state.subject,
                body: this.state.body,
              })
            }
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EmailDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

class EmailButton extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (value) => {
    this.setState({ open: false });
  };

  submitEmail = (emailObj) => {
    this.props.showNotification("Sending email...");
    this.setState({ open: false });
    const fetchUrl = `v1/email`;
    fetch(fetchUrl, {
      body: JSON.stringify(emailObj),
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        authorization: localStorage.getItem("token"),
      },
      cache: "no-cache",
    })
      .then((res) => {
        if (!res.ok) {
          this.props.showNotification("Could not send email", "warning");
          return;
        }
        this.props.showNotification("Email sent successfully", "info");
      })
      .catch((err) => {
        this.props.showNotification("Could not send email", "warning");
      });
  };

  render() {
    const { disabled, ...rest } = this.props;
    return (
      <div>
        <Button
          color="primary"
          disabled={disabled}
          onClick={this.handleClickOpen}
        >
          <Email />
          NOTIFY
        </Button>
        {this.state.open && (
          <EmailDialog
            open={this.state.open}
            onClose={this.handleClose}
            submitEmail={this.submitEmail}
            {...rest}
          />
        )}
      </div>
    );
  }
}

export default connect(null, { showNotification })(EmailButton);
