import React from "react";
import { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  EditButton,
  ShowButton,
  useGetMany,
  Button,
  showNotification,
  BooleanInput,
  CardActions,
} from "react-admin";
import { Paper, Typography } from "@material-ui/core";
import saveAs from "file-saver";

const FacilityFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Organisation"
      source="organisationId"
      reference="organisations"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Select facility"
      reference="facilities"
      source="facilityId"
      filter={{ module: "cr" }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <BooleanInput
      label="Show discharged/deceased"
      source="showDeceased"
      alwaysOn
    />
    <SelectInput
      alwaysOn
      allowEmpty={false}
      source="reviewFilter"
      label="Filter reviews"
      choices={[
        { id: "all", name: "All" },
        { id: "unacknowledged", name: "Unacknowledged" },
        { id: "acknowledged", name: "Acknowledged" },
      ]}
    />
  </Filter>
);

const BoolOnlyTrueField = (props) => {
  return !!props.record[props.source] ? (
    <span>
      <span></span>
      <svg
        style={{ width: 26, height: 26 }}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        data-testid="true"
      >
        <g>
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
        </g>
      </svg>
    </span>
  ) : (
    <></>
  );
};

const ExportReviewsButton = ({ selectedIds }) => {
  const doExport = () => {
    // const id = window.location.hash.split("/")[2]
    const fetchPath = `v1/crreviews/exportpdf?filter=${JSON.stringify({
      id: selectedIds,
    })}`;
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type": "application/pdf",
      },
      responseType: "blob",
    })
      .then((res) => {
        if (!res.ok) {
          showNotification("Could not download file", "warning");

          throw "bzzt";
        }
        return res.blob();
      })
      .then((blob) => saveAs(blob, "reviews.pdf"))
      .catch((e) => {
        showNotification("Could not download file", "warning");
      });
  };

  return <Button onClick={doExport} label="Export" />;
};

const BulkActionButtons = (props) => (
  <Fragment>
    <ExportReviewsButton label="Export" {...props} />
  </Fragment>
);

export const CrListed = (props) => (
  <>
    <Paper style={{ padding: "10px", marginBottom: "10px" }}>
      <Typography>
        <div>
          Please note that the Chemical Restraint Reviews are based on
          information recorded in the PPASS-3 self-assessment, there may be
          additional information available that may alter the relevance of the
          recommendations.
        </div>
      </Typography>
    </Paper>
    <List
      {...props}
      bulkActionButtons={<BulkActionButtons />}
      filters={<FacilityFilter />}
      exporter={false}
      filterDefaultValues={{ reviewFilter: "unacknowledged" }}
    >
      <Datagrid>
        <DateField source="createdAt" sortable={false} />
        <TextField source="facility.name" label="Facility" sortable={false} />
        <TextField
          source="facility.organisation.name"
          label="Organisation"
          sortable={false}
        />
        <TextField
          source="resident.nameOrId"
          label="Name/ID"
          sortable={false}
        />
        <BoolOnlyTrueField
          source="isComplete"
          label="Acknowledged"
          sortable={false}
        />
        <EditButton />
      </Datagrid>
    </List>
  </>
);
