import React from "react";
import { Bar, Line } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Paper } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import { Print, KeyboardArrowDown } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { Title } from "react-admin";

export class PolyResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auditSections: [],
      sections: [],
      graphs: [],
      date: "",
      facility: {
        name: "",
        organisation: {
          name: "",
        },
      },
    };
  }

  componentDidMount() {
    const facId = window.location.hash.split("/")[2];
    const fetchUrl = `/v1/polyresult/${facId}`;
    fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        authorization: localStorage.getItem("token"),
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then((resp) => {
        this.setState(resp);
      })
      .catch((err) => console.error(err));
  }

  generateGraph(graphData, index, graphType) {
    // colour consistency between regular/prn graphs
    let customColour = [];
    if (index === 0) {
      //prn ap
      customColour = [
        "rgba(255, 99, 132, 0.6)",
        "rgba(54, 162, 235, 0.6)",
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
        "rgba(255, 99, 132, 0.6)",
        "rgba(54, 162, 235, 0.6)",
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
      ];
    } else {
      customColour = [
        "rgba(255, 99, 132, 0.6)",
        // 'rgba(54, 162, 235, 0.6)',
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
        "rgba(255, 99, 132, 0.6)",
        "rgba(54, 162, 235, 0.6)",
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
      ];
    }

    const data = {
      labels: [], //replaced
      datasets: [
        {
          data: [], //replaced
          backgroundColor: customColour,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "", //replaced
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    let newData = JSON.parse(JSON.stringify(data));

    let newOptions = JSON.parse(JSON.stringify(options));

    newData.labels = graphData.labels;
    newData.datasets[0].data = graphData.data;
    newData.datasets[0].lineTension = 0;
    newOptions.title.text = graphData.title;

    if (graphData.type === "bar") {
      return (
        <Bar data={newData} options={newOptions} width={400} height={400} />
      );
    } else {
      newData.datasets[0].backgroundColor = "rgba(255, 99, 132, 0.6)";
      return (
        <Line data={newData} options={newOptions} width={400} height={400} />
      );
    }
  }

  handleShow = (index) => {
    this.setState((prevState) => ({
      [index]: !prevState[index],
    }));
  };

  render() {
    const isPrint =
      window.location.hash.split("/")[
        window.location.hash.split("/").length - 1
      ] === "print";
    return (
      <div>
        <Title title="Polypharmacy results" />
        {!isPrint && (
          <Tooltip title="printer friendly version">
            <Print
              onClick={() => {
                const newUrl = window.location.hash.replace("#", "") + "/print";

                this.props.history.push(newUrl);
              }}
            />
          </Tooltip>
        )}

        <List>
          <ListItem>
            <ListItemText
              primary="Date"
              secondary={this.state.date && this.state.date.substring(0, 10)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Facility"
              secondary={this.state.facility.name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Organisation"
              secondary={this.state.facility.organisation.name}
            />
          </ListItem>
        </List>

        <Paper
          elevation={isPrint ? 0 : 2}
          style={{
            display: "inline-block",
            clear: "both",
            padding: "10px",
            margin: "5px",
          }}
        >
          <Typography variant="title" style={{ cursor: "pointer" }}>
            Polypharmacy Results
            <KeyboardArrowDown style={{ marginBottom: "-5px" }} />
          </Typography>

          <Grid>
            {this.state.graphs.map((dataRow, index) => (
              <>
                <div style={{ width: "300px", float: "left", padding: "10px" }}>
                  {this.generateGraph(dataRow, index)}
                </div>
              </>
            ))}
          </Grid>
          <br />
        </Paper>
      </div>
    );
  }
}
