import { TextField, List, Datagrid, DateField, EditButton } from "react-admin";
import React from "react";
import { TopToolbar } from "react-admin";
import { Button } from "react-admin";
import { useRedirect } from "react-admin";
import NameConcat from "../../components/NameConcat";

export const UtiListed = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="UTI Pathway"
      exporter={false}
      empty={false}
    >
      <Datagrid rowClick="edit">
        <TextField
          source="residentName"
          sortable={false}
          label="Resident"
        />
        <TextField source="facility.name" sortable={false} label="Facility" />
        <DateField source="createdAt" sortable={false} label="Created" />
        <TextField source="userFullName" sortable={false} label="Created by" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
