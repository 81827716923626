import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  ShowButton,
} from "react-admin";

const FacilityFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Organisation"
      source="organisationId"
      reference="organisations"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Facility"
      source="facilityId"
      reference="facilities"
      filter={{ module: "microbe" }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const CustShowButton = (props) =>
  props.record.locked ? <ShowButton {...props} /> : <></>;
const CustEditButton = (props) => {
  if (localStorage.getItem("level") === "super") {
    return <EditButton {...props} />;
  }
  return props.record.locked ? <></> : <EditButton {...props} />;
};

export const MicrobeListed = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="Microbe"
      exporter={false}
      filters={<FacilityFilter />}
      // actions={<PostActions/>}
    >
      <Datagrid>
        <DateField
          source="dateSampleCollection"
          label="Sample date"
          sortable={false}
        />
        <TextField source="facility.name" label="Facility" sortable={false} />
        <TextField
          source="facility.organisation.name"
          label="Organisation"
          sortable={false}
        />
        <CustShowButton {...props} />
        <CustEditButton {...props} />
      </Datagrid>
    </List>
  );
};

// export default connect(null, {
//     push,
// })(ReportButton);
