import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EmailField,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  TextInput,
  downloadCSV,
  BooleanInput,
  Button,
  BulkExportButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
  CreateButton,
} from "react-admin";

import { unparse as convertToCSV } from "papaparse/papaparse.min";
import DisabledField from "./disabled-field";
import { TopToolbar } from "ra-ui-materialui";
// import { Button } from "react-admin";

const FacilityFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <ReferenceInput
        label="Organisation"
        source="organisationId"
        reference="organisations"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Facility"
        source="facilityId"
        reference="facilities"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput label="Show disabled users" source="showDisabled" />
      <BooleanInput label="Show super users" source="showSuper" />
    </Filter>
  );
};

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  const { total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {cloneElement(filters, { context: "button" })}
      <CreateButton basePath="/users" />
      <ExportButton disabled={total === 0} maxResults={99999} />
      {/* Add your custom actions */}
    </TopToolbar>
  );
};

const exporter = (posts) => {
  const postsForExport = posts.map((post) => {
    // add a field from an embedded resource
    post.facilities = post.facilities.map((fac) => fac.name).join(", ");
    post.organisationName = post.organisation ? post.organisation.name : "";
    return post;
  });
  const csv = convertToCSV({
    data: postsForExport,
    // select and order fields in the export
    fields: [
      "email",
      "facilities",
      "firstName",
      "lastName",
      "organisationName",
      "level",
      "disabled",
    ],
  });
  downloadCSV(csv, "users"); // download as 'posts.csv` file
};

const BulkActionButtons = (props) => <BulkExportButton {...props} />;

export const Users = (props) => {
  const level = localStorage.getItem("level");
  return (
    <List
      {...props}
      title="Users"
      actions={<ListActions />}
      exporter={level === "super" ? exporter : false}
      filters={<FacilityFilter />}
      bulkActionButtons={<BulkActionButtons {...props} />}
    >
      <Datagrid>
        <DisabledField source="disabled" sortable={false} />
        <TextField source="firstName" sortable={false} />
        <TextField source="lastName" sortable={false} />
        <EmailField source="email" sortable={false} />
        <DateField source="lastActive" sortable={false} showTime />
        <ArrayField source="facilities" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <TextField
          source="organisation.name"
          sortable={false}
          label="Organisation"
        />
        <TextField source="level" sortable={false} />
        {window.localStorage.getItem("level") === "super" &&
          window.localStorage.getItem("pharmacist") !== "true" && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};
