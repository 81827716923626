import React from "react";
import { BooleanInput } from "react-admin";
import { FormDataConsumer } from "react-admin";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Done } from "@material-ui/icons";

const CatheterTable = (props) => {
  const hasCatA = (formData) => {
    return (
      formData.fever || formData.flank || formData.rigors || formData.confusion
    );
  };
  const hasNoSigns = (formData) => {
    return formData.noSigns;
  };
  return (
    <div {...props}>
      <Typography variant="h4" gutterBottom>
        Assessment
      </Typography>
      <Table>
        <FormDataConsumer>
          {({ formData }) => (
            <TableBody>
              <TableRow>
                <TableCell>
                  NEW or WORSE problem with no other reason found in resident
                  with catheter
                </TableCell>
                <TableCell>Select if present</TableCell>
                <TableCell>Interpretation in resident with catheter</TableCell>
                <TableCell>Interpretation</TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ backgroundColor: "rgb(211, 211, 211)" }}
                >
                  Category A
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Fever (≥38°or {">"}1.5° above usual temperature)
                </TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="fever"
                    disabled={formData.noSigns}
                  />
                </TableCell>
                <TableCell rowSpan={4}>
                  <Typography>If one checked:</Typography>
                  <Typography>
                    <u>UTI possible</u>, for further investigation and
                    management.
                  </Typography>
                </TableCell>
                <TableCell rowSpan={4}>
                  {hasCatA(formData) && <Done fontSize="large" />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Flank, loin, kidney pain or tenderness</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="flank"
                    disabled={formData.noSigns}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Rigors, chills, shivering (even if infection at another site
                  possible)
                </TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="rigors"
                    disabled={formData.noSigns}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Confusion, agitation (new or worse)</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="confusion"
                    disabled={formData.noSigns}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ backgroundColor: "rgb(211, 211, 211)" }}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>No signs or symptoms</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="noSigns"
                    disabled={hasCatA(formData)}
                  />
                </TableCell>
                <TableCell>
                  UTI unlikely. Do not perform urine Dipstick. Consider other
                  causes of symptoms. If concerned contact GP as usual and
                  monitor resident for changes.
                </TableCell>
                <TableCell>
                  {hasNoSigns(formData) && <Done fontSize="large" />}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </FormDataConsumer>
      </Table>
    </div>
  );
};

export default CatheterTable;
