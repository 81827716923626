import MaskedInput from 'react-text-mask'
import React from 'react'

const TextMaskCustom = (props) => {
    const {inputRef, ...other} = props

    return (
        <MaskedInput 
            {...other}
            ref={inputRef}
            mask={[/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,'-',/\d/,/\d/ ]}
            placeholderChar={'\u2000'}
            placeholder="YYYYMMDD"
            showMask
            guide={false}
            keepCharPositions={false}
            style={{width: "105px"}}
            minLength={4}
        />
    )
}

export default TextMaskCustom