

import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { Link, useLocation } from 'react-router-dom';

import {
    Typography
} from '@material-ui/core'

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ReCAPTCHA from "react-google-recaptcha";

import banner from '../files/banner.png'
import privacy from '../files/PPASS-3 privacy policy.pdf'
import terms from '../files/PPASS-3 terms and conditions.pdf'

import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { lightTheme } from '../themes';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: 'url(https://source.unsplash.com/random/1600x900)',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        backgroundColor: '#F0F0F0',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    username?: string;
    password?: string;
    reCaptcha?: string;
}

const { Form } = withTypes<FormValues>();
// const recaptchaRef = React.createRef();
const Login = () => {
    const [loading, setLoading] = useState(false);
    // const [reCaptcha, setReCaptcha] = useState("")
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation<{ nextPathname: string } | null>();
    const attempts = Number(localStorage.getItem("attempts"))
    
    const recaptchaRef:any = React.useRef({})

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                        {type: "warning"}
                );
            }
        )
        // @ts-ignore
        if (typeof recaptchaRef.current.reset !== "undefined") { 
            recaptchaRef.current.reset()
        }
        // if (recaptchaRef.current) {
        //     recaptchaRef.current.reset()
        // }
        
    };

    

    // const setCaptchaRef = (ref: any) => {
    //     if (ref) {
    //       return captcha = ref;
    //     }
    //  };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };
    // @ts-ignore
    let captcha;

    return (
        <div className={classes.main}>
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <img src={banner} />
                </div>
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className={classes.form}>
                        <Typography style={{width: "300px"}}>
                        There has been a recent change to the way you login, <span style={{color: "red"}}> sign in with your email address instead of username</span>
                        </Typography>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                name="username"
                                // @ts-ignore
                                component={renderInput}
                                label={"Email address"}
                                disabled={loading}
                            />
                        </div>
                        <div className={classes.input} style={{paddingBottom: "20px"}}>
                            <Field
                                name="password"
                                // @ts-ignore
                                component={renderInput}
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                            />
                        </div>
                        <Field 
                            name="reCaptcha" 
                        >
                            {({ input, meta }) => {
                                return attempts >= 5 && (
                                    <ReCAPTCHA
                                    // @ts-ignore
                                    ref={recaptchaRef}
                                    sitekey="6Lfbp_wUAAAAAFcDrR8Owko9z36Ck2gpaE7zpQT7"
                                    // @ts-ignore
                                    onChange={input.onChange}                   
                                    />
                                )
                            }}
                        </Field>
            
                    
                        

                    </div>
                    <CardActions className={classes.actions}>
                        <Button
                            // variant="raised"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            // className={classes.button}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                    <Link to="forgotpw" style={{width: "100%", textAlign: 'center', paddingBottom: "10px"}}><Typography>Forgot password</Typography></Link>
                </form>
            )}
            />
            
                
                </Card>

                <div style={{marginTop: "20px", marginBottom: "20px"}}>
                <Link to="tdontd-external-login" style={{textDecoration: "none"}}>
                <Button variant="outlined">
                    TDONTD Pathway access
                </Button>
                </Link>
            </div>
                {/* <Link to="forgotpw"><Typography>Forgot password</Typography></Link> */}
                <div style={{bottom: "0", height: "2.5rem", textAlign: "center"}}>
                    <Typography>By using this software you agree to the <a href={privacy} download>Privacy Policy</a> and <a href={terms} download>Terms of Service</a></Typography>
                    <Typography>inbox@ppass3.choiceagedcare.com.au or 1300 275 908 for support</Typography>
                
                </div>
                
                <Notification />
            </div>

    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);


export default LoginWithTheme