import React, { useEffect, useReducer, useState } from "react";
import { SelectInput } from "react-admin";

import { Table, Paper, TextField, Button, Typography } from "@material-ui/core";
import { ResourceSelect } from "../../components/ResourceSelect";
import { ResidentEdit } from "../resident/resident-edit";
import TdontdTableAll from "./tdontd-table-all";
import TdontdTableUti from "./tdontd-table-uti";
import { useNotify, useRedirect } from "ra-core";
import { useHistory } from "react-router";
import TdontdItemHeader from "./tdontd-item-header";
import { authLevel } from "../../funcs/funcs";

function reducer(state, action) {
  switch (action.type) {
    case "utiRowVal":
      //action.index
      //action.payload
      return {
        ...state,
        rowsUti: [
          ...state.rowsUti.map((row, i) =>
            i === action.index ? { ...row, ...action.payload } : row
          ),
        ],
      };

    case "allRowVal":
      return {
        ...state,
        rowsAll: [
          ...state.rowsAll.map((row, i) =>
            i === action.index ? { ...row, ...action.payload } : row
          ),
        ],
      };

    case "setInit":
      return {
        ...state,
        racsId: action.payload.racsId,
        audit: action.payload.audit,
        resident: action.payload.resident,
        user: action.payload.user,
        rowsAll: action.payload.rows,
        rowsUti: action.payload.rows.filter((row) => row.siteId === 13),
      };

    case "save":
      return { ...state };

    case "updateRoot":
      return {
        ...state,
        ...action.payload,
      };
    case "updateResident":
      return {
        ...state,
        resident: {
          ...state.resident,
          ...action.payload,
        },
      };
    case "updateUser":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
  }
}

const date = new Date();
const offset = date.getTimezoneOffset();
const now = new Date(date.getTime() - offset * 60 * 1000);

const initialState = {
  facility: {
    name: "",
    racsId: "",
  },
  resident: {},
  audit: {
    auditDate: "",
  },
  user: {},
  rowsUti: [],
  rowsAll: [],
};

export const TdontdItemCreate = (props) => {
  const record = props.location.state.record;

  const [state, dispatch] = useReducer(reducer, initialState);
  const { goBack } = useHistory();
  const notify = useNotify();

  useEffect(() => {
    getData();
  }, []);

  const handleSave = () => {
    fetch("/v1/tdontd-item", {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(state),
    })
      .then((resp) => {
        if (!resp.ok) {
          notify("Could not save", {type: "warning"});
        } else {
          notify("Success", {type: "success"});
          goBack();
        }
      })
      .catch((e) => {
        notify("Could not save", {type: "warning"});
      });
  };

  const getData = () => {
    //using audit date
    fetch(
      `/v1/residents/${record.resident.id}/tdontdItemInit?auditId=${record.tdontdAuditId}`,
      {
        method: "GET",
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }
    )
      .then((resp) =>
        resp.json().then((data) => {
          //strip ids
          data.rows = data.rows.map((row) => {
            row.signsSymptoms = "[]";
            row.startDate = row.startDate.split("T")[0];
            row.amsRowId = row.id;
            if (row.siteId !== 13) {
              row.siteDetails = "";
            } else {
              row.siteDetails = row.indication.name;
            }
            row.frequency = "Daily";
            delete row.id;
            return row;
          });
          //process results
          dispatch({ type: "setInit", payload: data });
          // dispatch({});
        })
      )
      .catch((e) => {
        //do something
      });
  };

  return (
    <div>
      <Paper>
        <TdontdItemHeader
          audit={state.audit}
          dispatch={dispatch}
          resident={state.resident}
          user={state.user}
          racsId={state.racsId}
          catheter={state.catheter}
        />
        <br />
        <Typography variant="h5" style={{ paddingLeft: "5px", color: "red" }}>
          This audit uses a snapshot of your AMS values. If any of the
          pre-filled information below appears incorrect, update AMS before
          proceeding
        </Typography>

        <br />
        <TdontdTableAll rows={state.rowsAll} dispatch={dispatch} />
        <br />
        <br />
        {state.rowsUti.length > 0 && (
          <>
            <TdontdTableUti rows={state.rowsUti} dispatch={dispatch} />
            <br />
            <br />
          </>
        )}
        <Button
          disabled={authLevel() === "external"}
          style={{ margin: "10px" }}
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </Paper>
    </div>
  );
};
