import React, {useEffect, useState} from 'react'
import {Typography} from '@material-ui/core'

export const FacName = ({facId}) => {
    const [fac, setFac] = useState({name: ""})
    useEffect(() => {
        let fetchPath = `v1/facilities/${facId}`

        fetch(fetchPath, {
            method: "GET",
            headers: {
                "authorization": localStorage.getItem("token")
            },
        }).then(res => res.json()
        ).then(resJson => {
            setFac(resJson)
        }).catch(e => {
            return 
        })

    }, [])


    return <Typography variant="h5" gutterBottom>{fac.name}</Typography>
}