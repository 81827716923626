import React, { Component } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // see installation section above for versions of NPM older than 3.0.0
// If you choose not to use import, you need to assign Cropper to default
// var Cropper = require('react-cropper').default
// import { addField } from 'react-admin';
import { Field } from "react-final-form";

// const cropper = React.createRef(null);

class renderImageCropper extends Component {
  constructor(props) {
    super(props);
    this.state = { src: "" };
  }

  cropImage = (e) => {
    e.preventDefault();

    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    this.props.input.onChange(
      this.cropper.getCroppedCanvas({ maxWidth: 300 }).toDataURL()
    );
  };

  render(e) {
    return (
      <div>
        <Cropper
          // ref={cropper}
          ref={(cropper) => {
            this.cropper = cropper;
          }}
          src={this.props.input.value}
          style={{ height: 200, width: 400 }}
          // Cropper.js options
          aspectRatio={2 / 1}
          guides={false}
        />
        <button onClick={this.cropImage}>Crop</button>
      </div>
    );
  }
}

const ImageCropper = (props) => (
  <Field name={props.source} component={renderImageCropper} {...props} />
);

export default ImageCropper;
