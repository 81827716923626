import React from 'react';
import { AppBar } from 'react-admin';
import {
    Typography,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help'
import {ExitToApp} from '@material-ui/icons'
import guide from './files/PPASS-3 user guide.pdf'

// const useStyles = {
//     title: {
//         flex: 1,
//         textOverflow: 'ellipsis',
//         whiteSpace: 'nowrap',
//         overflow: 'hidden',
//     },
//     spacer: {
//         flex: 1,
//     },

// };
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const CustAppBar = props => {
    const classes = useStyles();
    return (
    <AppBar {...props}>
        <Typography
            variant="title"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        <a href="https://www.choiceagedcare.com.au" download target="_blank" rel="noopener noreferrer" style={{color: "inherit", "textDecoration": "inherit"}}><Button color="default" style={{color: "white"}}><ExitToApp /><span color="white">CAC Home</span></Button></a>
        <a href="https://buddybook.com.au/wp-login.php?redirect_to=https%3A%2F%2Fbuddybook.com.au%2F" download target="_blank" rel="noopener noreferrer" style={{color: "inherit", "textDecoration": "inherit"}}><Button color="default" style={{color: "white"}}><ExitToApp /><span color="white">BuddyBook</span></Button></a>
        <a href={guide} download target="_blank" rel="noopener noreferrer" style={{color: "inherit", "textDecoration": "inherit"}}><Button mini color="secondary" style={{color: "white"}}><HelpIcon /></Button></a>
    </AppBar>
)};

export default CustAppBar;