import React from "react";
import { BooleanInput } from "react-admin";
import { FormDataConsumer } from "react-admin";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Zoom,
} from "@material-ui/core";
import { Done } from "@material-ui/icons";

const NoCatheterTable = (props) => {
  const hasCatA = (formData) => {
    return formData.dysuria;
  };
  const hasBothCatB = (formData) => {
    return formData.fever && formData.confusion;
  };
  const hasCatB = (formData) => {
    return formData.fever || formData.confusion;
  };

  const hasOneCatB = (formData) => {
    //xor
    return (
      (formData.fever && !formData.confusion) ||
      (!formData.fever && formData.confusion)
    );
  };

  const hasCatC = (formData) => {
    return (
      formData.frequency ||
      formData.urgency ||
      formData.incontinence ||
      formData.flank ||
      formData.pain ||
      formData.blood
    );
  };

  const hasCatCOnly = (formData) => {
    return (
      hasCatC(formData) &&
      !hasCatA(formData) &&
      !hasCatB(formData) &&
      !hasNoSigns(formData)
    );
  };

  const hasNoSigns = (formData) => {
    return formData.noSigns;
  };

  const hasExceptSigns = (formData) => {
    return hasCatA(formData) || hasCatB(formData) || hasCatC(formData);
  };

  return (
    <div {...props}>
      <Typography variant="h4" gutterBottom>
        Assessment
      </Typography>
      <Table size="small" style={{ width: "1000px" }}>
        <FormDataConsumer>
          {({ formData }) => (
            <TableBody>
              <TableRow>
                <TableCell>
                  NEW or WORSE problem with no other reason found in resident
                  without catheter
                </TableCell>
                <TableCell>Select if present</TableCell>
                <TableCell>
                  Interpretation in resident without catheter
                </TableCell>
                <TableCell>Interpretation</TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ backgroundColor: "rgb(211, 211, 211)" }}
                >
                  Category A
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Dysuria, pain or burning on passing urine</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="dysuria"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
                <TableCell>
                  <Typography>If one checked:</Typography>
                  <Typography>
                    <u>UTI possible</u>, for further investigation and
                    management.
                  </Typography>
                </TableCell>
                <TableCell>
                  {hasCatA(formData) && (
                    <Zoom in>
                      <Done fontSize="large" />
                    </Zoom>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ backgroundColor: "rgb(211, 211, 211)" }}
                >
                  Category B
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Fever (≥38°or {">"}1.5° above usual temperature)
                </TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="fever"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
                <TableCell rowSpan={2}>
                  <Typography>If both checked:</Typography>
                  <Typography>
                    <u>UTI possible</u>, for further investigation and
                    management.
                  </Typography>
                </TableCell>
                <TableCell rowSpan={2}>
                  {hasBothCatB(formData) && <Done fontSize="large" />}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Confusion, agitation</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="confusion"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ backgroundColor: "rgb(211, 211, 211)" }}
                >
                  Category C
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Frequency on passing urine</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="frequency"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
                <TableCell rowSpan={3}>
                  <Typography>
                    If one of Category B and one or more of Category C:
                  </Typography>
                  <Typography>
                    <u>UTI possible</u>, for further investigation and
                    management.
                  </Typography>
                </TableCell>
                <TableCell rowSpan={3}>
                  {hasOneCatB(formData) && hasCatC(formData) && (
                    <Done fontSize="large" />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Urgency on passing urine</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="urgency"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Urinary incontinence</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="incontinence"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Flank, loin, kidney pain or tenderness</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="flank"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
                <TableCell rowSpan={3}>
                  <Typography>If Category C only:</Typography>
                  <Typography>
                    Consider other causes as well as UTI. Do not perform urine
                    Dipstick. If concerned contact GP as usual and monitor
                    resident for changes.
                  </Typography>
                </TableCell>
                <TableCell rowSpan={3}>
                  {hasCatCOnly(formData) && <Done fontSize="large" />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Low abdominal pain</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="pain"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Visible blood in urine</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="blood"
                    disabled={hasNoSigns(formData)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ backgroundColor: "rgb(211, 211, 211)" }}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>No signs or symptoms</TableCell>
                <TableCell>
                  <BooleanInput
                    label=""
                    source="noSigns"
                    disabled={hasExceptSigns(formData)}
                  />
                </TableCell>
                <TableCell>
                  UTI unlikely. Do not perform urine Dipstick. Consider other
                  causes of symptoms. If concerned contact GP as usual and
                  monitor resident for changes.
                </TableCell>
                <TableCell>
                  {hasNoSigns(formData) && <Done fontSize="large" />}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </FormDataConsumer>
      </Table>
    </div>
  );
};

export default NoCatheterTable;
