import {
  TextField,
  List,
  Datagrid,
  DateField,
  ShowButton,
  Button,
  Link,
} from "react-admin";
import React from "react";
import NameConcat from "../../components/NameConcat";

export const TdontdListed = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="UTI Pathway"
      exporter={false}
      empty={false}
    >
      <Datagrid>
        <TextField source="facility.name" sortable={false} label="Resident" />
        <DateField source="auditDate" sortable={false} />
        <DateField source="createdAt" sortable={false} label="Created" />
        <NameConcat source="user" label="Created by" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
