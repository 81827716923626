import React, { useState } from "react";
import { ACTIONS } from "./ams";
import {
  TableRow,
  TableCell,
  Collapse,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Add, Clear } from "@material-ui/icons";
import { AmsRowInputs } from "./ams-row-inputs";
import { authLevel } from "../../funcs/funcs";

export const AmsStaging = ({ row, dispatch, submitNewRow }) => {
  const handleChange = (changeObj) => {
    dispatch({ type: ACTIONS.EDIT_ROW, payload: { ...row, ...changeObj } });
  };

  const [showInvalid, setShowInvalid] = useState(false);
  const showInvalidOrSubmit = () => {
    if (row.errors.length === 0 && row.hasChanged) {
      submitNewRow(row);
      setShowInvalid(false);
    } else {
      setShowInvalid(true);
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ backgroundColor: "WhiteSmoke" }}>
          <IconButton
            size="small"
            disabled={authLevel() === "external"}
            onClick={() => showInvalidOrSubmit(row)}
          >
            <Add />
          </IconButton>
        </TableCell>
        <TableCell style={{ backgroundColor: "WhiteSmoke" }}>
          <IconButton
            disabled={authLevel() === "external"}
            size="small"
            onClick={() =>
              dispatch({ type: ACTIONS.CLEAR, payload: { id: row.id } })
            }
          >
            <Clear />
          </IconButton>
        </TableCell>
        <AmsRowInputs
          row={row}
          handleChange={handleChange}
          cellBackground={{ backgroundColor: "WhiteSmoke" }}
        />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={19}>
          <Collapse in={showInvalid} unmountOnExit>
            {row.errors.map((msg) => (
              <Typography style={{ color: "red" }}>{msg}</Typography>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
