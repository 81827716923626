import React, { useContext, useState } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
} from "@material-ui/core";
import { ResourceSelect } from "../../components/ResourceSelect";
import { UserContext } from "../../UserContext";
import saveAs from "file-saver";

const opts = {
  ams: {
    label: "AMS",
    path: (facId) => `/v1/ams/${facId}/excel`,
    sortBy: {
      nameOrId: {
        label: "Resident name",
        defaultOrder: "asc",
      },
      medication: {
        label: "Medication name",
        defaultOrder: "asc",
      },
      startDate: {
        label: "Start date",
        defaultOrder: "desc",
      },
      ceaseDate: {
        label: "Cease date",
        defaultOrder: "desc",
      },
    },
    include: {
      showDeceased: "Deceased or discharged residents",
    },
  },
  psa: {
    label: "Self-assessment",
    path: (facId) => `/v1/psa/${facId}/excel`,
    sortBy: {
      nameOrId: {
        label: "Resident name",
        defaultOrder: "asc",
      },
      medication: {
        label: "Medication name",
        defaultOrder: "asc",
      },
      createdAt: {
        label: "Last modified",
        defaultOrder: "desc",
      },
    },
    include: {
      showDeceased: "Deceased or discharged residents",
      showCeased: "Ceased prescriptions",
    },
  },
  psaHist: {
    label: "Self-assessment resident history",
    path: (facId) => `/v1/psahist/${facId}/excel`,
    sortBy: {
      nameOrId: {
        label: "Resident name",
        defaultOrder: "asc",
      },
      medication: {
        label: "Medication name",
        defaultOrder: "asc",
      },
      lastModified: {
        label: "Last modified",
        defaultOrder: "desc",
      },
    },
    include: {
      showDeceased: "Deceased or discharged residents",
      showCeased: "Ceased prescriptions",
    },
  },
  psaCeased: {
    label: "Ceased self-assessment prescriptions",
    path: (facId) => `/v1/psaceased/${facId}/excel`,
    sortBy: {
      ceaseDate: {
        label: "Cease date",
        defaultOrder: "desc",
      },
    },
    include: {},
  },
};

export const Export = () => {
  const user = useContext(UserContext);

  const [selected, setSelected] = useState({
    facility: {
      id: 0,
      name: "",
    },
    module: "", //ams, psa etc
    filter: {}, //keys of extra information to include {deceased: true}
    sort: ["", ""], //[keyOfSort, ascOrDesc]
  });

  const doExport = () => {
    //encode uri
    const fetchPath =
      opts[selected.module].path(selected.facility.id) + selectedUrlParams();
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    })
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, `${selected.module}.xlsx`))
      .catch((e) => {});
  };

  const selectedUrlParams = () => {
    const sortPrm = JSON.stringify(selected.sort);
    return `?filter=${encodeURIComponent(
      JSON.stringify(selected.filter)
    )}&sort=${encodeURIComponent(sortPrm)}`;
  };

  const setDefaultSort = (e) => {
    const sortKey = e.target.value;
    setSelected({
      ...selected,
      sort: [sortKey, opts[selected.module].sortBy[sortKey].defaultOrder],
    });
  };

  const changeOrder = (e) => {
    setSelected({
      ...selected,
      sort: [selected.sort[0], e.target.value],
    });
  };

  const handleFacChange = (e) => {
    setSelected({
      ...selected,
      facility: {
        id: e.facility.id,
        name: e.facility.name,
      },
    });
  };

  const handleModuleChange = (e) => {
    //also set the default sort
    const module = e.target.value;
    const sortKey = Object.keys(opts[module].sortBy)[0];
    const sortOrder = opts[module].sortBy[sortKey].defaultOrder;
    setSelected({
      ...selected,
      module: module,
      filter: {},
      sort: [sortKey, sortOrder],
    });
  };

  const handleIncludeChange = (e) => {
    setSelected({
      ...selected,
      filter: {
        ...selected.filter,
        [e.target.name]: e.target.checked,
      },
    });
  };

  return (
    <div style={{ width: "300px" }}>
      <br />
      <InputLabel id="faclabel">Facility</InputLabel>
      <ResourceSelect
        labelId="faclabel"
        value={selected.facility.name}
        displayKey="name"
        idKey="facilityId"
        innerIdKey="id"
        resource="facilities"
        objKey="facility"
        clearOnBlur
        onChange={handleFacChange}
      />
      <br />
      {/* select module */}
      <InputLabel id="modulelabel">Module</InputLabel>
      <Select
        labelId="modulelabel"
        value={selected.module}
        onChange={handleModuleChange}
        fullWidth
      >
        {Object.keys(opts).map((moduleKey) => (
          <MenuItem value={moduleKey}>{opts[moduleKey].label}</MenuItem>
        ))}
      </Select>
      <br />
      <br />

      {selected.module && (
        <div>
          <InputLabel id="sortlabel">Sort data by</InputLabel>
          <Select
            labelId="sortlabel"
            value={selected.sort[0]}
            onChange={setDefaultSort}
            autoWidth
          >
            {Object.keys(opts[selected.module].sortBy).map((sortKey) => (
              <MenuItem value={sortKey}>
                {opts[selected.module].sortBy[sortKey].label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <br />

      {selected.sort[0] && (
        <FormControl component="fieldset">
          <FormLabel component="legend">Ascending or descending</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="ascOrDesc"
            value={selected.sort[1]}
            onChange={changeOrder}
          >
            <FormControlLabel
              value="asc"
              control={<Radio />}
              label="Ascending"
            />
            <FormControlLabel
              value="desc"
              control={<Radio />}
              label="Descending"
            />
          </RadioGroup>
        </FormControl>
      )}

      <br />
      <br />
      {selected.module && (
        <FormControl component="fieldset">
          <FormLabel component="legend">Additional Data</FormLabel>
          <FormGroup>
            {Object.keys(opts[selected.module].include).map((includeKey) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!selected.filter[includeKey]}
                    onChange={handleIncludeChange}
                    name={includeKey}
                  />
                }
                label={opts[selected.module].include[includeKey]}
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
      <br />
      <br />
      {!!selected.sort[0] && !!selected.facility.id && (
        <Button variant="contained" onClick={doExport}>
          Export
        </Button>
      )}
    </div>
  );
};
