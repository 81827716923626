import React from 'react';
import {
    SimpleForm,
    NumberInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    Toolbar,
    SaveButton,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    ImageInput,
    ImageField,
    Edit,
    BooleanInput,
    FormDataConsumer,
    TextInput
} from 'react-admin'
import {dateParser} from '../../funcs/funcs'
import CancelButton from '../../components/CancelButton'
import ImageCropper from '../../components/ImageCropper'
import EmailButton from '../../components/email-dialog'

export const MicrobeEdit = (props) => (
    <Edit
        title="Edit Microbe visit"
        successMessage="Microbe report saved"
        {...props}>
        <SimpleForm redirect="list" toolbar={<PolyCreateToolbar/>}>
            <ReferenceInput 
                label="Select facility" 
                source="facilityId" 
                reference="facilities" 
                validate={[required()]}
                filter={{module: "microbe"}}
            >
                <AutocompleteInput optionText="name" />

            </ReferenceInput>
            <DateInput parse={v => dateParser(v)} source="dateSampleCollection" label="Sample collection date" initialValue={new Date()} validate={[required()]}/>
            <BooleanInput label="Locked (reports won't be visible until locked)" source="locked" />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                     <EmailButton 
                        facId={formData.facilityId}
                        disabled={!formData.facilityId}
                        subject="Microbe testing results"
                        body="The report from your most recent microbe testing is now available. Please login at &#13;&#10;https://ppass3.choiceagedcare.com.au"
                    />
                }
            </FormDataConsumer>
            
            <ArrayInput source="microbeEntries">
                <SimpleFormIterator reOrderButtons={<></>}>
                <ReferenceInput 
                    label="Select microbe site" 
                    source="microbeSiteId" 
                    reference="microbesites" 
                    validate={[required()]}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <NumberInput source="incubationTimeHrs" label="Incubation time (hrs)" min={0} max={48} step={1} validate={[required()]}/>

                <ReferenceInput 
                    label="Microbe growth" 
                    source="microbeGrowthId" 
                    reference="microbegrowth" 
                    validate={[required()]}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput multiline source="comment" />
                
                <ImageInput 
                source="image" 
                label="Slide image" 
                accept="image/*" 
                placeholder={<p>Select image (or drag/drop)</p>} 
                multiple={false}
                validate={[required()]}
                >
                    <ImageField source="src" title="title" style={{display: "none"}}/>
                </ImageInput>
                <ImageCropper source="image.src"/>

                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

const PolyCreateToolbar = props => (
    <Toolbar {...props} style={{flex: 1, display: "flex", justifyContent: "space-between"}}>
        <SaveButton />
        <CancelButton />
    </Toolbar>
)