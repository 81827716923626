import React from "react";
import {
  Create,
  SimpleForm,
  NumberInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  Toolbar,
  SaveButton,
  FormDataConsumer,
} from "react-admin";
import { dateParser } from "../../funcs/funcs";
import CancelButton from "../../components/CancelButton";
import { NumResidentsUpdate } from "../../components/NumResidentsUpdate";

const NumResField = ({ record }) => {
  return <></>;
};
// record.facilityId ? <NumResidentsUpdate facilityId={record.facilityId} /> : <></>

export const PolyCreate = (props) => (
  <Create
    title="Create new polypharmacy submission"
    successMessage="Polypharmacy report saved"
    {...props}
  >
    <SimpleForm redirect="list" toolbar={<PolyCreateToolbar />}>
      <DateInput
        source="date"
        defaultValue={new Date()}
        parse={(v) => dateParser(v)}
        validate={[required()]}
      />
      <ReferenceInput
        label="Select facility"
        source="facilityId"
        reference="facilities"
        validate={[required()]}
        filter={{ module: "poly" }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return formData.facilityId ? (
            <NumResidentsUpdate facilityId={formData.facilityId} />
          ) : (
            <></>
          );
        }}
      </FormDataConsumer>
      <NumResField source="facilityId" />
      <NumberInput
        source="numResidentsReg"
        helperText="Number of residents on 9 or more regular medications"
        label="Regular"
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);

const PolyCreateToolbar = (props) => (
  <Toolbar
    {...props}
    style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
  >
    <SaveButton />
    <CancelButton />
  </Toolbar>
);
