import React, { useContext, useEffect, useState } from "react";

import {
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Collapse,
  IconButton,
  TextField,
} from "@material-ui/core";
import { PsaHistoryInputs } from "./psa-history-inputs";
import { Done, Clear } from "@material-ui/icons";
import { useNotify } from "ra-core";
import { useSafeSetState } from "react-admin";
import { isDateValid } from "../../funcs/funcs";
import TextMaskCustom from "../../components/TextMaskCustom";
import { ApiDisabledContext } from "./psa";

//try to self-contain the changes instead of mutating in the root state
export const PsaEditRow = ({ setHasUnlocked, setRows, fetchRows, ...rest }) => {
  const notify = useNotify();

  const virginRow = rest.row;
  const [row, setRow] = useState(rest.row);
  const [hasChanged, setHasChanged] = useState(false);

  const handleCancel = () => {
    setRows(
      (prev) =>
        //locked false
        prev.map((theRow) =>
          row.id === theRow.id ? { ...theRow, unlocked: false } : theRow
        )
      //hasunlocked false
    );
    setHasUnlocked(false);
  };

  const [invalidMsgs, setInvalidMsgs] = useState([]);

  

  const getInvalidMsgs = (incNameMed) => {
    let ret = [];

    //pretty dirty, but only include name/med checks for staging
    if (incNameMed) {
      if (!row.psaPrescription.resident.nameOrId) {
        ret.push("Name or Id not set");
      }

      if (!row.psaPrescription.medicationId) {
        ret.push("Medication not set");
      }
    }

    // if (row.regularDose > 0 && !row.regular) {
    //   ret.push("Reg must be selected if a daily dose is entered");
    // }

    const med = row.psaPrescription.medication;
    if (
      row.regularDose &&
      (row.regularDose < med.lowerBound || row.regularDose > med.upperBound)
    ) {
      ret.push(
        `Regular dose is out of bounds, must be between ${med.lowerBound} and ${med.upperBound}mg`
      );
    }

    if (typeof row.regularDose !== "number" && !!row.regularDose) {
      ret.push("Regular dose is not a valid number");
    }

    //has to be a number or null
    if (!(typeof row.prnNumDoses === "number" || row.prnNumDoses === null)) {
      ret.push("PRN doses is not a valid number");
    }

    if (typeof row.prnNumDays !== "number" && !!row.prnNumDays) {
      ret.push("PRN days is not a valid number");
    }

    if (!isDateValid(row.decisionDate)) {
      ret.push("Decision date is not valid");
    }

    if (!isDateValid(row.lastReviewDate)) {
      ret.push("Date of last review not valid");
    }

    setInvalidMsgs(ret);
  };

  useEffect(() => {
    getInvalidMsgs();
  }, [row]);

  const hasRowChanged = () => {
    const valsOfConcernPresc = [
      "prnNumDoses",
      "prnNumDays",
      "altRestraint",
      "participate",
      "decisionDate",
      "repsInformed",
      "freqMonitoring",
      "lastReviewDate",
      "regular",
      "regularDose",
      "prn",
      "indication",
      "indicationType",
      "behaviour",
      "informedConsentDate",
      "informedConsentHow",
      "behaviourPlanImplemented",
      "effectivenessMonitoring",
      "comments",
      "behaviourPlanDate",
      "prnCircumstance"
    ];

    //check presc vals
    for (let i = 0; i < valsOfConcernPresc.length; i++) {
      const key = valsOfConcernPresc[i];
      if (row[key] !== virginRow[key]) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    setHasChanged(hasRowChanged());
  }, [row]);

  useEffect(() => {
    setRow(rest.row);
  }, [rest.row]);

  const handleRowHistChange = (changeObj) => {
    setRow({ ...row, ...changeObj });
  };

  const [showInvalid, setShowInvalid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const showInvalidOrSubmit = () => {
    if (invalidMsgs.length === 0) {
      submit(row);
      setShowInvalid(false);
    } else {
      setShowInvalid(true);
    }
  };

  const submit = (row) => {
    row.decisionDate = !!row.decisionDate
      ? new Date(row.decisionDate).toISOString()
      : null;
    row.lastReviewDate = !!row.lastReviewDate
      ? new Date(row.lastReviewDate).toISOString()
      : null;
    row.informedConsentDate = !!row.informedConsentDate
      ? new Date(row.informedConsentDate).toISOString()
      : null;
    row.behaviourPlanDate = !!row.behaviourPlanDate
      ? new Date(row.behaviourPlanDate).toISOString()
      : null;

    setWaiting(true);

    const fetchPath = `v1/psapres/${row.psaPrescriptionId}/newHist`;
    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(row),
    })
      .then((res) => {
        setWaiting(false);
        if (!res.ok) {
          notify("Could edit row, please contact support", {type: "warning"});
          throw "";
        }
        return res.json();
      })
      .then((resp) => {
        notify("Row edited", {type: "info"});
        fetchRows();
      })
      .catch((e) => {
        setWaiting(false);
      });
  };

  const ctx = useContext(ApiDisabledContext)

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Tooltip title="Save">
            <IconButton
              size="small"
              onClick={() => hasChanged && showInvalidOrSubmit()}
              disabled={
                !hasChanged ||
                (showInvalid && invalidMsgs.length > 0) ||
                waiting
              }
            >
              <Done />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Cancel">
            <IconButton
              size="small"
              onClick={() => handleCancel(row)}
              disabled={waiting}
            >
              <Clear />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography></Typography>
        </TableCell>
        <TableCell>
          <Typography>{row.psaPrescription.resident.nameOrId}</Typography>
        </TableCell>
        <TableCell>
        <TextField 
            value={row.decisionDate}
            onChange={(e) => handleRowHistChange({decisionDate: e.target.value || null})}
            error={!isDateValid(row.decisionDate)}
            InputProps={{
                inputComponent: TextMaskCustom
            }}
            disabled={ctx.apiDisabled("decisionDate")}
          />
        </TableCell>
        <TableCell>
          <Typography>{row.psaPrescription.medication.generic}</Typography>
        </TableCell>
        <PsaHistoryInputs
          onChange={handleRowHistChange}
          indications={row.psaPrescription.medication.indications}
          {...row}
        />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={19}>
          <Collapse in={showInvalid} unmountOnExit>
            {invalidMsgs.map((msg) => (
              <Typography style={{ color: "red" }}>{msg}</Typography>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
