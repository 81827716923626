import * as React from "react";
import PropTypes from 'prop-types';
import {Check} from '@material-ui/icons'

const DisabledField = ({ source, record = {} }) => record.disabled ? <Check/> : <span></span>;

DisabledField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default DisabledField;