import React from 'react';
import { UtiCreate } from '../modules/uti/uti-create';
import { useHistory } from 'react-router';
import {useNotify} from 'react-admin';

const TdontdExternal = (props) => {
    const history = useHistory();
    const notify = useNotify()

    const fakeProps = {
        basePath: "/tdontd-external",
        hasCreate: true,
        hasEdit: false,
        hasList: false,
        hasShow: false,
        history: {},
        location: { pathname: "/", search: "", hash: "", state: undefined },
        match: { path: "/", url: "/", isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: "tdontd-external",
        external: true,
        onSuccess: () => {
            notify('Created successfully', {type: "success"});
            history.replace('/login');
            
              
        }

    }

    return (
        <UtiCreate {...fakeProps} />
    )
}

export default TdontdExternal