import React from 'react';
import {Bar, Line, HorizontalBar} from 'react-chartjs-2';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip'
import {Warning, KeyboardArrowDown, Print} from '@material-ui/icons'
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    Paper
} from '@material-ui/core'
import {Title, Loading} from 'react-admin'

export default class Landing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // resp: {}
            sections: [],
            benchmarking: [],
            prnUsage: {
                headings: [],
                rows: [[]]
            },
            actionableItems: {
                items: []
            },
            loading: true,

        }
    }

    componentDidMount() {
        // const fetchUrl = `/v1${window.location.hash.substring(1)}`
        const id = window.location.hash.split("/")[2]
        const fetchUrl = `/v1/dashboards/${id}`
        fetch(fetchUrl, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
                'Connection': 'keep-alive',
                "authorization": localStorage.getItem("token")
            },
            cache: "no-cache"
        })
        .then(res => res.json())
        .then(resp => {
            this.setState({...resp, loading: false})
        })
        .catch(err => {
            console.error(err)
            this.setState({loading: false})
        })
    }



    generateGraph(graphData, index, graphType) {
        // colour consistency between regular/prn graphs

        const customColour = [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
        ]

        const data = {
            labels: [], //replaced
            datasets: [{
                data: [], //replaced
                // backgroundColor: customColour
            }]
        }

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: '' //replaced
            },
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }

        let newData = JSON.parse(JSON.stringify(data))

        let newOptions = JSON.parse(JSON.stringify(options))

        newData.labels = graphData.labels
        // newData.datasets[0].data = graphData.data
        // newData.datasets[0].lineTension = 0
        newData.datasets = graphData.datasets

        //set colours appropriately
        if (newData.datasets.length === 1) { //no grouping, array of colours
            newData.datasets[0].backgroundColor = customColour
            newOptions.legend.display = false
        } else if (newData.datasets.length > 1) { //grouping, iterate over datasets and set single (different) colour for each
            newOptions.legend.display = true
            newData.datasets.forEach((set, i) => {
                newData.datasets[i].backgroundColor = customColour[i]
            })
        }

        newOptions.title.text = graphData.title

        return <HorizontalBar data={newData} options={newOptions} width={700} />
        
        
    }

    handleShow = (index) => {
        this.setState(prevState => ({
            [index]: !prevState[index]
        }))
    }

    render() {

        const isPrint = window.location.hash.split("/")[window.location.hash.split("/").length-1] === "print"

        return (
            
            <div style={{maxWidth: "1000px"}}>
                {this.state.loading && <Loading />}
                <Title title="Dashboard" />
                { !isPrint &&
                    <Tooltip title="printer friendly version">
                    <Print 
                        onClick={() => {
                            const newUrl = window.location.hash.replace("#", "")+"/print"
                            this.props.history.push(newUrl)}}
                    />
                    </Tooltip>
                }
                <br/>

                {this.state.graphSections && this.state.graphSections.map((section, index1) => (

                    <Paper key={index1} elevation={isPrint ? 0 : 2} style={{width: "750px", display: "inline-block", clear: "both", padding: "10px", margin: "5px"}}>
                    <Typography variant="title" style={{ cursor:'pointer'}}  onClick={() => this.handleShow(index1)}>{section.title} 
                        <KeyboardArrowDown 
                            style={{ cursor:'pointer', marginBottom: "-5px"}} 
                            
                        />
                    </Typography>

                    <Grid>
                    {this.state[index1] && section.graphs.map((graph, i) => {
                        return (!!graph.labels && !!graph.datasets) &&
                        <div style={{height: `${50+(graph.labels.length * 33)+(graph.datasets.length * 33)}px`, paddingBottom: "20px", pageBreakAfter: i === section.graphs.length - 1 ? "always" : "never"}}>
                            {this.generateGraph(graph, 0)}
                        </div>

                    }
                       
                    )}
                    </Grid>
                    </Paper>
                ))}



                <Paper elevation={isPrint ? 0 : 2} style={{width: "750px", display: "inline-block", clear: "both", padding: "10px", margin: "5px"}}>
            
                    <Typography variant="title" style={{float: "left", paddingBottom: "10px", cursor:'pointer'}} onClick={() => this.handleShow(666)}>
                        Actionable Items
                        <KeyboardArrowDown 
                        style={{cursor:'pointer', marginBottom: "-5px"}}
                        
                        />
                    </Typography>
                    
                    {this.state["666"] && this.state.actionableItems.tables.map(fac => 
                        {
                             return fac.rows && <div style={{paddingTop: "20px"}}>
                            <br/>
                            <Typography variant="subheading" id="tableTitle">
                            {fac.heading}
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{fac.headings[0]}</TableCell>
                                        <TableCell>{fac.headings[1]}</TableCell>
                                        <TableCell>{fac.headings[2]}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { fac.rows.map(row => (
                                        <TableRow>
                                            <TableCell>{row[0]}</TableCell>
                                            <TableCell>{row[1]}</TableCell>
                                            <TableCell>{ row[2] && row[1] && <Tooltip title={row[2]}><Warning rounded/></Tooltip>}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </div>
                        }
                        
                    )}
                </Paper>

                { this.state.microbeItems && this.state.microbeItems.heading !== "" &&
                    <Paper elevation={isPrint ? 0 : 2} style={{width: "750px", display: "inline-block", clear: "both", padding: "10px", margin: "5px"}}>
            
                    <Typography variant="title" style={{float: "left", paddingBottom: "10px", cursor:'pointer'}} onClick={() => this.handleShow(777)}>
                        Microbe Testing
                        <KeyboardArrowDown 
                        style={{cursor:'pointer', marginBottom: "-5px"}}
                        
                        />
                    </Typography>
                    

                            <br/>
                            {this.state["777"] && 
                                <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{this.state.microbeItems.tables[0].headings[0]}</TableCell>
                                        <TableCell>{this.state.microbeItems.tables[0].headings[1]}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.state.microbeItems.tables[0].rows.map(row => (
                                        <TableRow>
                                            <TableCell>{row[0]}</TableCell>
                                            <TableCell>{row[1]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            }
                            
                    
                </Paper>
                }
            

            </div>
        )
    }
}