import {
  Create,
  Edit,
  SimpleForm,
  Show,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  AutocompleteInput,
  ReferenceInput,
  required,
  ArrayField,
  SimpleShowLayout,
  CreateButton,
  Button,
  Link,
} from "react-admin";
import saveAs from "file-saver";

import React, { useState } from "react";
import { useNotify, useRefresh } from "ra-core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { BooleanField } from "ra-ui-materialui";

const CustCreateButton = ({ record, auditId }) => {
  //create new
  if (record.id === 0) {
    return (
      <Button
        component={Link}
        to={{
          pathname: "/tdontd-item/create",
          state: {
            record: {
              tdontdAuditId: Number(auditId),
              resident: record.resident,
              //   facility: record.facility,
            },
          },
        }}
        label="Create"
      ></Button>
    );
  }
  return (
    <Button
      component={Link}
      to={{
        pathname: `/tdontd-item/${record.id}`,
      }}
      label="Edit"
    ></Button>
  );
};

const CustDeleteButton = ({ record, auditId }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    ///do thing
    fetch(`/v1/tdontd-item/${record.id}`, {
      method: "DELETE",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    })
      .then((resp) => {
        if (resp.ok) {
          notify("Item deleted", {type: "success"});
          refresh();
        } else {
          notify("Could not delete", {type: "warning"});
        }
        setOpen(false);
      })
      .catch(() => {
        notify("Could not delete", {type: "warning"});
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>
          Delete? This will allow you to create another audit item
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel} label="Cancel"></Button>
          <Button onClick={handleDelete} label="Delete"></Button>
        </DialogActions>
      </Dialog>
      {record.id !== 0 && (
        <Button onClick={() => setOpen(true)} label="Delete"></Button>
      )}
    </div>
  );
};

const ExcelButton = ({ auditId }) => {
  const notify = useNotify();
  const dlExcel = () => {
    const fetchPath = `v1/tdontd/${auditId}/excel`;
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    })
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, "tdontd.xlsx"))
      .catch((e) => {
        notify("error", {type: "warning"});
      });
  };
  return <Button label="Download excel" onClick={dlExcel}></Button>;
};

export const TdontdAuditShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        {/* <Button>hello</Button> */}
        <ExcelButton auditId={props.id} />
        <TextField source="facility.name" label="Facility" />
        <DateField source="auditDate" />
        <ArrayField source="tdontds">
          <Datagrid>
            <TextField source="resident.nameOrId" label="Resident" />
            <BooleanField source="complete" />
            <CustCreateButton auditId={props.id} />
            <CustDeleteButton auditId={props.id} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
