import React, { useState, useEffect } from "react";
import { ResourceSelect } from "../../components/ResourceSelect";

import {
  TableCell,
  TableRow,
  Collapse,
  Typography,
  IconButton,
  TextField
} from "@material-ui/core";
import { PsaHistoryInputs } from "./psa-history-inputs";
import { Add, Clear } from "@material-ui/icons";
import { useNotify } from "ra-core";
import { authLevel } from "../../funcs/funcs";
import { isDateValid } from "../../funcs/funcs";
import TextMaskCustom from "../../components/TextMaskCustom";

export const PsaStagingRow = ({
  onChange,
  staging,
  onStagingPrescriptionChange,
  headInput,
  invalidMsgs,
  clearStaging,
  fetchRows,
  facilityId,
}) => {
  const notify = useNotify();

  const [showInvalid, setShowInvalid] = useState(false);
  const showInvalidOrSubmit = () => {
    if (invalidMsgs.length === 0) {
      submit(staging);
      setShowInvalid(false);
    } else {
      setShowInvalid(true);
    }
  };

  const submit = (row) => {
    row.decisionDate = !!row.decisionDate
      ? new Date(row.decisionDate).toISOString()
      : null;
    row.lastReviewDate = !!row.lastReviewDate
      ? new Date(row.lastReviewDate).toISOString()
      : null;
    row.informedConsentDate = !!row.informedConsentDate
      ? new Date(row.informedConsentDate).toISOString()
      : null;
    row.behaviourPlanDate = !!row.behaviourPlanDate
      ? new Date(row.behaviourPlanDate).toISOString()
      : null;
      

    const fetchPath = `v1/psa/${facilityId}/create`;
    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(row),
    })
      .then((res) => {
        if (!res.ok) {
          notify("Could not create, please contact support", {type: "warning"});
          throw "";
        }
        return res.json();
      })
      .then((resp) => {
        clearStaging();
        notify("Row created", {type: "info"});

        fetchRows();
      })
      .catch((e) => {});
  };

  const [height, setHeight] = useState(0);
  //happens every render to accommodate growing multi-line inputs
  useEffect(() => {
    const stagingHeight = document.getElementById("stagingRow").clientHeight;
    const adjHeight = stagingHeight + 308;
    setHeight(adjHeight);
  });

  return (
    <React.Fragment>
      <TableRow id="stagingRow">
        <TableCell className={headInput}>
          <IconButton
            size="small"
            disabled={authLevel() === "external"}
            onClick={() => showInvalidOrSubmit()}
          >
            <Add
              color={showInvalid && invalidMsgs.length > 0 ? "disabled" : ""}
            />
          </IconButton>
        </TableCell>
        <TableCell className={headInput}>
          <IconButton
            onClick={() => {
              setShowInvalid(false);
              clearStaging();
            }}
            size="small"
          >
            <Clear />
          </IconButton>
        </TableCell>
        <TableCell className={headInput}></TableCell>
        <TableCell className={headInput}>
          <ResourceSelect
            value={staging.psaPrescription.resident}
            getOptionDisabled={(option) => {
              console.log(option)
              return !!option.externalId
            }}
            displayKey="nameOrId"
            idKey="residentId"
            resource="residents"
            objKey="resident"
            creatable
            width="130px"
            clearOnBlur
            filter={{
              facilityId: Number(window.location.hash.split("/")[2]),
            }}
            onChange={onStagingPrescriptionChange}
          />
        </TableCell>
        <TableCell className={headInput}>
          <TextField 
            value={staging.decisionDate}
            onChange={(e) => onChange({decisionDate: e.target.value || null})}
            error={!isDateValid(staging.decisionDate)}
            InputProps={{
                inputComponent: TextMaskCustom
            }}
          />
        </TableCell>
       
        <TableCell className={headInput}>
          <ResourceSelect
            value={staging.psaPrescription.medication}
            displayKey="generic"
            idKey="medicationId"
            innerIdKey="medicationId"
            // resource="medications"
            resource={`facilities/${facilityId}/psa-medications`}
            objKey="medication"
            clearOnBlur
            width="160px"
            // filter={{
            //   medicationCategory: "psychotropic",
            // }}
            onChange={onStagingPrescriptionChange}
          />
        </TableCell>
        <PsaHistoryInputs
          {...staging}
          onChange={onChange}
          headInput={headInput}
          indications={staging.psaPrescription.medication.indications}
        />
      </TableRow>
      <TableRow>
        {showInvalid && (
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, top: `${height}px` }}
            colSpan={19}
          >
            {invalidMsgs.map((msg) => (
              <Typography style={{ color: "red" }}>{msg}</Typography>
            ))}
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};
