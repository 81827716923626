import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Typography,
  Input,
  Chip,
  makeStyles,
  useTheme,
  FormControl,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    // height: "500px",
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const TdontdTableUti = ({ rows, dispatch }) => {
  const classes = useStyles();
  const handleSignsSymptoms = (e, i) => {
    dispatch({
      type: "utiRowVal",
      index: i,
      payload: { signsSymptoms: JSON.stringify(e.target.value) },
    });
  };
  const handleGpReviewOutcome = (e, i) => {
    dispatch({
      type: "utiRowVal",
      index: i,
      payload: { gpReviewOutcome: JSON.stringify(e.target.value) },
    });
  };
  return (
    <div>
      <Typography variant="h6" style={{ paddingLeft: "10px" }}>
        For antimicrobial prescriptions related to urine tract sites ONLY
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Agent</TableCell>
            <TableCell>Antibiotic start date</TableCell>
            <TableCell>
              Symptoms or signs on start date related to urinary tract
            </TableCell>
            <TableCell>
              GP review: Date most recent GP clinical or prescription review
              related to this condition
            </TableCell>
            <TableCell>
              GP review: Outcome of most recent review, more details
            </TableCell>
            <TableCell>Was urine culture sent? If yes date sent</TableCell>
            <TableCell>
              Acknowledgement of urine culture result by GP or service staff. If
              yes, date
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, i) => (
            <TableRow>
              <TableCell>{row.medication.generic}</TableCell>
              <TableCell>{row.startDate}</TableCell>
              <TableCell>
                <FormControl>
                  <Select
                    style={{ width: "140px" }}
                    value={
                      row.signsSymptoms ? JSON.parse(row.signsSymptoms) : []
                    }
                    error={
                      !row.signsSymptoms ||
                      JSON.parse(row.signsSymptoms).length === 0
                    }
                    multiple
                    input={<Input id="select-multiple-chip" />}
                    onChange={(e) => handleSignsSymptoms(e, i)}
                    renderValue={(selected) => {
                      return (
                        <div className={classes.chips}>
                          {selected.map((val) => (
                            <Chip value={val} label={val} />
                          ))}
                        </div>
                      );
                    }}
                  >
                    <MenuItem value="No symptoms or signs">
                      No symptoms or signs
                    </MenuItem>
                    <MenuItem value="Dysuria, pain or burning on passing urine">
                      Dysuria, pain or burning on passing urine
                    </MenuItem>
                    <MenuItem value="Fever (&gt;38°or >1.5° above usual temperature)">
                      Fever (≥38°or {">"}1.5° above usual temperature)
                    </MenuItem>
                    <MenuItem value="Rigors, chills, shivering">
                      Rigors, chills, shivering
                    </MenuItem>
                    <MenuItem value="New or worse confusion, agitation">
                      New or worse confusion, agitation
                    </MenuItem>
                    <MenuItem value="Urinary frequency">
                      Urinary frequency
                    </MenuItem>
                    <MenuItem value="Urinary urgency">Urinary urgency</MenuItem>
                    <MenuItem value="Urinary incontinence">
                      Urinary incontinence
                    </MenuItem>
                    <MenuItem value="Flank, loin, kidney pain or tenderness">
                      Flank, loin, kidney pain or tenderness
                    </MenuItem>
                    <MenuItem value="Low abdominal pain">
                      Low abdominal pain
                    </MenuItem>
                    <MenuItem value="Visible blood in urine">
                      Visible blood in urine
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  type="date"
                  error={!row.gpReviewDate}
                  value={row.gpReviewDate || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "utiRowVal",
                      index: i,
                      payload: { gpReviewDate: e.target.value },
                    });
                  }}
                />
              </TableCell>
              <TableCell>
                <FormControl>
                  <Select
                    style={{ width: "140px" }}
                    error={
                      !row.gpReviewOutcome ||
                      JSON.parse(row.gpReviewOutcome).length === 0
                    }
                    value={
                      row.gpReviewOutcome ? JSON.parse(row.gpReviewOutcome) : []
                    }
                    multiple
                    input={<Input id="select-multiple-chip" />}
                    onChange={(e) => handleGpReviewOutcome(e, i)}
                    renderValue={(selected) => {
                      return (
                        <div className={classes.chips}>
                          {selected.map((val) => (
                            <Chip value={val} label={val} />
                          ))}
                        </div>
                      );
                    }}
                  >
                    <MenuItem value="Commence antimicrobial">
                      Commence antimicrobial
                    </MenuItem>
                    <MenuItem value="Cease antimicrobial">
                      Cease antimicrobial
                    </MenuItem>
                    <MenuItem value="Extend duration antimicrobial with stop date">
                      Extend duration antimicrobial with stop date
                    </MenuItem>
                    <MenuItem value="Extend duration antimicrobial no stop date">
                      Extend duration antimicrobial no stop date
                    </MenuItem>
                    <MenuItem value="Change antimicrobial">
                      Change antimicrobial
                    </MenuItem>
                    <MenuItem value="Review resident again within 14 days">
                      Review resident again within 14 days
                    </MenuItem>
                    <MenuItem value="No change to plan from antibiotic start date">
                      No change to plan from antibiotic start date
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <Select
                  style={{ width: "164px" }}
                  value={row.cultureSent || ""}
                  error={!row.cultureSent}
                  onChange={(e) => {
                    dispatch({
                      type: "utiRowVal",
                      index: i,
                      payload: { cultureSent: e.target.value },
                    });
                  }}
                >
                  <MenuItem value="Y">Yes</MenuItem>
                  <MenuItem value="N">No</MenuItem>
                  <MenuItem value="Not known">Not known</MenuItem>
                </Select>
                <TextField
                  type="date"
                  error={row.cultureSent === "Y" && !row.cultureSentDate}
                  value={row.cultureSentDate || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "utiRowVal",
                      index: i,
                      payload: { cultureSentDate: e.target.value },
                    });
                  }}
                />
              </TableCell>
              <TableCell>
                <Select
                  style={{ width: "164px" }}
                  value={row.ackResult || ""}
                  error={!row.ackResult}
                  onChange={(e) => {
                    dispatch({
                      type: "utiRowVal",
                      index: i,
                      payload: { ackResult: e.target.value },
                    });
                  }}
                >
                  <MenuItem value="Y">Yes</MenuItem>
                  <MenuItem value="N">No</MenuItem>
                  <MenuItem value="Not known">Not known</MenuItem>
                </Select>
                <br />
                <TextField
                  type="date"
                  error={row.ackResult === "Y" && !row.ackResultDate}
                  value={row.ackResultDate || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "utiRowVal",
                      index: i,
                      payload: { ackResultDate: e.target.value },
                    });
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TdontdTableUti;
