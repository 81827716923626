import React from "react";
import { Line, Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import PrintIcon from "@material-ui/icons/Print";
import { ExpandMore } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { Title } from "react-admin";

export class AmsResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      graphs: [],
      facilty: {
        name: "",
        organisation: {
          name: "",
        },
      },
    };
  }

  componentDidMount() {
    const facilityId = window.location.hash.split("/")[2];
    const fetchUrl = `/v1/ams/${facilityId}/results`;
    fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        authorization: localStorage.getItem("token"),
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then((resp) => {
        this.setState(resp);
      })
      .catch((err) => console.error(err));
  }

  generateBar(graphData) {
    const options = {
      maintainAspectRatio: false,
      title: {
        text: graphData.title,
        display: true,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    return <Bar data={graphData} options={options} width={500} height={500} />;
  }

  generateGraph(graphData, index, graphType) {
    const customColour = [
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
    ];

    const data = {
      labels: [], //replaced
      datasets: [
        {
          lineTension: 0,
          data: [], //replaced
          // backgroundColor: customColour
        },
      ],
    };

    const options = {
      line: {
        tension: 0,
      },
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "", //replaced
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            type: "time",
            time: {
              unit: "month",
              tooltipFormat: "YYYY-MM-DD",
            },
          },
        ],
      },
    };

    let newData = JSON.parse(JSON.stringify(data));

    let newOptions = JSON.parse(JSON.stringify(options));

    newData.labels = graphData.labels;
    // newData.datasets[0].data = graphData.data
    newData.datasets = graphData.datasets;

    //set colours appropriately
    if (newData.datasets == null) {
      newData.datasets = [];
    }
    if (newData.datasets.length === 1) {
      //no grouping, array of colours
      newData.datasets[0].backgroundColor = customColour;
      newOptions.legend.display = false;
    } else if (newData.datasets.length > 1) {
      //grouping, iterate over datasets and set single (different) colour for each
      newOptions.legend.display = true;
      newData.datasets.forEach((set, i) => {
        // newData.datasets[i].backgroundColor = customColour[i]
      });
    }

    if (graphData.type === "line") {
      newOptions.legend.display = true;
      newOptions.legend.labels = {
        filter: (label) => !label.text.includes("AC NAPS"),
      };
    }

    // newData.datasets[0].lineTension = 0
    newOptions.title.text = graphData.title;
    if (graphData.stacked) {
      // newOptions.scales.xAxes[0].stacked = true
      newOptions.scales.xAxes = [{ stacked: true }];
    }

    return (
      <Line data={newData} options={newOptions} width={500} height={500} />
    );
  }

  render() {
    return (
      <div>
        <Title title="Antimicrobial stewardship results" />
        {window.location.hash.split("/")[
          window.location.hash.split("/").length - 1
        ] !== "print" && (
          <Tooltip title="printer friendly version">
            <PrintIcon
              onClick={() => {
                const newUrl = window.location.hash.replace("#", "") + "/print";
                this.props.history.push(newUrl);
              }}
            />
          </Tooltip>
        )}

        <List>
          <ListItem>
            <ListItemText
              primary="Date"
              secondary={this.state.date && this.state.date.substring(0, 10)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Facility"
              secondary={this.state.facility && this.state.facility.name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Organisation"
              secondary={
                this.state.facility && this.state.facility.organisation.name
              }
            />
          </ListItem>
        </List>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Learn how we determine these numbers</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              These AMS graphs are calculated in a way that allows comparison
              with the results of the Aged Care National Antimicrobial
              Prescribing Survey (ACNAPS). The ACNAPS survey is conducted as a
              point prevalence survey for a single day, therefore, your
              benchmarking is calculated by taking the status of the AMS list
              for each day (as if your were to complete the NAPS survey each
              day) and averaging the result for each quarter. This allows for as
              close a comparison with the national averages in the ACNAPS
              results with your own facility data. To ensure that benchmarks are
              accurate, the most recent point shown will be the date that the
              AMS list was last updated.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <div style={{ float: "left" }}>
          <div style={{ pageBreakAfter: "always" }}></div>
          <div>&nbsp;</div>
          {/* <Typography variant="display1">{section.sectionTitle}</Typography> */}
          <Grid>
            {this.state.graphs.map((graphs) => (
              <>
                <div style={{ height: "550px", paddingBottom: "20px" }}>
                  {this.generateGraph(graphs.timeGraph, 0)}
                </div>
                <div style={{ height: "550px", paddingBottom: "20px" }}>
                  {this.generateBar(graphs.numGraph)}
                </div>
              </>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}
