import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  CardActions,
  CreateButton,
} from "react-admin";
import NameConcat from "../../components/NameConcat";

const FacilityFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Organisation"
      source="organisationId"
      reference="organisations"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Facility"
      source="facilityId"
      reference="facilities"
      filter={{ module: "poly" }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const PostActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
  basePath,
  ...rest
}) => {
  return (
    <CardActions>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
    </CardActions>
  );
};

export const NqiListed = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    title="Polypharmacy"
    exporter={false}
    filters={<FacilityFilter />}
    actions={<PostActions />}
  >
    <Datagrid>
      <DateField source="date" sortable={false} />
      <NameConcat source="user" sortable={false} />
      <TextField source="facility.name" sortable={false} label="Facility" />
      <TextField
        source="facility.organisation.name"
        sortable={false}
        label="Organisation"
      />
      <EditButton />
    </Datagrid>
  </List>
);
