import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {Check} from '@material-ui/icons'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell component="th" scope="row">
          {row.medication.generic}
        </TableCell>
        <TableCell>{row.medication.medicationId}</TableCell>
        <TableCell>{row.restraint}</TableCell>
        <TableCell align="right">{row.startDate.substring(0, 10)}</TableCell>
        <TableCell align="right">{(row.ceaseDate ? row.ceaseDate.substring(0, 10) : "Current")}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>HistID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Regular</TableCell>
                    <TableCell>Regular Dose</TableCell>
                    <TableCell>PRN</TableCell>
                    <TableCell>Indication</TableCell>
                    <TableCell>Indication Type</TableCell>
                    <TableCell>Behaviour</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                        <TableCell>{historyRow.id}</TableCell>
                        <TableCell component="th" scope="row">
                        {historyRow.createdAt.substring(0, 10)}
                        </TableCell>
                        <TableCell>{historyRow.regular && <Check />}</TableCell>
                        <TableCell>{historyRow.regularDose}</TableCell>
                        <TableCell>{historyRow.prn && <Check />}</TableCell>
                        <TableCell>{historyRow.indication && <Check />}</TableCell>
                        <TableCell>{historyRow.indicationType}</TableCell>
                        <TableCell>{historyRow.behaviour}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PrescriptionHistoryTable(props) {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" gutterBottom component="div" style={{paddingLeft: "10px", paddingTop: "10px"}}>
                  Medication history
            </Typography>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>PrescriptionID</TableCell>
            <TableCell>Medication</TableCell>
            <TableCell>MedicationID</TableCell>
            <TableCell>Restraint</TableCell>
            <TableCell align="right">Start Date</TableCell>
            <TableCell align="right">Cease Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
      </TableContainer>
  );
}