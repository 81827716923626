import React from "react";
import {
  SimpleForm,
  FormDataConsumer,
  Create,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  CheckboxGroupInput,
  NumberInput,
  required,
} from "react-admin";

export const FacCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label="Organisation"
            source="organisationId"
            reference="organisations"
            validate={[required()]}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <NumberInput source="numResidents" validate={[required()]} />
      <CheckboxGroupInput
        source="uiModules"
        choices={[
          { id: 1, name: "dashboard" },
          { id: 2, name: "psa" },
          { id: 3, name: "ams" },
          { id: 4, name: "cr" },
          { id: 5, name: "poly" },
          { id: 6, name: "microbe" },
          { id: 8, name: "tdontd" },
        ]}
      />
    </SimpleForm>
  </Create>
);
