import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  NumberInput,
  required,
} from "react-admin";
import { dateParser } from "../../funcs/funcs";

const validateNumRes = (values) => {
  const errors = {};
  if (new Date(values.date) > new Date()) {
    errors.date = ["Date must be today's date or earlier"];
  }
  return errors;
};

export const NumResidentsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm autoComplete="off" validate={validateNumRes}>
        <DateInput
          source="date"
          parse={(v) => dateParser(v)}
          initialValue={new Date()}
          validate={[required()]}
        />
        <NumberInput source="numResidents" validate={[required()]} />
        <ReferenceInput
          label="Facility"
          source="facilityId"
          reference="facilities"
          validate={[required()]}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
