import React from "react";
import {
  Edit,
  SimpleForm,
  FormDataConsumer,
  TextField,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { Link } from "react-router-dom";

const optionRenderer = (choice) =>
  choice.valid ? `${choice.name}` : <s>{choice.name}</s>;

export const ExtUserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="level" />

      <ArrayInput
        source="uiExtUserFacMods"
        style={{ width: "100%" }}
        label="Modules: strikethrough means fac does not have module"
      >
        <SimpleFormIterator disableRemove disableAdd reOrderButtons={<></>}>
          <FormDataConsumer>
            {({ getSource, scopedFormData, record }) => {
              getSource();
              return (
                <Link to={{ pathname: `/facilities/${record.facility.id}` }}>
                  <TextField
                    source={"facility.name"}
                    label="asdf"
                    record={scopedFormData}
                  />
                </Link>
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, getSource, record, ...rest }) => {
              console.log("record:", record);
              //   getSource();
              return (
                <CheckboxGroupInput
                  label=" "
                  optionText={optionRenderer}
                  source={getSource("modules")}
                  //   source="modules"
                  row={true}
                  choices={record.choices}
                  helperText=""
                />
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
