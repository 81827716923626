import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
  AUTH_ERROR,
  useNotify
} from "react-admin";
import decodeJwt from "jwt-decode";

const authProvider = {
  login: params => {

    const creds = {
      username: params.username,
      password: params.password,
      reCaptcha: params.reCaptcha,
    };
    const request = new Request("/login", {
      method: "POST",
      body: JSON.stringify(creds),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return response.json().then((errJson) => {
            localStorage.setItem("attempts", errJson.attempts);
            throw new Error(errJson.msg);
          });
        }
        return response.json();
      })
      .then(({ token }) => {
        const decodedToken = decodeJwt(token);
        localStorage.setItem("token", token);
        localStorage.setItem("level", decodedToken.level);
        localStorage.setItem("facilityId", decodedToken.facilityId);
        localStorage.setItem("organisationId", decodedToken.organisationId);
        localStorage.setItem("contracted", decodedToken.contracted);
        localStorage.setItem("modules", decodedToken.modules);
        localStorage.setItem("pharmacist", decodedToken.pharmacist);
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  },
  checkError: error => {
    // const notify = useNotify();
    console.log(error.status)
    // if (error.status === 418) {
      // notify("Could not authorise request", "warning");
    // }
    // return Promise.reject({ message: "Couldn't authenticate request"})
    // Promise.resolve()
    return Promise.reject()
    // return Promise.reject({message: "this is a message", redirectTo: "/blah"})

  },
  checkAuth: params => {
    console.log(params)
    return localStorage.getItem("token")
      ? Promise.resolve()
      : Promise.reject({message: "Could not authenticate request" });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("level");
    localStorage.removeItem("facilityId");
    localStorage.removeItem("organisationId");
    localStorage.removeItem("contracted");
    localStorage.removeItem("modules");
    localStorage.removeItem("attempts");
    localStorage.removeItem("pharmacist");
    return Promise.resolve();
  },
  getIdentity: (params) => {
    console.log(params)
    return Promise.resolve()
  },
  // authorization
  getPermissions: params => {
    console.log(params)
    const level = localStorage.getItem("level");
    return level ? Promise.resolve(level) : Promise.resolve("guest");
  },


}

export default authProvider;

// export default (type, params) => {
//   const notify = useNotify()
//   if (type === AUTH_LOGIN) {
//     const creds = {
//       username: params.username,
//       password: params.password,
//       reCaptcha: params.reCaptcha,
//     };
//     const request = new Request("/login", {
//       method: "POST",
//       body: JSON.stringify(creds),
//       headers: new Headers({ "Content-Type": "application/json" }),
//     });
//     return fetch(request)
//       .then((response) => {
//         if (response.status < 200 || response.status >= 300) {
//           return response.json().then((errJson) => {
//             localStorage.setItem("attempts", errJson.attempts);
//             throw new Error(errJson.msg);
//           });
//         }
//         return response.json();
//       })
//       .then(({ token }) => {
//         const decodedToken = decodeJwt(token);
//         localStorage.setItem("token", token);
//         localStorage.setItem("level", decodedToken.level);
//         localStorage.setItem("facilityId", decodedToken.facilityId);
//         localStorage.setItem("organisationId", decodedToken.organisationId);
//         localStorage.setItem("contracted", decodedToken.contracted);
//         localStorage.setItem("modules", decodedToken.modules);
//         localStorage.setItem("pharmacist", decodedToken.pharmacist);
//       })
//       .catch((err) => {
//         throw Error(err.message);
//       });
//   }

//   if (type === AUTH_LOGOUT) {
//     localStorage.removeItem("token");
//     localStorage.removeItem("level");
//     localStorage.removeItem("facilityId");
//     localStorage.removeItem("organisationId");
//     localStorage.removeItem("contracted");
//     localStorage.removeItem("modules");
//     localStorage.removeItem("attempts");
//     localStorage.removeItem("pharmacist");
//     return Promise.resolve();
//   }

//   if (type === AUTH_CHECK) {
//     // console.log("params:", params)
    
//     return localStorage.getItem("token")
//       ? Promise.resolve()
//       : Promise.reject({ redirectTo: "/login" });
//   }

//   if (type === AUTH_ERROR) {
//     notify("Could not authorise request", "warning")
//     // console.log("paramsss:", params)
//   }

//   if (type === AUTH_GET_PERMISSIONS) {
//     const level = localStorage.getItem("level");
//     return level ? Promise.resolve(level) : Promise.resolve("guest");
//   }

//   return Promise.reject("Unknown method");
// };
