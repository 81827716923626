import React, { useState } from 'react';
import { 
    Edit, 
    SimpleForm, 
    DateField,
    SelectInput,
    Toolbar,
    maxLength,
    SaveButton,
    FormDataConsumer,
    TextInput
} from 'react-admin';

import {
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Paper,
    Box
} from '@material-ui/core';

import {
    KeyboardArrowDown
} from '@material-ui/icons'


const validateTextLength = [maxLength(140)]


const RowIncCr = ({hist, changed}) => (
    <TableRow key={hist.id}>
        <TableCell>{`${(new Date(hist.createdAt)).toLocaleDateString()}`}</TableCell>
        <TableCell>{hist.psaPrescription.medication.generic}</TableCell>
        <TableCell style={changed.regular ? {backgroundColor: "yellow"} : {}}>{hist.regular && <Tick />}</TableCell>
        <TableCell numeric style={changed.regularDose ? {backgroundColor: "yellow"} : {}}>{hist.regularDose > 0 && hist.regularDose}</TableCell>
        <TableCell style={changed.prn ? {backgroundColor: "yellow"} : {}}>{hist.prn && <Tick />}</TableCell>
        <TableCell style={changed.indication ? {backgroundColor: "yellow"} : {}}>{hist.indication && <Tick />}</TableCell>
        <TableCell style={changed.indicationType ? {backgroundColor: "yellow"} : {}}>{hist.indicationType}</TableCell>
        <TableCell style={changed.behaviour ? {backgroundColor: "yellow"} : {}}>{hist.behaviour}</TableCell>
        <TableCell>{hist.pharmacistComment}</TableCell>
        <TableCell>{hist.pharmacistRecommendation}</TableCell>
        <TableCell>{hist.rnComment}</TableCell>
    </TableRow>
)

const HeadIncCr = () => (
    <TableHead>
        <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Medication</TableCell>
        <TableCell>Regular</TableCell>
        <TableCell>Regular Dose</TableCell>
        <TableCell>PRN</TableCell>
        <TableCell>Indication</TableCell>
        <TableCell>Indication type</TableCell>
        <TableCell>Behaviour</TableCell>
        <TableCell>Pharmacist comment</TableCell>
        <TableCell>Recommendation</TableCell>
        <TableCell>RN Comment</TableCell>
        </TableRow>
    </TableHead>
)

const HeadNoCr = () => (
    <TableHead>
        <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Medication</TableCell>
            <TableCell>Regular</TableCell>
            <TableCell>Regular dose</TableCell>
            <TableCell>PRN</TableCell>
            <TableCell>Indication</TableCell>
            <TableCell>Indication type</TableCell>
            <TableCell>Behaviour</TableCell>
        </TableRow>
    </TableHead>
)

const RowNoCr = ({hist}) => (
    <TableRow key={hist.id}>
        <TableCell>{(new Date(hist.createdAt)).toLocaleDateString()}</TableCell>
        <TableCell>{hist.psaPrescription.medication.generic}</TableCell>
        <TableCell >{hist.regular && <Tick />}</TableCell>
        <TableCell>{hist.regularDose > 0 && hist.regularDose}</TableCell>
        <TableCell>{hist.prn && <Tick /> }</TableCell>
        <TableCell>{hist.indication && <Tick />}</TableCell>
        <TableCell>{hist.indicationType}</TableCell>
        <TableCell>{hist.behaviour}</TableCell>
    </TableRow>
)

const determinedChanged = (prevHist, currHist) => {
    if (prevHist === null) {
        return {}
    }
    return {
        regular: prevHist.regular !== currHist.regular,
        regularDose: prevHist.regularDose !== currHist.regularDose,
        prn: prevHist.prn !== currHist.prn,
        indication: prevHist.indication !== currHist.indication,
        indicationType: prevHist.indicationType !== currHist.indicationType,
        behaviour: prevHist.behaviour !== currHist.behaviour
    }

}

//may include number of 
const TableHistory = ({prescriptions}) => {
    // const changeObj = i === 0 ? {} : determinedChanged(history[i-1], hist)
    return (
        <Table>
            <HeadIncCr />
            {prescriptions
            .map((presc) => {
                const histLen = presc.history.length
                return presc.history.map((hist, i) => {
                    const changeObj = i === histLen-1 ? {} : determinedChanged(presc.history[i+1], hist)
                    return <RowIncCr hist={hist} changed={changeObj} />
                })
            }).reduce((accu, elem) => {
                return accu === null ? [elem] : [...accu, <TableRow><TableCell>MEDICATION CEASED</TableCell></TableRow>, elem]
            })
            }
        </Table>
    )
}

const TableOtherCr = ({hists}) => {
    return (
        <Table>
            <HeadIncCr />
            {hists
            .map((hist) => {
                return <RowIncCr hist={hist} changed={{}} />
            })
            }
        </Table>
    )
}


const PsaRowTable = (props) => (
    <Table padding="dense" style={{width: "989px"}}>
        <HeadNoCr />
        <TableBody>
        { props.rows.map((row, i) => (
            <RowNoCr hist={row}/>
        ))}
            
        </TableBody>
    </Table>
)

const FullNameField = (props) => {
    const user = props.record[props.source]
return <><Typography component="span" variant="body2"><span>{!!user && `${user.firstName} ${user.lastName}`}</span></Typography></>
}
FullNameField.defaultProps = {addLabel: true}

const Tick = () => (
    <span><span></span><svg style={{width: 26, height: 26}} focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true"><g><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path></g></svg></span>
)

const SaveOnlyToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
)



const ReviewEdit = (props) => {
    const [showState, setShowState] = useState({})

    const handleShow = (key) => {
        setShowState({[key]: !showState[key]})
    }


    return (
        <>
        <Edit {...props}>
            <SimpleForm toolbar={null}>
                <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData.self &&
                    <>
                    <Typography variant="h5" style={{paddingBottom: "15px"}}>{`${formData.self.psaPrescription.resident.nameOrId}: ${formData.self.psaPrescription.medication.generic}`}</Typography>

                    <Typography variant="title" >Current Prescribed Details</Typography>
                    <PsaRowTable rows={[formData.self]} />

                    <div style={{marginTop: "20px"}}>
                        <Typography variant="title" style={{display: "inline-block", marginRight: "5px", cursor:'pointer'}} onClick={() => handleShow("showPrev")}>{`History for ${formData.self.psaPrescription.medication.generic}`}</Typography>
                        <KeyboardArrowDown 
                            style={{display: "inline-block", cursor:'pointer', position: "relative", top: "5px"}} 
                            onClick={() => handleShow("showPrev")}
                        />
                    </div>
    
                    {showState.showPrev &&
                        <TableHistory prescriptions={formData.usageHistory} />
                    }
                    

                    <div style={{marginTop: "20px"}}>
                        <Typography variant="title" style={{display: "inline-block", marginRight: "5px", cursor:'pointer'}} onClick={() => handleShow("showOtherCr")}>Other Potential Chemical Restraint</Typography>
                        <KeyboardArrowDown 
                            style={{display: "inline-block", cursor:'pointer', position: "relative", top: "5px"}} 
                            onClick={() => handleShow("showOtherCr")}
                        />
                    </div>
                    {showState.showOtherCr &&
                        <TableOtherCr hists={formData.otherCr} />
                    }

                    <div style={{marginTop: "20px"}}>
                        <Typography variant="title" style={{display: "inline-block", marginRight: "5px", cursor:'pointer'}} onClick={() => handleShow("showOtherPsychotropics")}>Other Psychotropics</Typography>
                        <KeyboardArrowDown 
                            style={{display: "inline-block", cursor:'pointer', position: "relative", top: "5px"}} 
                            onClick={() => handleShow("showOtherPsychotropics")}
                        />
                    </div>
                    {showState.showOtherPsychotropics &&
                        <PsaRowTable rows={formData.otherPsychotropics || []} />
                    }
                    
                    </>
                }
                }

                    
                </FormDataConsumer>
                

            </SimpleForm>
        </Edit>
        
        <Edit undoable={false} title="Chemical Restraint Review Edit" {...props} style={{marginBottom: "20px"}}>
            <SimpleForm toolbar={<SaveOnlyToolbar />}>
            <Typography variant="title" style={{width: "500px"}}>Pharmacist Recommendation</Typography>
            <FullNameField label="Pharmacist" source="pharmacist" />
            <DateField label="Updated on" source="pharmacistDate" />
                
            <SelectInput source="pharmacistRecommendation" label="Recommendation" choices={[
                { id: 'Cease', name: 'Cease' },
                { id: 'Increase dose', name: 'Increase dose' },
                { id: 'Decrease dose', name: 'Decrease dose' },
                { id: 'No change', name: 'No change' },
            ]} />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <TextInput multiline source="pharmacistComment" disabled={!formData.pharmacistRecommendation} label="Pharmacist comment" style={{width: "250px"}} validate={validateTextLength} {...rest}/>
                }
            </FormDataConsumer>

            </SimpleForm>
        </Edit>
        <Edit undoable={false} {...props} title=" ">
            <SimpleForm toolbar={<SaveOnlyToolbar />}>
                <Typography variant="title" style={{width: "500px"}}>Nurse Feedback</Typography>
                <FullNameField label="Nurse" source="rn" />
                <DateField label="Updated on" source="rnDate"/>
                <SelectInput source="rnOutcome" label="Outcome" choices={[
                    { id: 'Cease', name: 'Cease' },
                    { id: 'Increase dose', name: 'Increase dose' },
                    { id: 'Decrease dose', name: 'Decrease dose' },
                    { id: 'No change', name: 'No change' },
                ]} />
                <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <TextInput multiline source="rnComment" disabled={!formData.rnOutcome} label="RN comment" style={{width: "250px"}} validate={validateTextLength} />
                }
                </FormDataConsumer>
                

            </SimpleForm>
        </Edit>
        </>
    )
    

}

export default ReviewEdit
