import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const Dashboards = (props) => {
    return (
        <>
            <List {...props}
                bulkActionButtons={false}
                title="Dashboards"
                exporter={false}
            >
                <Datagrid rowClick={(id, basePath, record) => `/dashboards/${id}`}>
                    <TextField source="name" sortable={false} label="Organisation" />
                </Datagrid>
            </List>
        </>
    )
};