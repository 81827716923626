import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';


export const OrgsListed = props => (
    <List {...props} 
        bulkActionButtons={false}
        title="Organisations"
        exporter={false}
        >
        <Datagrid>
            <TextField source="name" sortable={false} />
            {(window.localStorage.getItem("level") === "super" && window.localStorage.getItem("pharmacist") !== "true" ) &&
                <EditButton />
            }
        </Datagrid>
    </List>
);