import React from 'react'
import {Typography} from '@material-ui/core'

export const PolyAd = props => (
    <div>
        <Typography variant="body2" style={{ maxWidth: "700px"}}>
        <span style={{fontWeight: "bold",}}>This is a demonstration page.</span> Please contact Choice Aged Care on 1300 275 908 or email rmmr@choiceagedcare.com.au to arrange access any of the PPASS-3 Tool modules.
        </Typography>
        <br/>
        <Typography variant="body2" style={{maxWidth: "700px"}}>
        Record your polypharmacy quality indicator with historical tracking and benchmarking against the organisation’s and Choice Aged Care’s national average.
        </Typography>
        <br/>
        <img src={require('../files/polypharmacy_table.png')} />
        <img src={require('../files/polypharmacy_results.png')} />
    </div>
);