import React, { useEffect } from "react";
import MaskedInput from "react-text-mask";
import {
  TableCell,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import { ResourceSelect } from "../../components/ResourceSelect";
import Autocomplete from "@material-ui/lab/Autocomplete";

const routes = ["Oral", "Topical", "Eye", "IV", "IM", "PV", "PR", "Otic"];

const padding = {
  paddingLeft: "1px",
  paddingRight: "1px",
};

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/]}
      placeholderChar={"\u2000"}
      placeholder="YYYYMMDD"
      showMask
      guide={false}
      keepCharPositions={false}
      style={{ width: "105px" }}
      minLength={4}
    />
  );
};

export const AmsRowInputs = ({ handleChange, row, cellBackground }) => {
  const style = { ...padding, ...cellBackground };

  return (
    <React.Fragment>
      <TableCell style={style}>
        <ResourceSelect
          value={row.resident.nameOrId}
          displayKey="nameOrId"
          idKey="residentId"
          resource="residents"
          objKey="resident"
          creatable
          clearOnBlur
          filter={{
            facilityId: Number(window.location.hash.split("/")[2]),
          }}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell style={style}>
        <ResourceSelect
          value={row.medication.generic}
          displayKey="generic"
          idKey="medicationId"
          innerIdKey="medicationId"
          resource="medications"
          objKey="medication"
          clearOnBlur
          filter={{
            medicationCategory: "antimicrobial",
          }}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell style={style}>
        <Autocomplete
          autoHighlight
          autoSelect
          clearOnBlur
          disableClearable
          value={row.route}
          options={routes}
          renderInput={(params) => (
            <TextField {...params} InputProps={{ ...params.InputProps }} />
          )}
          onChange={(e, newVal) => handleChange({ route: newVal })}
        />
      </TableCell>
      <TableCell align="center" style={style}>
        <Checkbox
          checked={row.prn}
          onChange={(e) => handleChange({ prn: e.target.checked })}
        />
      </TableCell>
      <TableCell style={style}>
        <ResourceSelect
          value={row.site.name}
          displayKey="name"
          idKey="siteId"
          resource="amssites"
          objKey="site"
          clearOnBlur
          onChange={handleChange}
        />
        {row.site.id === 13 && (
          <FormControlLabel
            control={
              <Checkbox
                checked={row.catheter}
                onChange={(e) => handleChange({ catheter: e.target.checked })}
              />
            }
            label="Catheter?"
          />
        )}
      </TableCell>
      <TableCell style={style}>
        <ResourceSelect
          value={row.indication.name}
          displayKey="name"
          idKey="indicationId"
          resource="amsindications"
          objKey="indication"
          clearOnBlur
          onChange={handleChange}
          filter={{
            amsSiteId: Number(row.siteId),
          }}
          disabled={!row.siteId}
        />
      </TableCell>
      <TableCell style={style}>
        <TextField
          inputProps={{ maxLength: 250 }}
          multiline
          value={row.signsSymptoms}
          onChange={(e) => handleChange({ signsSymptoms: e.target.value })}
        />
      </TableCell>
      <TableCell align="center" style={style}>
        <Checkbox
          checked={row.pathologyRequested}
          onChange={(e) =>
            handleChange({ pathologyRequested: e.target.checked })
          }
        />
      </TableCell>
      <TableCell style={style}>
        <TextField
          // error={!isDateValid(startDate)}
          InputProps={{
            inputComponent: TextMaskCustom,
            value: row.startDate,
            onChange: (e) =>
              handleChange({ startDate: e.target.value || null }),
          }}
        />
      </TableCell>
      <TableCell align="center" style={style}>
        <Checkbox
          checked={row.stopReviewDateListed}
          onChange={(e) =>
            handleChange({ stopReviewDateListed: e.target.checked })
          }
        />
      </TableCell>
      <TableCell style={style}>
        <TextField
          // error={!isDateValid(ceaseDate)}
          InputProps={{
            inputComponent: TextMaskCustom,
            value: row.ceaseDate,
            onChange: (e) =>
              handleChange({ ceaseDate: e.target.value || null }),
          }}
        />
      </TableCell>
    </React.Fragment>
  );
};
