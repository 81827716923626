import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
} from "react-admin";

const OrganisationFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Organisation"
      source="organisationId"
      reference="organisations"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const Tick = () => (
  <span class="MuiTypography-root-101 MuiTypography-body1-110">
    <span class="translate-BooleanField--label-547"></span>
    <svg
      style={{ width: 26, height: 26 }}
      class="MuiSvgIcon-root-87"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      data-testid="true"
    >
      <g>
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
      </g>
    </svg>
  </span>
);

const ModuleField = ({ record, mod, ...rest }) => {
  if (!record.modules) {
    return null;
  }
  const res = record.modules.filter((m) => m.name === mod);
  return res.length === 1 ? <Tick /> : null;
};

export const FacsListed = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    title="Facilities"
    exporter={false}
    filters={<OrganisationFilter />}
  >
    <Datagrid>
      <TextField source="name" sortable={false} />
      <TextField
        source="organisation.name"
        sortable={false}
        label="Organisation"
      />
      <ModuleField label="Dashboard" mod="dashboard" />
      <ModuleField label="PSA" mod="psa" />
      <ModuleField label="AMS" mod="ams" />
      <ModuleField label="CR" mod="cr" />
      <ModuleField label="Poly" mod="poly" />
      <ModuleField label="Microbe" mod="microbe" />
      <ModuleField label="TDONTD" mod="tdontd" />
      {window.localStorage.getItem("level") === "super" &&
        window.localStorage.getItem("pharmacist") !== "true" && <EditButton />}
    </Datagrid>
  </List>
);
