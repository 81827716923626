//using 'yyyy-mm-dd' date, check whether valid
export const isDateValid = (strDate) => {

    //empty dates are 'valid'
    if (!strDate) {
        return true
    }

    //check for valid yyyy-mm-dd format
    const dateMatch = strDate.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)
    if (dateMatch === null) {
        return false
    }
    
    //date itself is valid or not
    const date = new Date(strDate)
    if (Object.prototype.toString.call(date) === "[object Date]") {
        // it is a date
        if (isNaN(date.getTime())) {  // d.valueOf() could also work
          // date is not valid
            return false
        } else {
            return true
          // date is valid
        }
    } else {
        return false
    // not a date
    }
}