import React, { useCallback, useContext, useMemo, useState } from "react";

import {
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Edit, DeleteOutlined, Block } from "@material-ui/icons";
import { Tick } from "../../components/Tick";
import { useNotify } from "react-admin";

import { useHistory } from "react-router-dom";
import { authLevel } from "../../funcs/funcs";
import { ApiDisabledContext } from "./psa";

const InnerCells = ({ row }) => {
  const {
    regular,
    regularDose,
    shortCourse,
    prn,
    prnNumDoses,
    prnNumDays,
    indication,
    indicationType,
    behaviour,
    altRestraint,
    participate,
    createdAt,
    decisionDate,
    repsInformed,
    freqMonitoring,
    lastReviewDate,
    psaPrescription,
    prnCircumstance,
    informedConsentDate,
    informedConsentHow,
    behaviourPlanImplemented,
    effectivenessMonitoring,
    comments,
    behaviourPlanDate,
    integNote
  } = row;

  const history = useHistory();
  return (
    <>
      <TableCell>
        <Typography
          // variant="body2"
          style={{ paddingLeft: "5px", width: "70px" }}
          variant="caption"
        >
          {createdAt.substring(0, 10)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          onClick={() =>
            history.push(`/residents/${psaPrescription.residentId}`)
          }
        >
          {psaPrescription.resident.nameOrId}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {decisionDate}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {psaPrescription.medication.generic}
        </Typography>
      </TableCell>
      <TableCell align="center">{(regular || shortCourse) && <Tick />}</TableCell>
      <TableCell>
        <Typography variant="body2">
          {regularDose ? regularDose : ""}
        </Typography>
      </TableCell>
      <TableCell align="center">{prn && <Tick />}</TableCell>
      <TableCell>
        <Typography variant="body2">{prnNumDoses}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{prnNumDays}</Typography>
      </TableCell>
      {/* <TableCell align="center">{indication && <Tick />}</TableCell> */}
      <TableCell>
        <Typography variant="body2">{indicationType}</Typography>
      </TableCell>
      <TableCell style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        <Typography variant="body2">{prnCircumstance}</Typography>
      </TableCell>
      <TableCell style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        <Typography variant="body2">{informedConsentDate}</Typography>
      </TableCell>
      <TableCell style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        <Typography variant="body2">{informedConsentHow}</Typography>
      </TableCell>
        {/* <Typography variant="body2">{decisionDate}</Typography> */}
      <TableCell align="center">{!indication && <Tick />}</TableCell>

      <TableCell align="center">{behaviourPlanImplemented && <Tick />}</TableCell>


      <TableCell style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        <Typography variant="body2">{behaviourPlanDate}</Typography>
      </TableCell>
      
 
      <TableCell align="center">{effectivenessMonitoring && <Tick />}</TableCell>
 
      <TableCell>
        <Typography variant="body2">{lastReviewDate}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{integNote && `[${integNote}]`}{comments}</Typography>
      </TableCell>
    </>
  );
};

const MemoInnerCells = (props) => {
  return useMemo(() => {
    return <InnerCells {...props} />;
  }, []);
};

const SelfDisplayRow = ({
  setRows,
  row,
  hasUnlocked,
  setHasUnlocked,
  setDeleteOpen,
  setDeleteReqOpen,
  setCeaseOpen,
  setDialogDet,
}) => {
  // const [deleteRequestOpen, setDeleteRequestOpen] = useState(false);

  const handleUnlock = useCallback(() => {
    setRows((prevRows) =>
      prevRows.map((prevRow) =>
        row.id === prevRow.id ? { ...prevRow, unlocked: true } : prevRow
      )
    );
    setHasUnlocked(true);
  }, []);

  const isSuper = localStorage.getItem("level") === "super";

  const handleRemove = () => {
    if (!hasUnlocked) {
      setDialogDet({
        nameOrId: row.psaPrescription.resident.nameOrId,
        medication: row.psaPrescription.medication.generic,
        prescriptionId: row.psaPrescriptionId,
        residentId: row.psaPrescription.residentId,
        reason: "",
        decisionDate: row.decisionDate ? row.decisionDate.split("T")[0] : (new Date()).toISOString().split("T")[0],
      });
      isSuper ? setCeaseOpen(true) : setDeleteReqOpen(true);
    }
  };

  const ctx = useContext(ApiDisabledContext)

  return (
    <TableRow style={{ paddingTop: "2px", paddingBottom: "2px" }}>
      <TableCell style={{ paddingTop: "2px", paddingBottom: "2px" }}>
        <Tooltip title="Update row">
          <IconButton
            disabled={authLevel() === "external" || hasUnlocked}
            size="small"
            onClick={handleUnlock}
            // disabled={hasUnlocked}
          >
            <Edit />
          </IconButton>
          {/* <UpdateRowIcon handleUnlock={() => handleUnlock(row.id)} /> */}
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Remove">
          <IconButton
            // disabled={authLevel() === "external"}
            size="small"
            onClick={handleRemove}
            disabled={authLevel() === "external" || hasUnlocked || ctx.apiDisabled("ceaseDate")}
          >
            <Block />
          </IconButton>
        </Tooltip>
      </TableCell>
      <MemoInnerCells row={row} />
      <IconButton
        disabled={authLevel() === "external" || ctx.apiDisabled("ceaseDate")}
        onClick={() => {
          if (!hasUnlocked) {
            setDialogDet({
              nameOrId: row.psaPrescription.resident.nameOrId,
              medication: row.psaPrescription.medication.generic,
              prescriptionId: row.psaPrescriptionId,
              residentId: row.psaPrescription.residentId,
            });
            return isSuper ? setDeleteOpen(true) : setDeleteReqOpen(true);
          }
        }}
      >
        <DeleteOutlined
        // color={hasUnlocked ? "disabled" : ""}
        />
      </IconButton>
    </TableRow>
  );
};

export const PsaDisplayRow = (props) => {
  return useMemo(() => {
    return <SelfDisplayRow {...props} />;
  }, [props.hasUnlocked]);
};
