import React from 'react'
import {Typography} from '@material-ui/core'

export const CrAd = props => (
    <div>
        <Typography variant="body2" style={{ maxWidth: "700px"}}>
        <span style={{fontWeight: "bold",}}>This is a demonstration page.</span> Please contact Choice Aged Care on 1300 275 908 or email rmmr@choiceagedcare.com.au to arrange access any of the PPASS-3 Tool modules.
        </Typography>
        <br/>
        <Typography variant="body2" style={{maxWidth: "700px"}}>
        The chemical restraint review module is an innovative platform for clinical pharmacist review of potential chemical restraint. Feedback can be provided on each chemical restraint for discussion with the rest of the resident’s care team. This process ensures chemical restraint medications are regularly reviewed and promotes quality use of medicines in accordance with the Aged Care Quality and Safety Commission standards.
        </Typography>
        <br/>
        <img src={require('../files/CR_table.png')} />
        <img src={require('../files/CR_edit.png')} />
    </div>
);