// 'use strict';

import React, { useState, useEffect, useReducer, useCallback } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  SvgIcon,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Button,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Title, useNotify } from "react-admin";
import { Prompt } from "react-router-dom";
import saveAs from "file-saver";
import { NumResidentsUpdate } from "../../components/NumResidentsUpdate";
import { AmsRowLocked } from "./ams-row-locked";
import { AmsRowUnlocked } from "./ams-row-unlocked";
import { AmsRowInputs } from "./ams-row-inputs";
import { isDateValid } from "../../funcs/funcs";
import moment from "moment";
import { AmsStaging } from "./ams-staging";
import { useAuthenticated } from "react-admin";
import { FacName } from "../../components/FacName";

const styles = (theme) => ({
  disabled: {
    color: "black",
  },
  cellPadding: {
    paddingLeft: "15px",
    paddingRight: "15px",
    padding: "0px 0px 0px 0px",
  },
});

const rowTemplate = {
  id: null,
  residentId: 0,
  resident: {
    nameOrId: "",
  },
  medicationId: "",
  medication: {
    generic: "",
  },
  route: "",
  siteId: null,
  site: {},
  prn: false,
  indicationId: null,
  indication: {},
  signsSymptoms: "",
  pathologyRequested: false,
  startDate: "",
  stopReviewDateListed: false,
  ceaseDate: "",
  errors: [],
  hasChanged: false,
  catheter: false,
  virgin: {
    id: null,
    residentId: 0,
    resident: {
      nameOrId: "",
    },
    medicationId: "",
    medication: {
      generic: "",
    },
    route: "",
    siteId: null,
    site: {},
    prn: false,
    indicationId: null,
    indication: {},
    signsSymptoms: "",
    pathologyRequested: false,
    startDate: "",
    stopReviewDateListed: false,
    ceaseDate: "",
    errors: [],
    hasChanged: false,
  },
};

export const ACTIONS = {
  TOGGLE_UNLOCKED: "toggleUnlocked",
  EDIT_ROW: "edit",
  SET_ROWS: "setRows",
  APPEND_ERRORS: "setErrors", //for use with async checking
  CLEAR: "clear", //resets to virgin
  BLANK: "blank", //resets to rowTemplate
};

function rowsReducer(rows, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_UNLOCKED:
      return rows.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, unlocked: !action.payload.unlocked };
        }
        return row;
      });

    case ACTIONS.EDIT_ROW: //replace existing with incoming
      return rows.map((row) => {
        if (action.payload.id === row.id) {
          //TODO check
          return {
            ...action.payload,
            errors: getInvalidMsgs(action.payload),
            hasChanged: rowHasChanged(action.payload),
          };
        }
        return row;
      });

    case ACTIONS.SET_ROWS:
      return action.payload; //arr of rows from svr

    case ACTIONS.CLEAR: //use virgin data again
      return rows.map((row) => {
        if (action.payload.id === row.id) {
          //set data to be what is currently virgin
          let tempRow = { ...row };
          tempRow = { ...tempRow.virgin };
          //put virgin back in place
          tempRow.virgin = { ...tempRow };
          return tempRow;
        }
        return row;
      });

    case ACTIONS.BLANK:
      return rows.map((row) => {
        if (action.payload.id === row.id) {
          return { ...rowTemplate };
        }
        return row;
      });
  }
}

const rowHasChanged = (row) => {
  const keysOfConcern = [
    "residentId",
    "medicationId",
    "route",
    "siteId",
    "prn",
    "indicationId",
    "signsSymptoms",
    "pathologyRequested",
    "startDate",
    "stopReviewDateListed",
    "ceaseDate",
    "catheter",
  ];
  for (var key in keysOfConcern) {
    const theKey = keysOfConcern[key];
    if (row[theKey] !== row.virgin[theKey]) {
      return true;
    }
  }
  return false;
};

const getInvalidMsgs = (row) => {
  let ret = [];
  //CHECK FOR REQUIRED FIELDS
  if (!row.resident.nameOrId) {
    ret.push("Resident is required");
  }
  if (!row.medicationId) {
    ret.push("Antimicrobial is required");
  }
  if (!row.route) {
    ret.push("Route is required");
  }
  if (!row.siteId) {
    ret.push("Site is required");
  }
  if (!row.indicationId) {
    ret.push("Indication is required");
  }
  if (!row.startDate) {
    ret.push("Start date is required");
  }

  const startValid = isDateValid(row.startDate);
  const ceaseValid = isDateValid(row.ceaseDate);
  //VALIDATE DATES
  if (!ceaseValid) {
    ret.push(`Invalid date for cease date`);
  }
  if (!startValid) {
    ret.push(`Invalid date for start date`);
  }

  if (
    row.startDate &&
    startValid &&
    row.ceaseDate &&
    ceaseValid &&
    row.startDate > row.ceaseDate
  ) {
    ret.push("Start date can't be greater than cease date");
  }

  return ret;
};

const Ams = ({ history }) => {
  useAuthenticated();
  const [rows, rowsDispatch] = useReducer(rowsReducer, []);
  const [staging, stagingDispatch] = useReducer(rowsReducer, [
    { ...rowTemplate },
  ]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(["startDate", "asc"]);
  const [showOverlapAlert, setShowOverlapAlert] = useState(false);
  const rowsPerPage = 50;
  const facilityId = Number(window.location.hash.split("/")[2]);
  const notify = useNotify();

  useEffect(() => {
    getRows();
  }, [page, ...sort]);

  const print = () => {
    const fetchPath = `v1/ams/${facilityId}/excel`;
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    })
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, "ams.xlsx"))
      .catch((e) => {
        notify("error", {type: "warning"});
      });
  };

  const handleSort = (key) => {
    //if key is already set, swap order
    const tempSort = [...sort];
    if (key === tempSort[0]) {
      const newSort = tempSort[1] === "asc" ? "desc" : "asc";
      setSort([tempSort[0], newSort]);
      return;
    }

    setSort([key, "asc"]);

    //if new key, desc
  };

  //TODO usecallback only when page or sort changes
  const getRows = useCallback(() => {
    const rangeStart = page * rowsPerPage;
    const rangeEnd = rangeStart + rowsPerPage - 1;
    const range = `[${rangeStart},${rangeEnd}]`;
    const sortPrm = JSON.stringify(sort);

    let fetchPath = `v1/ams/${facilityId}?range=${encodeURIComponent(
      range
    )}&sort=${encodeURIComponent(sortPrm)}`;

    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          notify(
            "there was a problem fetching data from the server, please contact support",
            {type: "warning"}
          );
          return;
        } else {
          return res.json();
        }
      })
      .then((resJson) => {
        resJson.rows = resJson.rows.map((row) => scrubRowImport(row));
        resJson.rows = resJson.rows.map((row) => {
          //keep virgin state inside obj
          row.virgin = { ...row, errors: [] };
          return { ...row, errors: [], unlocked: false };
        });
        setTotalCount(resJson.totalCount);
        // setRows(resJson.rows)
        rowsDispatch({ type: ACTIONS.SET_ROWS, payload: resJson.rows });
        // setVirginRows(resJson.rows)
      })
      .catch((e) => {
        return;
      });
  }, [page, sort]);

  const submitNewRow = (row) => {
    const sRow = scrubRowExport(row);
    //send to server
    const fetchPath = `v1/ams/${facilityId}`;
    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(sRow),
    })
      .then((res) => {
        if (res.status === 409) {
          setShowOverlapAlert(true);
        } else if (!res.ok) {
          //notify bad save
          notify("Could not save new row", {type: "warning"});
          return;
        } else {
          stagingDispatch({ type: ACTIONS.BLANK, payload: { id: null } });
          getRows();
        }
      })
      .catch((e) => {
        notify("error", {type: "warning"});
      });
  };

  const submitEdit = useCallback((row) => {
    const sRow = scrubRowExport(row);

    //send to server
    const fetchPath = `v1/ams/${facilityId}/${sRow.id}`;
    return fetch(fetchPath, {
      method: "PUT",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(sRow),
    })
      .then((res) => {
        if (res.status === 409) {
          //set error msgs
          setShowOverlapAlert(true);
        } else if (!res.ok) {
          //notify bad save
          notify("Could not save new row", {type: "warning"});
        } else {
          notify("Row updated", {type: "success"});
          getRows();
        }
      })
      .catch((e) => {});
  });

  //sanitises svr data for import
  const scrubRowImport = (row) => {
    const startDate = new Date(row.startDate);

    row.startDate = moment(startDate).utc().toISOString().substring(0, 10);

    if (row.ceaseDate) {
      const ceaseDate = new Date(row.ceaseDate);
      row.ceaseDate = moment(ceaseDate).utc().toISOString().substring(0, 10);
    }

    row.siteId = row.siteId.toString();
    row.indicationId = row.indicationId.toString();

    return row;
  };

  //sanitises local data for save/update
  const scrubRowExport = (sRow) => {
    var startDate = new Date(sRow.startDate);
    sRow.startDate = startDate.toISOString();

    // if !ceaseDate, turn to null
    if (!sRow.ceaseDate) {
      sRow.ceaseDate = null;
    } else {
      var ceaseDate = new Date(sRow.ceaseDate);
      sRow.ceaseDate = ceaseDate.toISOString();
    }

    //filthy, convert siteId to int
    sRow.siteId = Number(sRow.siteId);
    sRow.indicationId = Number(sRow.indicationId);

    return sRow;
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  return (
    <div>
      <Dialog
        open={showOverlapAlert}
        onClose={() => setShowOverlapAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Overlapping row already exists"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Date/drug/prn/indication overlaps with existing entry, fix before
            saving
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowOverlapAlert(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Tooltip title="download as excel file">
        <SvgIcon onClick={print} style={{ padding: "5px" }}>
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z" />
        </SvgIcon>
      </Tooltip>
      <Title title="Antimicrobial stewardship" />

      <SvgIcon
        style={{ padding: "5px" }}
        onClick={() => history.push(`/ams/${facilityId}/results`)}
      >
        <path d="M22.8 11.819l-1.667 1.66-6.781-6.762-2.992 2.993-5.922-5.835-1.41 1.418 7.336 7.242 3.001-3.002 5.351 5.358-1.716 1.708 6 1.221-1.2-6.001zm1.2 8.181v2h-24v-20h2v18h22z" />
      </SvgIcon>
      <br />
      {/* <Typography variant="title">{facility ? facility.name : ""}</Typography> */}
      <FacName facId={facilityId} />
      <NumResidentsUpdate facilityId={facilityId} />
      <TableContainer>
        <Table padding={"none"} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                classes={styles.cellPadding}
                style={{ width: "30px" }}
              ></TableCell>
              <TableCell
                classes={styles.cellPadding}
                style={{ width: "30px" }}
              ></TableCell>
              <TableCell
                classes={styles.cellPadding}
                style={{ width: "140px" }}
                sortDirection={sort[0] === "nameOrId" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "nameOrId"}
                  direction={sort[0] === "nameOrId" ? sort[1] : "asc"}
                  onClick={() => handleSort("nameOrId")}
                >
                  Name or ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "170px" }}
                sortDirection={sort[0] === "medication" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "medication"}
                  direction={sort[0] === "medication" ? sort[1] : "asc"}
                  onClick={() => handleSort("medication")}
                >
                  Antimicrobial
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "110px" }}
                sortDirection={sort[0] === "route" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "route"}
                  direction={sort[0] === "route" ? sort[1] : "asc"}
                  onClick={() => handleSort("route")}
                >
                  Route
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "80px" }}
                sortDirection={sort[0] === "prn" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "prn"}
                  direction={sort[0] === "prn" ? sort[1] : "asc"}
                  onClick={() => handleSort("prn")}
                >
                  PRN
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "170px", cursor: "pointer" }}
                sortDirection={sort[0] === "site" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "site"}
                  direction={sort[0] === "site" ? sort[1] : "asc"}
                  onClick={() => handleSort("site")}
                >
                  Site
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "170px", cursor: "pointer" }}
                sortDirection={sort[0] === "indication" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "indication"}
                  direction={sort[0] === "indication" ? sort[1] : "asc"}
                  onClick={() => handleSort("indication")}
                >
                  Indication
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "170px", cursor: "pointer" }}
                sortDirection={sort[0] === "signsSymptoms" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "signsSymptoms"}
                  direction={sort[0] === "signsSymptoms" ? sort[1] : "asc"}
                  onClick={() => handleSort("signsSymptoms")}
                >
                  Signs/symptoms
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "80px" }}
                sortDirection={
                  sort[0] === "pathologyRequested" ? sort[1] : false
                }
              >
                <TableSortLabel
                  active={sort[0] === "pathologyRequested"}
                  direction={sort[0] === "pathologyRequested" ? sort[1] : "asc"}
                  onClick={() => handleSort("pathologyRequested")}
                >
                  Pathology
                  <br /> requested
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "110px", cursor: "pointer" }}
                sortDirection={sort[0] === "startDate" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "startDate"}
                  direction={sort[0] === "startDate" ? sort[1] : "asc"}
                  onClick={() => handleSort("startDate")}
                >
                  Start date
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "80px", cursor: "pointer" }}
                sortDirection={
                  sort[0] === "stopReviewDateListed" ? sort[1] : false
                }
              >
                <TableSortLabel
                  active={sort[0] === "stopReviewDateListed"}
                  direction={
                    sort[0] === "stopReviewDateListed" ? sort[1] : "asc"
                  }
                  onClick={() => handleSort("stopReviewDateListed")}
                >
                  Stop/review
                  <br /> date listed
                </TableSortLabel>
              </TableCell>
              <TableCell
                style={{ width: "110px", cursor: "pointer" }}
                sortDirection={sort[0] === "ceaseDate" ? sort[1] : false}
              >
                <TableSortLabel
                  active={sort[0] === "ceaseDate"}
                  direction={sort[0] === "ceaseDate" ? sort[1] : "asc"}
                  onClick={() => handleSort("ceaseDate")}
                >
                  Cease date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <AmsStaging
              row={staging[0]}
              dispatch={stagingDispatch}
              submitNewRow={submitNewRow}
            />

            {rows.map((row, index) =>
              row.unlocked ? (
                <AmsRowUnlocked
                  row={row}
                  dispatch={rowsDispatch}
                  submitEdit={submitEdit}
                />
              ) : (
                <AmsRowLocked
                  row={row}
                  dispatch={rowsDispatch}
                  getRows={getRows}
                  history={history}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={false}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </div>
  );
};

Ams.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ams);
