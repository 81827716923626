import moment from "moment";

export const dateParser = (badDateStr) => {
  const temp = new Date(badDateStr);
  return temp.toISOString();
};

//using 'yyyy-mm-dd' date, check whether valid
export const isDateValid = (strDate) => {
  //empty dates are 'valid'
  if (!strDate) {
    return true;
  }

  //check for valid yyyy-mm-dd format
  const dateMatch = strDate.match(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
  );
  if (dateMatch === null) {
    return false;
  }

  var m = moment(strDate);
  if (!m.isValid()) {
    return false;
  }

  //date itself is valid or not
  const date = new Date(strDate);
  if (Object.prototype.toString.call(date) === "[object Date]") {
    // it is a date
    if (isNaN(date.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false;
    } else {
      //now that this is a 'valid' date, check if it's within the bounds
      const nowDate = new Date();
      const withinLower = date.getFullYear() > nowDate.getFullYear() - 30;
      const withinUpper = date.getFullYear < nowDate.getFullYear + 2;
      return withinLower && withinUpper;
      // date is valid
    }
  } else {
    return false;
    // not a date
  }
};

export const authLevel = () => {
  return localStorage.getItem("level");
};
