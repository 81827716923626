import React from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import {
    Button,
    SvgIcon
} from "@material-ui/core"

const ReportButton = (props) => {
    return (<Button color="primary" disabled={props.disabled} onClick={() => props.push(`/polyresult/${props.facilityId}`)}>
    <SvgIcon viewBox="0 0 24 24">
        <path d="M22.8 11.819l-1.667 1.66-6.781-6.762-2.992 2.993-5.922-5.835-1.41 1.418 7.336 7.242 3.001-3.002 5.351 5.358-1.716 1.708 6 1.221-1.2-6.001zm1.2 8.181v2h-24v-20h2v18h22z"/>
    </SvgIcon>
</Button>
)}

export default connect(null, {
    push: pushAction,
})(ReportButton);