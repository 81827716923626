import React, { useState } from "react";

import {
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  required,
  TextField,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  Toolbar,
  SaveButton,
  useNotify,
  FormDataConsumer,
} from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
} from "@material-ui/core";
import { useEffect } from "react";
import { BooleanInput } from "ra-ui-materialui";
import { authLevel } from "../../funcs/funcs";

const RequestRmmr = ({ facility, nameOrId }) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const send = () => {
    handleClose();
    notify("Please wait");
    const fetchPath = `v1/rmmrrequest`;

    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        facility: facility,
        nameOrId: nameOrId,
      }),
    }).then((res) => {
      if (res.ok) {
        notify("Email sent", {type: "success"});
      } else {
        notify("Email could not be sent", {type: "warning"});
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        disabled={authLevel() === "external"}
      >
        Request RMMR
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send request for RMMR to Choice Aged Care?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            An email will be sent to Choice Aged Care on your behalf.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={send} variant="contained" color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SaveOnlyToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={authLevel() === "external"} />
  </Toolbar>
);

export const CrEdit = (props) => (
  <>
    <Edit {...props} title="Edit Chemical Restraint Review">
      <SimpleForm toolbar={<SaveOnlyToolbar />}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return formData.pharmacistComment ? (
              <RequestRmmr
                facility={formData.facility.name}
                nameOrId={formData.resident.nameOrId}
              />
            ) : (
              <></>
            );
            // return formData.facilityId ? <NumResidentsUpdate facilityId={formData.facilityId} /> : <></>
          }}
        </FormDataConsumer>

        <DateField source="createdAt" label="Date created" />
        <TextField source="resident.nameOrId" label="Resident" />
        <TextField source="facility.name" label="Facility" />
        <TextField source="facility.organisation.name" label="Organisation" />
        {window.localStorage.getItem("level") === "super" ? (
          <TextInput source="pharmacistComment" options={{ multiline: true }} />
        ) : (
          <TextField source="pharmacistComment" />
        )}
        <TextInput
          source="doctorFeedback"
          label="Feedback"
          options={{ multiline: true }}
        />

        <BooleanInput source="acknowledged" />

        <ArrayInput source="actions" label="">
          <SimpleFormIterator disableRemove disableAdd reOrderButtons={<></>}>
            {/* <TextInput source={'medication.generic'} label="Medication" disabled/>
             */}
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                const isRestraint =
                  !scopedFormData.psaPrescriptionHistory.indication;
                return (
                  <div>
                    <Typography component="div">
                      Medication:{" "}
                      <Box fontWeight="fontWeightBold" display="inline">
                        {scopedFormData.medication.generic}
                      </Box>
                      {isRestraint && "(restraint)"}
                    </Typography>
                    {window.localStorage.getItem("level") !== "super" && (
                      <Typography component="div">
                        Optional comment:{" "}
                        <Box fontWeight="fontWeightBold" display="inline">
                          {scopedFormData.recommendation}
                        </Box>
                      </Typography>
                    )}
                  </div>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                const isSuper =
                  window.localStorage.getItem("level") === "super";
                const isRestraint =
                  !scopedFormData.psaPrescriptionHistory.indication;
                return (
                  <div>
                    {isRestraint && isSuper && (
                      <TextInput
                        source={getSource("recommendation")}
                        label="Optional comment"
                      />
                    )}
                    {isRestraint && !isSuper && (
                      <TextField
                        source={getSource("recommendation")}
                        label="Optional comment"
                      />
                    )}
                  </div>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  </>
);
