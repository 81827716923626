// import 'babel-polyfill';
// import 'react-app-polyfill/ie11';

import React from "react";
import { Admin, Resource, fetchUtils } from "react-admin";

import authProvider from "./authProvider";
import customRoutes from "./customRoutes";
import MyLayout from "./layout";
import simpleRestProvider from "ra-data-simple-rest";

import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";

import { createTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import {
  AmsListed,
  PolyListed,
  PolyEdit,
  PolyCreate,
  PsasListed,
  PsasCurrent,
  FacsListed,
  FacCreate,
  FacEdit,
  Dashboards,
  OrgCreate,
  OrgEdit,
  OrgsListed,
  UserCreate,
  Users,
  UserEdit,
  // ReviewsListedOpen,
  CrListed,
  ReviewEdit,
  AuthLogsListed,
  ExtUserEdit,
  ExtUsersListed,
  MicrobeListed,
  MicrobeCreate,
  MicrobeEdit,
  NumResidentsListed,
  NumResidentsCreate,
  NumResidentsEdit,
  ResidentEdit,
  ResidentListed,
  CrEdit,
  UtiCreate,
  UtiListed,
  TdontdListed,
} from "./modules";

import LoginPage from "./unauthed/login";

import IdleTimer from "react-idle-timer";

import { CrCreateListed } from "./modules/cr/cr-create-listed";
import { NqiListed } from "./modules/nqi/nqi-listed";
import { NqiCreate } from "./modules/nqi/nqi-create";
import { NqiEdit } from "./modules/nqi/nqi-edit";
import { UtiEdit } from "./modules/uti/uti-edit";
import { TdontdAuditCreate } from "./modules/tdontd/tdontd-audit-create";
import { TdontdAuditShow } from "./modules/tdontd/tdontd-audit-show";
import { HttpError } from 'react-admin';

const mainHttpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.user = {
    authenticated: true,
    token: localStorage.getItem("token"),
  };

  options.headers.set("pragma", "no-cache");
  options.headers.set("cache-control", "no-cache");

  return fetchUtils.fetchJson(url, options);
};

// const extHttpClient = (url, options = {}) => {
//   if (!options.headers) {
//     options.headers = new Headers({ Accept: "application/json" });
//   }
//   options.user = {
//     authenticated: true,
//     token: localStorage.getItem("ext-token"),
//   };

//   options.headers.set("pragma", "no-cache");
//   options.headers.set("cache-control", "no-cache");

//   return fetchUtils.fetchJson(url, options);
// }

const mainProvider = simpleRestProvider(`${window.location.origin}/v1`, mainHttpClient)
const extProvider = simpleRestProvider(`${window.location.origin}/ext`, mainHttpClient)

const myProvider = {
  ...mainProvider,
  create: (resource, params) => {
    if (resource === "tdontd-external") {
      return extProvider.create(resource, params)
    }
    return mainProvider.create(resource, params);
  }
}



// const myProvider = {
//   ...mainProvider,
//   create: (resource, params) => {
//     if (resource === "tdontd-external") {
//       return extProvider.create(resource, params);
//     }
//     return mainProvider.create(resource, params);
//   }
// }

// const myDataProvider = (type, resource, params) => {
//   // console.log("resource:", resource)
//   if (resource === "tdontd-external") {

//     return simpleRestProvider(`${window.location.origin}/ext`, extHttpClient)
//   }

//   return simpleRestProvider(`${window.location.origin}/api`, mainHttpClient);
//     // return {...simpleRestProvider(
//     //   `${window.location.origin}/ext`,
//     //   extHttpClient
//     // )(type, resource, params),
      
//   // }
//   // }
//   // return simpleRestProvider(
//   //   `${window.location.origin}/v1`,
//   //   mainHttpClient
//   // )(type, resource, params)
// }
//getdataprovider from file
// const addRespDataProvider = addRespData(dataProvider);



const resources = (isPharmacist) => {
  return {
    //for filtering by module
    main: [
      {
        module: "dashboard",
        singleOrg: false,
        singleFac: null,
        res: (
          <Resource
            name={`dashboards`}
            list={Dashboards}
            options={{ label: "Org Dashboards" }}
          />
        ),
      },
      {
        module: "ams",
        singleOrg: null,
        singleFac: false,
        res: (
          <Resource name={"ams"} list={AmsListed} options={{ label: "AMS" }} />
        ),
      },
      {
        module: "tdontd",
        singleOrg: null,
        singleFac: null,
        res: (
          <Resource
            name="uti"
            list={UtiListed}
            create={UtiCreate}
            edit={UtiEdit}
            options={{ label: "TDONTD Pathway" }}
          />
        ),
      },
      {
        module: "tdontd",
        singleOrg: null,
        singleFac: null,
        res: (
          <Resource
            name="tdontd"
            list={TdontdListed}
            create={TdontdAuditCreate}
            show={TdontdAuditShow}
            options={{ label: "TDONTD Audit" }}
          />
        ),
      },
      {
        module: "psa",
        singleOrg: null,
        singleFac: false,
        res: (
          <Resource
            name={"psa"}
            list={PsasCurrent}
            options={{ label: "Self-assessments" }}
          />
        ),
      },
      {
        module: "cr",
        singleOrg: null,
        singleFac: null,
        res: (
          <Resource
            name={`crreviews`}
            list={CrListed}
            options={{ label: "Chemical Restraint Reviews" }}
            edit={CrEdit}
          />
        ),
      },
      {
        module: "poly",
        singleOrg: null,
        singleFac: null,
        res: (
          <Resource
            name={"poly"}
            list={PolyListed}
            create={PolyCreate}
            edit={PolyEdit}
            options={{ label: "Polypharmacy" }}
          />
        ),
      },
      {
        module: "microbe",
        singleOrg: null,
        singleFac: null,
        res: (
          <Resource
            name={"microbe"}
            list={MicrobeListed}
            create={MicrobeCreate}
            edit={MicrobeEdit}
            options={{ label: "Microbe Testing" }}
          />
        ),
      },
      {
        module: "microbe",
        singleOrg: null,
        singleFac: null,
        res: <Resource name="microbesites" />,
      },
      {
        module: "microbe",
        singleOrg: null,
        singleFac: null,
        res: <Resource name="microbegrowth" />,
      },
    ],

    all: {
      super: [
        <Resource
          name={`users`}
          list={Users}
          create={isPharmacist ? null : UserCreate}
          edit={isPharmacist ? null : UserEdit}
          options={{ label: "Users" }}
        />,
        <Resource
          name={`extusers`}
          list={ExtUsersListed}
          edit={isPharmacist ? null : ExtUserEdit}
          options={{ label: "Ext Users" }}
        />,
        <Resource
          name="facilities"
          list={FacsListed}
          create={isPharmacist ? null : FacCreate}
          edit={isPharmacist ? null : FacEdit}
          options={{ label: "Facilities" }}
        />,
        <Resource
          name="organisations"
          list={OrgsListed}
          create={isPharmacist ? null : OrgCreate}
          edit={isPharmacist ? null : OrgEdit}
          options={{ label: "Organisations" }}
        />,
        <Resource
          name="authlogs"
          list={AuthLogsListed}
          options={{ label: "Auth Logs" }}
        />,
        <Resource
          name="nqis"
          list={NqiListed}
          options={{ label: "NQIs" }}
          create={NqiCreate}
          edit={NqiEdit}
        />,
        <Resource
          name="crcreate"
          list={CrCreateListed}
          options={{ label: "Chemical Restraint Create" }}
        />,
      ],
      organisation: [
        <Resource name={`users`} list={Users} options={{ label: "Users" }} />,
        <Resource
          name="facilities"
          list={FacsListed}
          options={{ label: "Facilities" }}
        />,
        <Resource name="organisations" />,
      ],
      facility: [<Resource name="facilities" />],
      external: [<Resource name="facilities" />],
    },
  };
};

function filterModules(level, singleOrg, singleFac, modules, isPharmacist) {
  const allRes = resources(isPharmacist);
  const mods = allRes.main
    .filter((fMod) => {
      const containsMod = modules.includes(fMod.module);
      const orgMatch =
        fMod.singleOrg === null ? true : fMod.singleOrg === singleOrg;
      const facMatch =
        fMod.singleFac === null ? true : fMod.singleFac === singleFac;
      return containsMod && orgMatch && facMatch;
    })
    .map((mod) => mod.res);

  //only add residents management, resident count if required
  const dependencies =
    modules.includes("ams") || modules.includes("psa")
      ? [
          <Resource
            name="numresidents"
            list={NumResidentsListed}
            create={NumResidentsCreate}
            edit={NumResidentsEdit}
            options={{ label: "Resident Count" }}
          />,
          <Resource name="numresidentsgraph" />,
          <Resource
            name="residents"
            list={ResidentListed}
            edit={ResidentEdit}
            options={{ label: "Resident Management" }}
          />,
          <Resource name="medications" />,
          <Resource name="amssites" />,
          <Resource name="amsindications" />,
        ]
      : [];

  //filter 'admin' stuff
  // const admMods = resources.admin.filter(fMod => fMod.levels.includes(level)).map(mod => mod.res)
  return [...mods, ...allRes.all[level], ...dependencies];
}

const App = () => {
  const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
    allowMissing: true,
    onMissingKey: (key, _, __) => key,
  });

  function onIdle() {
    localStorage.removeItem("token");
    window.location.replace("/");
  }

  return (
    <>
      <ThemeProvider theme={createTheme()}>
      <IdleTimer
        onIdle={onIdle}
        timeout={1000 * 60 * 60 * 6} //5 hours
      />
      <Admin
        i18nProvider={i18nProvider}
        appLayout={MyLayout}
        dataProvider={myProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        customRoutes={customRoutes}
      >
        {(level) => {
          const modules = localStorage.getItem("modules")
            ? localStorage.getItem("modules").split(",")
            : [];
          const singleOrg = !!Number(localStorage.getItem("organisationId"));
          const singleFac = !!Number(localStorage.getItem("facilityId"));
          if (level === "guest" || module.length === 0) {
            return [<></>];
          }
          const isPharmacist = localStorage.getItem("pharmacist") === "true";
          return filterModules(
            level,
            singleOrg,
            singleFac,
            modules,
            isPharmacist
          );
        }}
      </Admin>
      </ThemeProvider>
    </>
  );
};

export default App;
