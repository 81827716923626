// in src/menu.js
import React from "react";
import { connect } from "react-redux";
import { MenuItemLink, getResources, Responsive } from "react-admin";
import { withRouter } from "react-router-dom";

const Menu = ({ resources, onMenuClick, logout }) => {
  const modules = localStorage.getItem("modules")
    ? localStorage.getItem("modules").split(",")
    : [];
  const facId = Number(localStorage.getItem("facilityId"));
  const orgId = Number(localStorage.getItem("organisationId"));
  const level = localStorage.getItem("level");
  const external = level === "external";
  return (
    <div>
      {!!orgId && modules.includes("dashboard") && (
        <MenuItemLink
          to={`/dashboards/${localStorage.getItem("organisationId")}`}
          primaryText="Dashboard"
          onClick={onMenuClick}
        />
      )}

      {!!facId && modules.includes("ams") && (
        <MenuItemLink
          to={`/ams/${localStorage.getItem("facilityId")}`}
          primaryText="AMS"
          onclick={onMenuClick}
        />
      )}
      {!!facId && modules.includes("psa") && (
        <MenuItemLink
          to={`/psa/${facId}`}
          primaryText="Current Self-assessment"
          onclick={onMenuClick}
        />
      )}

      {!modules.includes("ams") && !external && (
        <MenuItemLink to={`/amsad`} primaryText="AMS" onclick={onMenuClick} />
      )}
      {!modules.includes("psa") && !external && (
        <MenuItemLink
          to={`/psaad`}
          primaryText="Self Assessments"
          onclick={onMenuClick}
        />
      )}
      {!modules.includes("dashboard") && !external && (
        <MenuItemLink
          to={`/dashboardad`}
          primaryText="Dashboard"
          onclick={onMenuClick}
        />
      )}
      {!modules.includes("poly") && !external && (
        <MenuItemLink
          to={`/polyad`}
          primaryText="Polypharmacy"
          onclick={onMenuClick}
        />
      )}
      {!modules.includes("cr") && !external && (
        <MenuItemLink
          to={`/crad`}
          primaryText="CR Reviews"
          onclick={onMenuClick}
        />
      )}

      {resources.map((resource) => {
        if (resource.name === "microbesites") {
          return <></>;
        }
        if (resource.name === "microbegrowth") {
          return <></>;
        }

        if (resource.name === "numresidentsgraph") {
          return <></>;
        }
        if (resource.name === "medications") {
          return <></>;
        }
        if (resource.name === "amssites") {
          return <></>;
        }
        if (resource.name === "amsindications") {
          return <></>;
        }

        if (resource.name === "facilities") {
          if (level === "super" || level === "organisation") {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={resource.options.label || resource.name}
                onClick={onMenuClick}
              />
            );
          } else {
            return <></>;
          }
        }

        if (resource.name === "organisations") {
          if (level !== "super") {
            return <></>;
          }
        }

        return (
          //generated menu passthrough
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options.label || resource.name}
            onClick={onMenuClick}
          />
        );
      })}

      <MenuItemLink to="/export" primaryText="Export" onClick={onMenuClick} />

      <Responsive
        small={logout}
        medium={null} // Pass null to render nothing on larger devices
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
