import * as React from "react";
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

export const SetDeceasedButton = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'residents',
        selectedIds,
        { deceased: (new Date()).toISOString() },
        {
            onSuccess: () => {
                refresh();
                notify('Success', {type: "success"});
                unselectAll('residents');
            },
            onFailure: error => notify('Error: Could not set residents as deceased', {type: "warning"}),
        }
    );

    return (
        <Button
            label="Set as deceased"
            disabled={loading}
            onClick={updateMany}
        >
        </Button>
    );
};