import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { goBack } from 'react-router-redux';

class CancelButton extends Component {
    handleClick = () => {
        this.props.goBack();
    };

    render() {
        return <Button color="primary" onClick={this.handleClick}>Cancel</Button>;
    }
}

export default connect(null, {
    goBack,
})(CancelButton);