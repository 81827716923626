import React from 'react';
import { 
    Typography,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip'
import {Print} from '@material-ui/icons'
import {
    Grid,
    Card,
    CardMedia,
    CardContent
} from '@material-ui/core'
import {Title} from 'react-admin'
import { withStyles } from '@material-ui/core/styles';
import {Growth} from './Growth'

const styles = {
    card: {
      width: 300,
    },
    media: {
      height: 140,
    },
  };

class MicrobeShow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                firstName: "",
                lastName: ""
            },
            facility: {
                name: "",
                organisation: {
                    name: ""
                }
            },
            dateSampleCollection: "",
            microbeEntries: []
            
        }
    }

    componentDidMount() {
        const id = window.location.hash.split("/")[2]
        const fetchUrl = `/v1/microbe/${id}`
        fetch(fetchUrl, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
                'Connection': 'keep-alive',
                "authorization": localStorage.getItem("token")
            },
            cache: "no-cache"
        })
        .then(res => res.json())
        .then(resp => {
            this.setState(resp)
        })
        .catch(err => console.error(err))
    }



   
    render() {

        const isPrint = window.location.hash.split("/")[window.location.hash.split("/").length-1] === "print"
        const { classes } = this.props;

        return (
            
            <div style={{maxWidth: "1000px",
                
                }}>
                <Title title="Dashboard" />
                { !isPrint &&
                    <Tooltip title="printer friendly version">
                    <Print 
                        onClick={() => {
                            const newUrl = window.location.hash.replace("#", "")+"/print"
                            this.props.history.push(newUrl)}}
                    />
                    </Tooltip>
                }
                <br/>

            {/* <div style={{ display: "inline-block", float: "left", clear: "both"}}> */}
                <List>
                    <ListItem>
                        <ListItemText
                            primary="Sample collection date"
                            secondary={this.state.dateSampleCollection && this.state.dateSampleCollection.substring(0,10)}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Facility"
                            secondary={this.state.facility.name}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Organisation"
                            secondary={this.state.facility.organisation.name}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Created by"
                            secondary={`${this.state.user.firstName} ${this.state.user.lastName}`}
                        />
                    </ListItem>
                </List>
                
                {/* {`MicrobeShow-media-2`} */}
                <Grid container spacing={2} className="theblock">
                <style>
                    {`@media print {.theblock{display:block;}}`}
                </style>
                {this.state.microbeEntries.map(entry => (
                    <Grid item className="chunk">
                        <style>
                            {`@media print {.chunk{page-break-inside:avoid;}}`}
                        </style>
                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.media}
                            image={entry.image.src}
                            title="dipslide"
                        />
                            <CardContent>
                                <Typography butterBottom variant="headline" component="h2">
                                    {entry.microbeSite.name}
                                </Typography>
                                <List>
                                    <ListItem style={{paddingBottom: "0px"}}>
                                        <ListItemText
                                            primary="Growth"
                                            secondary={entry.microbeGrowth.name}
                                        />
                                       
                                    </ListItem>
                                    <ListItem style={{paddingTop: "0px"}}>
                                        <Growth growthVal={entry.microbeGrowth.value} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Incubation time"
                                            secondary={`${entry.incubationTimeHrs}hrs`}
                                        />
                                    </ListItem>
                                    {entry.comment && 
                                        <ListItem>
                                        <ListItemText
                                            primary="Comment"
                                            secondary={entry.comment}
                                        />
                                        </ListItem>
                                    }
                                </List>
                            </CardContent>

                        
                    </Card>
                    </Grid>
                ))}
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(MicrobeShow)