import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  CardActions,
  CreateButton,
} from "react-admin";
import NameConcat from "../../components/NameConcat";
import saveAs from "file-saver";
import { Button, SvgIcon } from "@material-ui/core";

import ReportButton from "./poly-button-report";

class PolyExportButton extends React.Component {
  constructor() {
    super();
  }

  download = () => {
    //get ~/excel using filters if any
    const qryStr = window.location.hash.split("#/poly")[1];
    const fetchPath = `v1/poly/excel${!!qryStr ? qryStr : ""}`;
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    })
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, "polypharmacy.xlsx"))
      .catch((e) => {});
  };

  render() {
    let props = this.props;
    return (
      <Button color="primary" disabled={props.disabled} onClick={this.download}>
        <SvgIcon>
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z" />
        </SvgIcon>
        EXPORT
      </Button>
    );
  }
}

const FacilityFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Organisation"
      source="organisationId"
      reference="organisations"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Facility"
      source="facilityId"
      reference="facilities"
      filter={{ module: "poly" }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const PostActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
  basePath,
  ...rest
}) => {
  return (
    <CardActions>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <PolyExportButton disabled={total === 0} filter={filterValues} />
      <ReportButton
        facilityId={filterValues.facilityId}
        disabled={!filterValues.facilityId || total === 0}
      />
      <CreateButton basePath={basePath} />
    </CardActions>
  );
};

export const PolyListed = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    title="Polypharmacy"
    exporter={false}
    filters={<FacilityFilter />}
    actions={<PostActions />}
  >
    <Datagrid>
      <DateField source="date" sortable={false} />
      <NameConcat source="user" sortable={false} />
      {/* <TextField source="numResidents" label="Num residents" /> */}
      {/* <TextField source="numResidentsRegPrn" label="Num residents reg/prn" /> */}
      <TextField source="numResidentsReg" label="Num residents reg" />
      <TextField source="facility.name" sortable={false} label="Facility" />
      <TextField
        source="facility.organisation.name"
        sortable={false}
        label="Organisation"
      />
      <EditButton />
    </Datagrid>
  </List>
);

// export default connect(null, {
//     push,
// })(ReportButton);
