import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const DeleteDialog = ({ open, handleDelete, dialogDet }) => {
  const { nameOrId, medication } = dialogDet;
  const cancel = () => {
    handleDelete(false);
  };

  const del = () => {
    handleDelete(true);
  };

  return (
    <Dialog
      open={open}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Confirm row deletion`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span
            style={{ color: "red" }}
          >{`This row should only be deleted if it was created by mistake. If ${nameOrId} is no longer taking ${medication}, please use the cease button on the left of the row instead`}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={del} variant="contained" color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
