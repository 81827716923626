import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const NameConcat = ({ source, record = {} }) => {
  return record.mostRecentHistory ? (
    <span>{`${record.mostRecentHistory.user.firstName} ${record.mostRecentHistory.user.lastName}`}</span>
  ) : (
    <span></span>
  );
};

const FacilityFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Organisation"
      source="organisationId"
      reference="organisations"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Facility"
      source="facilityId"
      reference="facilities"
      filter={{ module: "psa" }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const PsasCurrent = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="Psychotropic Self-assessments"
      exporter={false}
      filters={<FacilityFilter />}
      empty={false}
    >
      <Datagrid rowClick={(id, basePath, record) => `/psa/${id}`}>
        <TextField source="name" sortable={false} label="Facility" />
        <TextField
          source="organisation.name"
          sortable={false}
          label="Organisation"
        />
        <DateField source="lastUpdated" label="Last updated" />
      </Datagrid>
    </List>
  );
};
