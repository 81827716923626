import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import MaskedInput from "react-text-mask";
import SaGenericSelect from "./sa-generic-select";
import SaNameSelect from "./sa-name-select";
import SaIndicationSelect from "./sa-indication-select";
import { TextValidator } from "react-material-ui-form-validator";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";

const style = (theme) => ({
  root: {
    width: "300px",
  },
  selected: {
    backgroundColor: "#e1e9fc !important",
  },
});

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/]}
      placeholderChar={"\u2000"}
      placeholder="YYYYMMDD"
      showMask
      guide={false}
      keepCharPositions={false}
      style={{ width: "105px" }}
      minLength={4}
    />
  );
};

class SaDataRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    //do shallow comparison to determine if row should update

    if (this.props.highlight !== nextProps.highlight) {
      return true;
    }
    const keysOfConcern = [
      "date",
      "nameOrId",
      "medicationId",
      "regular",
      "regularDose",
      "prn",
      "prnNumDoses",
      "prnNumDays",
      "indication",
      "indicationType",
      "behaviour",
      "altRestraint",
      "participate",
      "decisionDate",
      "repsInformed",
      "freqMonitoring",
      "lastReviewDate",
    ];

    const a = this.props.row;
    const b = nextProps.row;
    for (var key of keysOfConcern) {
      if (a[key] !== b[key]) {
        return true;
      }
    }
    return false;
  }

  getGeneric = (selectedMedId) => {
    if (!selectedMedId) {
      return {
        upper: 2000,
        lower: 0,
      };
    }
    return this.props.generics[selectedMedId];
  };

  render() {
    const {
      row,
      classes,
      index,
      handleRowValChange,
      isDateValid,
      showConfirmDelete,
      insertRow,
      readonly,
      highlight,
    } = this.props;
    let selected = false;
    if (highlight) {
      if (highlight === 1) {
        //determine if row potential checmical restraint
        if (!row.indication) {
          selected = true;
        }
      }
      if (highlight === 2) {
        //determine if pending informed consent
        if (!row.indication && !row.repsInformed) {
          selected = true;
        }
      }
    }

    return (
      <TableRow
        key={index}
        style={{ height: "40px" }}
        selected={selected}
        classes={{ selected: classes.selected }}
      >
        <TableCell>
          {!readonly && (
            <>
              <Tooltip title="Delete">
                <DeleteIcon onClick={() => showConfirmDelete(index)} />
              </Tooltip>
            </>
          )}
        </TableCell>
        <TableCell>
          {!readonly && (
            <>
              <Tooltip title="Add new row below">
                <AddIcon onClick={() => insertRow(index)} />
              </Tooltip>
            </>
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TextField
            className={classes.date}
            InputProps={{
              inputProps: { tabIndex: -1 },
              readOnly: readonly,
              style: {
                fontSize: 13,
              },
            }}
            value={row.date}
          />
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="name or id">
            <SaNameSelect
              namesSelect={this.props.namesSelect}
              selectedVal={row.nameOrId}
              handleRowValChange={handleRowValChange}
              disabled={readonly}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <SaGenericSelect
            readonly={readonly}
            genericsSelect={this.props.genericsSelect}
            handleRowValChange={handleRowValChange}
            // selectedVal={row.medicationId}
            selectedVal={
              row.medicationId && {
                value: row.medicationId,
                label: this.props.generics[row.medicationId].generic,
              }
            }
          />
        </TableCell>

        <TableCell className={classes.tableCell} align="center">
          <Tooltip title="Is regular rx?">
            <Checkbox
              disabled={readonly}
              checked={row.regular}
              onChange={(e) => handleRowValChange("regular", e.target.checked)}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} align="center">
          <Tooltip title="regular total daily dose in mg?">
            <TextValidator
              disabled={readonly}
              // value={props.row.prescriptions[props.medIndex].regular || ""}
              value={row.regularDose || ""}
              // className={props.classes.numInput}
              onChange={(e) =>
                handleRowValChange("regularDose", Number(e.target.value))
              }
              type="number"
              inputProps={{
                step: 0.01,
                style: {
                  readOnly: readonly,
                  fontSize: 13,
                  width: "50px",
                },
              }}
              validators={[
                `minFloat:${this.getGeneric(row.medicationId).lower}`,
                `maxFloat:${this.getGeneric(row.medicationId).upper}`,
                "isFloat",
              ]}
              errorMessages={[
                `Must be > ${this.getGeneric(row.medicationId).lower}`,
                `Must be < ${this.getGeneric(row.medicationId).upper}`,
                "must be a float",
              ]}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="PRN prescribed?">
            <Checkbox
              disabled={readonly}
              checked={row.prn}
              onChange={(e) => handleRowValChange("prn", e.target.checked)}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} align="center">
          <Tooltip title="PRN num doses">
            <TextField
              disabled={readonly}
              type="number"
              InputProps={{
                style: {
                  fontSize: 13,
                  width: "40px",
                },
              }}
              value={typeof row.prnNumDoses === "number" ? row.prnNumDoses : ""}
              onChange={(e) => {
                //allow null values
                let theVal = e.target.value;
                if (theVal === "") {
                  theVal = null;
                } else {
                  theVal = Number(theVal);
                }
                handleRowValChange("prnNumDoses", theVal);
              }}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="number of days covering doses period">
            <TextField
              disabled={readonly}
              type="number"
              InputProps={{
                style: {
                  fontSize: 13,
                  width: "40px",
                },
              }}
              value={typeof row.prnNumDays === "number" ? row.prnNumDays : ""}
              onChange={(e) => {
                //allow null values
                let theVal = e.target.value;
                if (theVal === "") {
                  theVal = null;
                } else {
                  theVal = Number(theVal);
                }
                handleRowValChange("prnNumDays", theVal);
              }}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="indication">
            <Checkbox
              disabled={readonly}
              checked={row.indication}
              onChange={(e) =>
                handleRowValChange("indication", e.target.checked)
              }
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="indication type">
            <SaIndicationSelect
              readonly={readonly}
              indicationSelect={() => {
                let filteredIndications = [];
                try {
                  filteredIndications = this.props.generics[
                    row.medicationId
                  ].indications.map((key, index) => ({
                    label: key,
                    value: key,
                  }));
                } catch (e) {
                  return [];
                }
                return filteredIndications;
              }}
              handleRowValChange={handleRowValChange}
              selectedVal={row.indicationType}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="behaviour">
            <TextField
              disabled={readonly}
              multiline
              value={row.behaviour}
              onChange={(e) => handleRowValChange("behaviour", e.target.value)}
              InputProps={{
                style: {
                  fontSize: 13,
                  width: "125px",
                },
              }}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="restraint alternative">
            <TextField
              multiline
              disabled={readonly}
              value={row.altRestraint}
              onChange={(e) =>
                handleRowValChange("altRestraint", e.target.value)
              }
              InputProps={{
                style: {
                  fontSize: 13,
                  width: "125px",
                },
              }}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="participation">
            <TextField
              disabled={readonly}
              multiline
              value={row.participate}
              onChange={(e) =>
                handleRowValChange("participate", e.target.value)
              }
              InputProps={{
                style: {
                  fontSize: 13,
                  width: "125px",
                },
              }}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="decision date">
            <TextField
              disabled={readonly}
              error={!isDateValid(row.decisionDate)}
              className={classes.date}
              InputProps={{
                inputComponent: TextMaskCustom,
                value: row.decisionDate,
                onChange: (e) =>
                  handleRowValChange("decisionDate", e.target.value || null),
                style: {
                  fontSize: 13,
                },
              }}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} padding={"checkbox"}>
          <div>
            <Tooltip title="reps informed">
              <Checkbox
                disabled={readonly}
                checked={row.repsInformed}
                onChange={(e) =>
                  handleRowValChange("repsInformed", e.target.checked)
                }
              />
            </Tooltip>
          </div>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="monitoring frequency">
            <TextField
              multiline
              disabled={readonly}
              value={row.freqMonitoring}
              onChange={(e) =>
                handleRowValChange("freqMonitoring", e.target.value)
              }
              InputProps={{
                style: {
                  fontSize: 13,
                  width: "75px",
                },
              }}
            />
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Tooltip title="last review date">
            <TextField
              disabled={readonly}
              error={!isDateValid(row.lastReviewDate)}
              className={classes.date}
              InputProps={{
                inputComponent: TextMaskCustom,
                value: row.lastReviewDate,
                onChange: (e) =>
                  handleRowValChange("lastReviewDate", e.target.value || null),
                style: {
                  fontSize: 13,
                },
              }}
            />
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(style)(SaDataRow);
