export default {
	"1": {
		"generic": "Risperidone",
		"lower": 0.01,
		"upper": 20,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"2": {
		"generic": "Olanzapine",
		"lower": 0.7,
		"upper": 100,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"3": {
		"generic": "Quetiapine",
		"lower": 3,
		"upper": 1000,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"4": {
		"generic": "Haloperidol",
		"lower": 0.1,
		"upper": 100,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"8": {
		"generic": "Diazepam",
		"lower": 0.1,
		"upper": 100,
		"indications": [
			"Anxiety",
			"Epilepsy",
			"Seizures"
		]
	},
	"9": {
		"generic": "Oxazepam",
		"lower": 1,
		"upper": 200,
		"indications": [
			"Anxiety"
		]
	},
	"10": {
		"generic": "Temazepam",
		"lower": 1,
		"upper": 100,
		"indications": [
			"Insonmia"
		]
	},
	"11": {
		"generic": "Agomelatine",
		"upper": 150,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"12": {
		"generic": "Amitriptyline",
		"upper": 500,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"13": {
		"generic": "Citalopram",
		"upper": 100,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"14": {
		"generic": "Clomipramine",
		"upper": 500,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"15": {
		"generic": "Dapoxetine",
		"upper": 100,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"16": {
		"generic": "Desvenlafaxine",
		"upper": 500,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"17": {
		"generic": "Dosulepin",
		"upper": 500,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"18": {
		"generic": "Doxepin",
		"upper": 500,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"19": {
		"generic": "Duloxetine",
		"upper": 300,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"20": {
		"generic": "Escitalopram",
		"upper": 100,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"21": {
		"generic": "Fluoxetine",
		"upper": 100,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"22": {
		"generic": "Imipramine",
		"upper": 300,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"23": {
		"generic": "Mianserin",
		"upper": 300,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"24": {
		"generic": "Milnacipran",
		"upper": 300,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"25": {
		"generic": "Mirtazapine",
		"upper": 300,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"26": {
		"generic": "Moclobemide",
		"upper": 1200,
		"lower": 50,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"27": {
		"generic": "Nortriptyline",
		"upper": 300,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"28": {
		"generic": "Paroxetine",
		"upper": 100,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"29": {
		"generic": "Phenelzine",
		"upper": 150,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"30": {
		"generic": "Reboxetine",
		"upper": 30,
		"lower": 0.5,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"31": {
		"generic": "Sertraline",
		"upper": 300,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"32": {
		"generic": "Tranylcypromine",
		"upper": 150,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"33": {
		"generic": "Venlafaxine",
		"upper": 500,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"34": {
		"generic": "Vortioxetine",
		"upper": 100,
		"lower": 1,
		"indications": [
			"Depression",
			"Anxiety",
			"Neuropathic pain"
		]
	},
	"35": {
		"generic": "Sodium valproate",
		"upper": 5000,
		"lower": 50,
		"indications": [
			"Bipolar",
			"Epilepsy"
		]
	},
	"37": {
		"generic": "Amisulpride",
		"lower": 50,
		"upper": 2000,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"38": {
		"generic": "Aripiprazole",
		"lower": 1,
		"upper": 100,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"39": {
		"generic": "Asenapine",
		"lower": 1,
		"upper": 50,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"40": {
		"generic": "Brexpiprazole",
		"lower": 0.1,
		"upper": 20,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"41": {
		"generic": "Chlorpromazine",
		"lower": 1,
		"upper": 1000,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"42": {
		"generic": "Clozapine",
		"lower": 1,
		"upper": 2000,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"43": {
		"generic": "Droperidol",
		"lower": 1,
		"upper": 50,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"44": {
		"generic": "Flupentixol",
		"lower": 0.1,
		"upper": 20,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"45": {
		"generic": "Lurasidone",
		"lower": 1,
		"upper": 300,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"46": {
		"generic": "Paliperidone",
		"lower": 0.1,
		"upper": 50,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"47": {
		"generic": "Periciazine",
		"lower": 0.1,
		"upper": 200,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"48": {
		"generic": "Trifluoperazine",
		"lower": 0.1,
		"upper": 100,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"49": {
		"generic": "Ziprasidone",
		"lower": 1,
		"upper": 500,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"50": {
		"generic": "Zuclopenthixol",
		"lower": 1,
		"upper": 190,
		"indications": [
			"Bipolar",
			"Schizophrenia",
		]
	},
	"51": {
		"generic": "Alprazolam",
		"lower": 0.1,
		"upper": 20,
		"indications": [
			"Anxiety"
		]
	},
	"52": {
		"generic": "Bromazepam",
		"lower": 1,
		"upper": 20,
		"indications": [
			"Anxiety"
		]
	},
	"53": {
		"generic": "Clobazam",
		"lower": 1,
		"upper": 100,
		"indications": [
			"Anxiety",
			"Insomnia associated with anxiety",
			"Epilepsy",
			"Seizures"
		]
	},
	"54": {
		"generic": "Clonazepam",
		"lower": 0.1,
		"upper": 20,
		"indications": [
			"Anxiety",
			"Epilepsy",
			"Seizures"
		]
	},
	"55": {
		"generic": "Flunitrazepam",
		"lower": 0.1,
		"upper": 10,
		"indications": [
			"Anxiety",
			"Insommnia associated with anxiety"
		]
	},
	"56": {
		"generic": "Lorazepam",
		"lower": 0.1,
		"upper": 10,
		"indications": [
			"Anxiety",
			"Insomnia associated with anxiety"
		]
	},
	"57": {
		"generic": "Midazolam",
		"lower": 0.1,
		"upper": 100,
		"indications": [
			"Anxiety",
			"Epilepsy",
			"Seizures"
		]
	},
	"58": {
		"generic": "Nitrazepam",
		"lower": 1,
		"upper": 20,
		"indications": [
			"Insomnia"
		]
	},
	"59": {
		"generic": "Fluvoxamine",
		"upper": 1000,
		"lower": 1,
		"indications": [
		]
	}
}

