import React from 'react'
import {Typography} from '@material-ui/core'

export const AmsAd = props => (
    <div>
         <Typography variant="body2" style={{ maxWidth: "700px"}}>
        <span style={{fontWeight: "bold",}}>This is a demonstration page.</span> Please contact Choice Aged Care on 1300 275 908 or email rmmr@choiceagedcare.com.au to arrange access any of the PPASS-3 Tool modules.
        </Typography>
        <br/>
        <Typography variant="body2" style={{maxWidth: "700px"}}>
         Following the recent Aged Care National Antimicrobial Prescribing Survey (acNAPS), barriers to appropriate use of antimicrobials were identified and a number of recommendations were made. The PPASS-3 antimicrobial stewardship (AMS) module has been designed to promote and measure the uptake of these recommendations and assist in meeting the Aged Care Quality and Safety Commission Standards.        </Typography>
        <br/>
        <img src={require('../files/AMS_table.png')} />
       
        <img src={require('../files/AMS_results.png')} />
    </div>
);