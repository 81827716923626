import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  createContext,
} from "react";
import classnames from "classnames";
import { NumResidentsUpdate } from "../../components/NumResidentsUpdate";
import { useAuthenticated } from "react-admin";
import saveAs from "file-saver";
import { DeleteRequestDialog } from "./delete-request-dialog";

import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  SvgIcon,
  withStyles,
  Typography,
  LinearProgress,

} from "@material-ui/core";

import Alert from '@material-ui/lab/Alert';

import { useNotify } from "react-admin";
import { CeaseDialog } from "./cease-dialog";
import { DeleteDialog } from "./delete-dialog";

import { PsaStagingRow } from "./psa-staging-row";
import { PsaDisplayRow } from "./psa-display-row";
import { PsaEditRow } from "./psa-edit-row";
import { isDateValid } from "../../funcs/funcs";
import { FacName } from "../../components/FacName";

const topRow = 27;
const middleRow = 110;
const bottomRow = 50;
const styles = (theme) => ({
  stickyHead: {
    lineHeight: "1rem",
    verticalAlign: "bottom",
    fontSize: "0.775rem",
  },
  headMiddle: {
    top: `${topRow}px`,
    height: "100px",
  },
  headBottom: {
    top: `${topRow + middleRow}px`,
    height: "40px",
  },
  headInput: {
    top: `${topRow + middleRow + bottomRow}px`,
    backgroundColor: "WhiteSmoke",
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  validation: {
    top: `${topRow + middleRow}px`,
    backgroundColor: "WhiteSmoke",
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  table: {
    borderLeftWidth: 1,
  },
  inputWidth: {
    // width: "125px",
  },
  date: {
    width: "75px",
  },
  tableCell: {
    padding: "5px",
  },
  txtBottom: {
    verticalAlign: "bottom",
  },
  colSpanCenter: {
    "text-align": "center",
    // "border-color": "#E0E0E0",
    "border-left": "1px solid #E0E0E0",
    "border-right": "1px solid #E0E0E0",
  },
  leftCell: {
    "border-left": "1px solid #E0E0E0",
  },
  rightCell: {
    "border-right": "1px solid #E0E0E0",
  },
  allCell: {
    "border-top": "1px solid #E0E0E0",
    "border-right": "1px solid #E0E0E0",
    "border-bottom": "1px solid #E0E0E0",
    "border-left": "1px solid #E0E0E0",
  },
  boldBoth: {
    "border-left": "2px solid #E0E0E0",
    "border-right": "2px solid #E0E0E0",
  },
  boldLeft: {
    "border-left": "2px solid #E0E0E0",
  },
  boldRight: {
    "border-right": "2px solid #E0E0E0",
  },
  noTop: {
    "border-top": "none",
  },
  noBottom: {
    "border-bottom": "none",
  },
  submitBtn: {
    margin: "10px",
  },
});

const rowTemplate = {
  regular: false,
  regularDose: 0,
  prn: false,
  indication: true,
  indicationType: "",
  behaviour: "",
  prnNumDoses: null,
  prnNumDays: null,
  altRestraint: "",
  participate: "",
  decisionDate: "",
  repsInformed: false,
  freqMonitoring: "",
  lastReviewDate: "",
  prnCircumstance: "",
  informedConsentDate: "",
  informedConsentHow: "",
  behaviourPlanImplemented: false,
  behaviourPlanDate: "",
  effectivenessMonitoring: false,
  comments: "",
  psaPrescription: {
    residentId: 0,
    resident: {
      nameOrId: "",
    },
    medicationId: 0,
    medication: {
      generic: "",
      indications: [],
    },
  },
};

export const HasUnlockedContext = createContext();
export const ApiDisabledContext = createContext(null)

const Psa = ({ classes, history, ...props }) => {
  useAuthenticated();
  const [staging, setStaging] = useState(rowTemplate);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [virginRows, setVirginRows] = useState([]);
  const [nameFilt, setNameFilt] = useState(false);
  const notify = useNotify();
  const facilityId = window.location.hash.split("/")[2];
  const [hasUnlocked, setHasUnlocked] = useState(false); //whether any of the rows are unlocked (should only be one)
  const [psaApiFieldsDisabled, setPsaApiFieldsDisabled] = useState([]); //whether any of the rows are unlocked (should only be one)
  
  //get rows data
  useEffect(() => {
    fetchFacApiDisabledFields();
    fetchRows();
  }, []);

  //filter results if known name is set
  useEffect(() => {
    setNameFilt(!!staging.psaPrescription.residentId);
  }, [staging.psaPrescription.resident.nameOrId]);

  const fetchFacApiDisabledFields = async () => {
    const response = await fetch(`/v1/facilities/${facilityId}`, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    const data = await response.json();
    setPsaApiFieldsDisabled(data.psaApiFieldsDisabled);
  }

  //update for change of locked status
  const fetchRows = () => {
    setLoading(true);
    const id = window.location.hash.split("/")[2];
    const fetchPath = `v1/psa/${id}`;
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          setLoading(false);
          notify(
            "there was a problem fetching data from the server, please contact support",
            "warning"
          );
          return;
        } else {
          return res.json();
        }
      })
      .then((resJson) => {
        //scrub dates to string
        const fin = resJson.map((row) => {
          return {
            ...row,
            decisionDate: row.decisionDate
              ? row.decisionDate.substring(0, 10)
              : null,
            lastReviewDate: row.lastReviewDate
              ? row.lastReviewDate.substring(0, 10)
              : null,
            behaviourPlanDate: row.behaviourPlanDate
              ? row.behaviourPlanDate.substring(0, 10)
              : null,
            informedConsentDate: row.informedConsentDate
              ? row.informedConsentDate.substring(0, 10)
              : null,

            psaPrescription: {
              ...row.psaPrescription,
            },
          };
        });
        setRows(fin);
        // setVirginRows(fin);
        setHasUnlocked(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // notify("Server error", "warning")
      });
  };
  const fetchRowsRef = useCallback(() => {
    fetchRows();
  }, []);

  //gives list of filtered rows if there's any results
  const filtRows = () => {
    if (nameFilt) {
      const retFilt = rows.filter(
        (row) =>
          row.psaPrescription.residentId === staging.psaPrescription.residentId
      );
      const hasResults = retFilt.length !== 0;
      if (!hasResults) {
        return rows;
      } else {
        return retFilt;
      }
    } else {
      return rows;
    }
  };

  const handleStagingHistChange = (changeObj) => {
    // Object.assign({}, oldState.staging, updtObj)
    setStaging({
      ...staging,
      ...changeObj,
    });
  };

  const handleStagingPrescriptionChange = (changeObj) => {
    setStaging({
      ...staging,
      psaPrescription: {
        ...staging.psaPrescription,
        ...changeObj,
      },
    });
  };

  const clearStaging = () => {
    setStaging({ ...rowTemplate });
  };
  const clearStagingRef = useCallback(() => {
    clearStaging();
  }, []);

  //

  const [dialogDet, setDialogDet] = useState({
    nameOrId: "",
    medication: "",
    prescriptionId: 0,
    reason: "",
    decisionDate: null,
  });

  const [ceaseOpen, setCeaseOpen] = useState(false);

  const handleCease = (doCease, ceaseDate) => {
    if (!doCease) {
      allDialogClose();
      return;
    }

    if (ceaseDate != null) {
      ceaseDate = new Date(ceaseDate)
    } else {
      ceaseDate = new Date()
    }
    // setDeleteRequestOpen(false);
    allDialogClose();
    const fetchPath = `v1/psapres/${dialogDet.prescriptionId}/cease`;
    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        cease_date: ceaseDate
      })
    }).then((res) => {
      if (res.status !== 200) {
        notify("Could not cease, contact support", {type: "warning"});
      } else {
        notify("Cease successful", {type: "success"});
        fetchRows();
      }
    });
  };

  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDelete = (doDelete) => {
    if (!doDelete) {
      allDialogClose();
      return;
    }
    const fetchPath = `v1/psapres/${dialogDet.prescriptionId}`;
    fetch(fetchPath, {
      method: "DELETE",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((res) => {
      if (res.status !== 200) {
        notify("Could not delete, contact support", {type: "warning"});
      } else {
        notify("Delete successful", {type: "success"});
        fetchRows();
      }
      allDialogClose();
    });
  };

  const getInvalidMsgs = (row, incNameMed) => {
    let ret = [];

    //pretty dirty, but only include name/med checks for staging
    if (incNameMed) {
      if (!row.psaPrescription.resident.nameOrId) {
        ret.push("Name or Id not set");
      }

      if (!row.psaPrescription.medicationId) {
        ret.push("Medication not set");
      }
    }

    // if (row.regularDose > 0 && !row.regular) {
    //   ret.push("Reg must be selected if a daily dose is entered");
    // }

    const med = row.psaPrescription.medication;
    if (
      row.regularDose &&
      (row.regularDose < med.lowerBound || row.regularDose > med.upperBound)
    ) {
      ret.push(
        `Regular dose is out of bounds, must be between ${med.lowerBound} and ${med.upperBound}mg`
      );
    }

    if (typeof row.regularDose !== "number" && !!row.regularDose) {
      ret.push("Regular dose is not a valid number");
    }

    //has to be a number or null
    if (!(typeof row.prnNumDoses === "number" || row.prnNumDoses === null)) {
      ret.push("PRN doses is not a valid number");
    }

    if (typeof row.prnNumDays !== "number" && !!row.prnNumDays) {
      ret.push("PRN days is not a valid number");
    }

    if (!isDateValid(row.decisionDate)) {
      ret.push("Decision date is not valid");
    }

    if (!isDateValid(row.lastReviewDate)) {
      ret.push("Date of last review not valid");
    }

    if (!isDateValid(row.behaviourPlanDate)) {
      ret.push("Date of behaviour plan not valid");
    }

    if (!isDateValid(row.informedConsentDate)) {
      ret.push("Date of informed consent not valid");
    }

    return ret;
  };
  const excel = () => {
    // const id = window.location.hash.split("/")[2]
    const fetchPath = `v1/psa/${facilityId}/excel`;
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    })
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, "psa.xlsx"))
      .catch((e) => {});
  };

  const [deleteReqOpen, setDeleteReqOpen] = useState(false);
  const handleDeleteRequest = (doAction) => {
    if (!doAction) {
      allDialogClose();
      return;
    }
    allDialogClose();
    notify("Sending request...", "success");
    const fetchPath = `v1/psapres/${dialogDet.prescriptionId}/deleterequest`;
    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({ reason: dialogDet.reason }),
    }).then((res) => {
      setDialogDet((prev) => ({ ...prev, reason: "" }));
      if (res.status !== 200) {
        notify("Could not send request, contact support", {type: "warning"});
      } else {
        notify("Delete request sent", {type: "success"});
      }
    });
  };

  const handleDecease = (doDecease) => {
    if (!doDecease) {
      allDialogClose();
      return;
    }
    allDialogClose();
    const fetchPath = `v1/residents/${dialogDet.residentId}/decease`;
    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((res) => {
      if (res.status !== 200) {
        notify("Could not send request, contact support", {type: "warning"});
      } else {
        notify("Resident set as deceased/discharged", {type: "success"});
        fetchRows();
      }
    });
  };

  const allDialogClose = () => {
    setDeleteOpen(false);
    setDeleteReqOpen(false);
    setCeaseOpen(false);
    setDialogDet({
      medication: "",
      nameOrId: "",
      prescriptionId: "",
      reason: "",
      decisionDate: null
    });
  };

  const apiShouldDisable = (dbKey) => {

  }

  return (
    <div style={{backgroundColor: "white", zIndex: 999}}>
      <CeaseDialog
        open={ceaseOpen}
        handleCease={handleCease}
        dialogDet={dialogDet}
        allDialogClose={allDialogClose}
      />

      <DeleteDialog
        open={deleteOpen}
        handleDelete={handleDelete}
        dialogDet={dialogDet}
        allDialogClose={allDialogClose}
      />

      <DeleteRequestDialog
        open={deleteReqOpen}
        handleDeleteRequest={handleDeleteRequest}
        handleCease={handleCease}
        handleDecease={handleDecease}
        dialogDet={dialogDet}
        setDialogDet={setDialogDet}
        allDialogClose={allDialogClose}
      />
      <a href="https://mailchi.mp/d05aeacd425b/updated-psychotropic-self-assessment-format-5110517" target="_blank" rel="noopener noreferrer" style={{color: "inherit", textDecoration: "inherit"}}>
            <Alert severity="warning">Self-assessments have recently changed, if you're unfamiliar, please click here</Alert>
            </a>

      <Tooltip title="download as excel file">
        <SvgIcon onClick={excel} style={{ padding: "5px" }}>
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z" />
        </SvgIcon>
      </Tooltip>
      <Tooltip title="view audit results">
        <SvgIcon
          style={{ padding: "5px" }}
          onClick={() =>
            history.push(`${window.location.hash.split("#")[1]}/results`)
          }
        >
          <path d="M22.8 11.819l-1.667 1.66-6.781-6.762-2.992 2.993-5.922-5.835-1.41 1.418 7.336 7.242 3.001-3.002 5.351 5.358-1.716 1.708 6 1.221-1.2-6.001zm1.2 8.181v2h-24v-20h2v18h22z" />
        </SvgIcon>
      </Tooltip>
      <FacName facId={facilityId} />
      <NumResidentsUpdate facilityId={facilityId} />
      <Table stickyHeader padding={"none"} style={{ width: "1500px" }}>
        <TableHead>
          <TableRow>
            <TableCell
              rowSpan={3}
              className={classnames(classes.stickyHead)}
            ></TableCell>
            <TableCell
              rowSpan={3}
              className={classnames(classes.stickyHead)}
            ></TableCell>
            <TableCell
              rowSpan={3}
              style={{ width: "100px" }}
              className={classnames(
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom
              )}
            >
              Last modified&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </TableCell>
            <TableCell
              className={classnames(
                classes.stickyHead,
                classes.leftCell,
                classes.tableCell,
                classes.txtBottom,
                classes.boldRight
              )}
              rowSpan={3}
            >
              <div style={{ width: "100px" }}>
                <SvgIcon fontSize="inherit">
                  <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                </SvgIcon>
              </div>
              Name or Id
            </TableCell>
            <TableCell
              rowSpan={3}
              style={{ width: "100px" }}
              className={classnames(
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom
              )}
            >
              Date first prescribed (approx. if unknown)
            </TableCell>
            <TableCell
              colSpan={6}
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.stickyHead
              )}
              style={{ textAlign: "center" }}
            >
              <Typography variant="body3">A.</Typography>
            </TableCell>
            <TableCell
              // colSpan={2}
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.stickyHead
              )}
              style={{ textAlign: "center" }}
            >
              B.
            </TableCell>
            <TableCell
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.stickyHead
              )}
              style={{ textAlign: "center" }}
            >
              C.
            </TableCell>
            <TableCell
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.stickyHead
              )}
              style={{ textAlign: "center" }}
              colSpan={2}
            >
              D.
            </TableCell>
            <TableCell
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.stickyHead
              )}
              style={{ textAlign: "center"}}
            >
              E.
            </TableCell>
            <TableCell
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.stickyHead
              )}
              style={{ textAlign: "center" }}
              colSpan={2}
            >
              F.
            </TableCell>
            <TableCell
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell
              )}
              style={{ textAlign: "center" }}
            >
              G.
            </TableCell>
            <TableCell
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell
              )}
              style={{ textAlign: "center" }}
            >
              H.
            </TableCell>
            <TableCell
              className={classnames(
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell
              )}
              style={{ textAlign: "center" }}
            >
              I.
            </TableCell>
            <TableCell rowSpan={4}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              rowSpan={2}
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.stickyHead,
                classes.headMiddle,
                classes.boldLeft
              )}
            >
              Name of psychotropic medication
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.leftCell,
                classes.tableCell,
                classes.txtBottom
              )}
              colSpan={2}
              style={{ width: "110px" }}
            >
              Reg Rx
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.leftCell,
                classes.tableCell,
                classes.noBottom
              )}
            ></TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.leftCell,
                classes.tableCell,
                classes.txtBottom,
                classes.boldRight,
                classes.noBottom
              )}
              colSpan={2}
            >
              Num PRN doses per X days
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.txtBottom
              )}
              // colSpan={2}
              rowSpan={2}
              style={{
                lineHeight: "1rem",
                verticalAlign: "bottom",
                fontSize: "0.775rem",
              }}
            >
              Reason for prescribing
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.txtBottom
              )}
              rowSpan={2}
              style={{ width: "175px" }}
            >
              If PRN, circumstances of use
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.txtBottom
              )}
              // rowSpan={2}
              style={{ width: "50px" }}
              colSpan={2}
            >
              Date and how prescriber last communicated informed consent was obtained
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.txtBottom
              )}
              rowSpan={2}
              style={{ width: "175px" }}
            >
              Is medication use identified as a restraint
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.txtBottom
              )}
              // rowSpan={2}
              colSpan={2}
            >
              Is behaviour support plan developed and implemented?
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.txtBottom
              )}
              rowSpan={2}
              style={{ width: "50px" }}
            >
              Is monitoring occurring for effectiveness and side effects?
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.txtBottom
              )}
              rowSpan={2}
              style={{ width: "75px" }}
            >
              Date of last formal medication review
            </TableCell>
            <TableCell
              className={classnames(
                classes.headMiddle,
                classes.stickyHead,
                classes.boldBoth,
                classes.tableCell,
                classes.txtBottom
              )}
              rowSpan={2}
              style={{ padding: "5px" }}
            >
              Additional info/comments
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.allCell
              )}
            >
              Reg
            </TableCell>
            <TableCell
              className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.allCell
              )}
            >
              Daily dose
            </TableCell>
            <TableCell
              className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.noTop,
                classes.tableCell,
                classes.txtBottom
              )}
            >
              PRN Rx
            </TableCell>
            <TableCell
              className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.allCell,
                classes.noTop
              )}
            >
              Doses
            </TableCell>
            <TableCell
              className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.allCell,
                classes.boldRight,
                classes.noTop
              )}
            >
              Days
            </TableCell>
            <TableCell className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.allCell,
                classes.boldRight,
                classes.noTop
              )}>
                Date

            </TableCell>
            <TableCell className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.allCell,
                classes.boldRight,
                classes.noTop
              )}>
              How
            </TableCell>
            <TableCell className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.allCell,
                classes.boldRight,
                classes.noTop
              )}>
              Yes?
            </TableCell>
            <TableCell className={classnames(
                classes.headBottom,
                classes.stickyHead,
                classes.tableCell,
                classes.txtBottom,
                classes.allCell,
                classes.boldRight,
                classes.noTop
              )}>
              Date
            </TableCell>
           
          </TableRow>
          <ApiDisabledContext.Provider
            value={{
              apiDisabled: () => false
            }}
          >
          <PsaStagingRow
            staging={staging}
            onChange={handleStagingHistChange}
            onStagingPrescriptionChange={handleStagingPrescriptionChange}
            // submit={submitRef(null)}
            headInput={classes.headInput}
            validation={classes.validation}
            invalidMsgs={getInvalidMsgs(staging, true)}
            clearStaging={clearStagingRef}
            facilityId={facilityId}
            fetchRows={fetchRows}
          />
          </ApiDisabledContext.Provider>
        </TableHead>
        <HasUnlockedContext.Provider value={hasUnlocked}>
          <TableBody>
            {nameFilt && (
              <TableRow>
                <TableCell colSpan={10}>
                  {`Showing known results for ${staging.psaPrescription.resident.nameOrId}`}
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={19}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}

            {filtRows().map((row, index) => {
              return (
                <ApiDisabledContext.Provider
                  value={{
                    apiDisabled: (dbKey) => {
                      if (!row.psaPrescription.resident.externalId) {
                        return false;
                      }
                      if (psaApiFieldsDisabled.includes(dbKey)) {
                        return true;
                      }
                      return false
                    }
                  }}
                >

                  {!!row.unlocked ? (
                    <PsaEditRow
                    row={row}
                    setHasUnlocked={setHasUnlocked}
                    setRows={setRows}
                    invalidMsgs={getInvalidMsgs(row, false)}
                    fetchRows={fetchRowsRef}
                    key={row.id}
                  />
                  ):
                  (
                    <PsaDisplayRow
                  row={row}
                  setRows={setRows}
                  setHasUnlocked={setHasUnlocked}
                  hasUnlocked={hasUnlocked}
                  key={row.id}
                  setDeleteOpen={setDeleteOpen}
                  setDeleteReqOpen={setDeleteReqOpen}
                  setCeaseOpen={setCeaseOpen}
                  setDialogDet={setDialogDet}
                />
                  )
                }
                
                </ApiDisabledContext.Provider>
              )}
            )}
              
                    
               
              
            
          </TableBody>
        </HasUnlockedContext.Provider>
      </Table>
    </div>
  );
};

export default withStyles(styles)(Psa);
