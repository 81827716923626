import {createContext} from 'react'

export const UserContext = createContext({
    token: () => localStorage.getItem("token"),
    level: () => localStorage.getItem("level"),
    organisationId: () => Number(localStorage.getItem("organisationId")),
    pharmacist: () => JSON.parse(localStorage.getItem("pharmacist")),
    loginAttempts: () => Number(localStorage.getItem("loginAttempts")),
    modules: () => JSON.parse(localStorage.getItem("modules")),
    get: async (path) => {
        return fetch(path, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
                'Connection': 'keep-alive',
                "authorization": localStorage.getItem("token")
            },
            cache: "no-cache"
        })
    },
    post: async (path, payload) => {

    }
})