import React from 'react';
import { 
    Edit, 
    SimpleForm, 
    SelectInput,
    FormDataConsumer,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    ReferenceArrayInput

} from 'react-admin';

export const UserEdit = props => {
    
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="firstName" />
                <TextInput source="lastName" />
                <TextInput source="password" />
                <TextInput source="email" />
                <BooleanInput source="optOutEmailPsaInactivity" />
                <BooleanInput source="disabled"/>
                <SelectInput source="level" choices={[
                    { id: 'organisation', name: 'organisation' },
                    { id: 'facility', name: 'facility' },
                    { id: 'super', name: 'super' },
                    { id: 'external', name: 'external'}
                ]} />
    
                <FormDataConsumer>
                {({formData, ...rest}) => (
                        formData.level === "super" ?
                        <BooleanInput source="pharmacist" />
                        :
                        <BooleanInput source="pharmacist" options={{
                            disabled: true
                        }} />
                    )}
                </FormDataConsumer>
                    
    
    
                <FormDataConsumer>
                     {({ formData, ...rest }) => (formData.level === "organisation" || formData.level === "facility") &&
                        <ReferenceInput label="Organisation" source="organisationId" reference="organisations" >
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                     }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        console.log("formdata: ", formData)
                        return (formData.level === "facility" && !!formData.organisationId) &&
                        <ReferenceArrayInput autoComplete='off' label="Facilities" source="uiFacilities" reference="facilities" filter={{organisationId: formData.organisationId}} perPage={200}>
                            <AutocompleteArrayInput 
                                optionText="name" {...rest} />
                        </ReferenceArrayInput>
                        } 
                    }
                </FormDataConsumer>
                
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData.level === "external" &&
                        <ReferenceArrayInput label="Facilities" source="uiFacilities" reference="facilities">
                            <AutocompleteArrayInput optionText="name" />
                        </ReferenceArrayInput>
                        } 
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
}