import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import { Print, KeyboardArrowDown } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { Title } from "react-admin";
import { useAuthenticated } from "react-admin";

const PsaResults = (props) => {
  useAuthenticated();
  const isPrint =
    window.location.hash.split("/")[
      window.location.hash.split("/").length - 1
    ] === "print";

  const [state, setState] = useState({
    sections: [],
    facility: {
      name: "",
      organisation: {
        name: "",
      },
    },
  });

  useEffect(() => {
    const auditId = window.location.hash.split("/")[2];
    const fetchUrl = `/v1/psa/${auditId}/results`;
    fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        authorization: localStorage.getItem("token"),
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then((resp) => {
        setState(resp);
      })
      .catch((err) => console.error(err));
  }, []);

  const generateGraph = (graphData, index, graphType) => {
    // colour consistency between regular/prn graphs
    let customColour = [];
    if (index === 0) {
      //prn ap
      customColour = [
        "rgba(255, 99, 132, 0.6)",
        "rgba(54, 162, 235, 0.6)",
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
        "rgba(255, 99, 132, 0.6)",
        "rgba(54, 162, 235, 0.6)",
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
      ];
    } else {
      customColour = [
        "rgba(255, 99, 132, 0.6)",
        // 'rgba(54, 162, 235, 0.6)',
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
        "rgba(255, 99, 132, 0.6)",
        "rgba(54, 162, 235, 0.6)",
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
      ];
    }

    // const data = {
    //     labels: [], //replaced
    //     datasets: [{
    //         data: [], //replaced
    //         backgroundColor: customColour
    //     }]
    // }

    const options = {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "", //replaced
      },
      legend: {
        display: true,
      },
      line: {
        tension: 0,
      },
      bezierCurve: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            type: "time",
            time: {
              unit: "month",
              tooltipFormat: "YYYY-MM-DD",
            },
          },
        ],
      },
    };

    graphData = {
      ...graphData,
      datasets: graphData.datasets.map((set) => {
        const setData = set.data.map((data) => {
          const datestr = data.x.substring(0, 10) + ":";
          return {
            ...data,
            x: new Date(datestr),
          };
        });
        return { ...set, data: setData };
      }),
    };

    let newData = JSON.parse(JSON.stringify(graphData));

    let newOptions = JSON.parse(JSON.stringify(options));

    // newData.labels = graphData.labels
    // newData.datasets[0].data = graphData.data
    // newData.datasets[0].lineTension = 0
    newOptions.title.text = graphData.title;

    // newData.datasets[0].backgroundColor = 'rgba(255, 99, 132, 0.6)'
    return (
      <Line data={newData} options={newOptions} width={400} height={400} />
    );
  };

  const handleShow = (index) => {
    // setState(prevState => ({
    //     [index]: !prevState[index]
    // }))
    setState({
      ...state,
      [index]: !state[index],
    });
  };

  return (
    <div>
      <Title title="Psychotropic self-assessment results" />
      {!isPrint && (
        <Tooltip title="printer friendly version">
          <Print
            onClick={() => {
              const newUrl = window.location.hash.replace("#", "") + "/print";

              props.history.push(newUrl);
            }}
          />
        </Tooltip>
      )}

      <List>
        <ListItem>
          <ListItemText
            primary="Date"
            secondary={state.date && state.date.substring(0, 10)}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="Facility" secondary={state.facility.name} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Organisation"
            secondary={state.facility.organisation.name}
          />
        </ListItem>
      </List>

      {state.sections.map((section, index1) => (
        <Paper
          elevation={isPrint ? 0 : 2}
          style={{
            width: "750px",
            display: "inline-block",
            clear: "both",
            padding: "10px",
            margin: "5px",
          }}
        >
          <Typography
            variant="title"
            style={{ cursor: "pointer" }}
            onClick={() => handleShow(index1)}
          >
            {section.sectionTitle}
            <KeyboardArrowDown style={{ marginBottom: "-5px" }} />
          </Typography>

          <Grid>
            {!state[index1] &&
              section.graphs.map((dataRow, index) => (
                <>
                  <div
                    style={{ width: "300px", float: "left", padding: "10px" }}
                  >
                    {generateGraph(dataRow, index)}
                  </div>
                  {(index + 1) % 4 === 0 && (
                    <>
                      <div style={{ pageBreakAfter: "always" }}></div>
                      <div>&nbsp;</div>
                    </>
                  )}
                </>
              ))}
          </Grid>
          <br />
        </Paper>
      ))}

      <Paper
        elevation={isPrint ? 0 : 2}
        style={{
          width: "750px",
          display: "inline-block",
          clear: "both",
          padding: "10px",
          margin: "5px",
        }}
      >
        <Typography
          variant="title"
          style={{ float: "left", paddingBottom: "10px", cursor: "pointer" }}
          onClick={() => handleShow(666)}
        >
          Raw Numbers
        </Typography>
        <KeyboardArrowDown
          style={{ float: "left", cursor: "pointer" }}
          onClick={() => handleShow(666)}
        />
        {state["666"] && (
          <div style={{ paddingTop: "20px" }}>
            <br />

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Num Residents</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.rawNumbers.map((row) => (
                  <TableRow>
                    <TableCell>{row[0]}</TableCell>
                    <TableCell>{row[1]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default PsaResults;
