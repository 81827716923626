import React from 'react';
import {Autocomplete} from '@material-ui/lab'
import {TextField} from '@material-ui/core'
import {Popper} from "@material-ui/core"

const styles = (theme) => ({
    popper: {
      maxWidth: "fit-content"
    }
  });

const CustPopper = (props) => <Popper {...props} style={styles.popper} placement="bottom-start" />

const IndicationAutocomplete = ({indications, value, onChange, disabled}) => {
    return <Autocomplete
        id="free-solo-demo"
        freeSolo
        disabled={disabled}
        disableClearable
        options={indications ? indications.map((indication) => indication.name) : []}
        value={value}
        onChange={(e, newVal) => {
            onChange({indicationType: newVal})
        }}
        PopperComponent={CustPopper}
        renderInput={(params) => (
            <TextField {...params}
                onChange={e => {
                    onChange({indicationType: e.target.value})
                }}
            />
        )}
        renderOption={(option, {inputValue }) => {
            return (
                <div style={{width: "200px"}}>
                   <span>{option}</span> 
                </div>
            )
        }}
    />
}

export default IndicationAutocomplete