import React, {useState} from 'react'
import {
    TableCell,
    TableRow,
    Tooltip,
    Collapse,
    Typography
} from '@material-ui/core'
import {Done, Clear} from '@material-ui/icons'
import {AmsRowInputs} from './ams-row-inputs'
import { ACTIONS } from './ams'

export const AmsRowUnlocked = ({
    row,
    // lockRow,
    dispatch,
    // hasRowChanged,
    // handleCancel,
    // handleChange,
    // invalidMsgs,
    submitEdit,
    ...rest
}) => {

    const [showInvalid, setShowInvalid] = useState(false)
    const showInvalidOrSubmit = () => {
        if (row.errors.length === 0) {
            submitEdit(row)
            setShowInvalid(false)
        } else {
            setShowInvalid(true)
        }
    }

    const handleChange = (obj) => {
        dispatch({type: ACTIONS.EDIT_ROW, payload: { ...row, ...obj}})
    }


    return (
        <React.Fragment>
        <TableRow>
            <TableCell>
                <Done color={(!row.hasChanged || (showInvalid && row.errors.length > 0)) ? "disabled" : ""} onClick={() => row.hasChanged && showInvalidOrSubmit()}/> 
            </TableCell>
            <TableCell>
            <Tooltip title="Cancel">
                    <Clear onClick={() => dispatch({type: ACTIONS.CLEAR, payload: {id: row.id}})}/>
                </Tooltip>
            </TableCell>
            <AmsRowInputs handleChange={handleChange} row={row}/>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={19}>
                <Collapse in={true} unmountOnExit>
                {row.errors.map(msg => <Typography style={{color: "red"}}>{msg}</Typography>)}
                </Collapse>
            </TableCell>
        </TableRow>
        </React.Fragment>
        
    )
}