import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const DeleteDialog = ({
    open,
    handleDelete,
    handleDeleteClose,
    name,
    medication
}) => {

  return (
      <Dialog
        open={open}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Confirm row deletion`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <span style={{color: "red"}}>{`This row should only be deleted if it was created by mistake. If ${name} is no longer taking ${medication}, please set the cease date then save`}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} >
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="contained" color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  );
}