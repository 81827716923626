import React from "react";
import {
  Create,
  SimpleForm,
  NumberInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  Toolbar,
  SaveButton,
  FormDataConsumer,
} from "react-admin";
import { dateParser } from "../../funcs/funcs";
import CancelButton from "../../components/CancelButton";
import { NumResidentsUpdate } from "../../components/NumResidentsUpdate";
import { Edit } from "react-admin";
import { Typography } from "@material-ui/core";

// record.facilityId ? <NumResidentsUpdate facilityId={record.facilityId} /> : <></>

export const NqiEdit = (props) => (
  <Edit
    title="Edit NQI submission"
    successMessage="NQI report saved"
    {...props}
  >
    <SimpleForm redirect="list">
      <DateInput
        source="date"
        // defaultValue={new Date()}
        parse={(v) => dateParser(v)}
        validate={[required()]}
        label="Collection date"
      />
      <ReferenceInput
        label="Select facility"
        source="facilityId"
        reference="facilities"
        validate={[required()]}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <Typography variant="h6" gutterBottom>
        Polypharmacy Indicator
      </Typography>

      <NumberInput
        source="polyNumAssessed"
        validate={[required()]}
        helperText="Total number of care recipients assessed for polypharmacy"
        label=""
      />
      <NumberInput
        source="polyNumExcluded"
        validate={[required()]}
        helperText="Total number excluded"
        label=""
      />
      <NumberInput
        source="polyNumNineOrMore"
        validate={[required()]}
        helperText="Total number prescriped 9 or more medications"
        label=""
      />
      <Typography variant="h6" gutterBottom>
        Antipsychotic Indicator
      </Typography>
      <NumberInput
        source="antipsyNumAssessed"
        validate={[required()]}
        helperText="Total number care recipients assessed for antipsychotic medications"
        label=""
      />
      <NumberInput
        source="antipsyNumExcluded"
        validate={[required()]}
        helperText="Total number excluded"
        label=""
      />
      <NumberInput
        source="antipsyNumReceived"
        validate={[required()]}
        helperText="Total number who received an antipsychotic medication"
        label=""
      />
      <NumberInput
        source="antipsyNumReceivedPsychosis"
        validate={[required()]}
        helperText="Total number who received an antipsychotic medication for diagnosed condition of psychosis"
        label=""
      />
    </SimpleForm>
  </Edit>
);
