import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const classes = {
  abody: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "6em",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
};

class ResetPw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirm: "",
      msg: "",
      msgTitle: "",
      showMsg: "",
      isErr: false,
      reCaptcha: "",
    };
  }

  handleAlertClose = () => {
    this.setState({
      showMsg: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // validate shitty password
    if (!this.state.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/g)) {
      this.setState({
        msgTitle: "PASSWORD RESET NOT SUCCESSFUL",
        msg: "Password must be at least 8 characters, contain an uppercase, lowercase and a number",
        showMsg: true,
        isErr: true,
      });
      return;
    }

    if (this.state.password.toLowerCase().includes("password")) {
      this.setState({
        msgTitle: "PASSWORD RESET NOT SUCCESSFUL",
        msg: "password cannot contain the word 'password'",
        showMsg: true,
        isErr: true,
      });
      return;
    }

    fetch(`passwordreset`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "applicaton/json",
      },
      //only include rows, numResidents
      body: JSON.stringify({
        password: this.state.password,
        resetId: window.location.hash.split("/").pop(),
        reCaptcha: this.state.reCaptcha,
      }),
    })
      .then((resp) => {
        if (!resp.ok) {
          this.setState({
            msgTitle: "PASSWORD RESET NOT SUCCESSFUL",
            msg: "Could not reset password, invalid reCaptcha or your password reset link is invalid or has expired",
            showMsg: true,
            isErr: true,
          });
        } else {
          this.setState({
            msgTitle: "Password reset successful",
            msg: <Link to={{ pathname: `/login` }}>Go to login page</Link>,
            showMsg: true,
            isErr: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          msgTitle: "PASSWORD RESET NOT SUCCESSFUL",
          msg: "No response from server, please contact support",
          isErr: true,
          showMsg: true,
        });
      });
  };

  updatePw = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  updateConfirm = (e) => {
    this.setState({
      passwordConfirm: e.target.value,
    });
  };

  setRecaptcha = (val) => {
    this.setState({
      reCaptcha: val,
    });
  };

  render() {
    let matched = this.state.passwordConfirm === this.state.password;
    let bothEmpty = !this.state.password && !this.state.passwordConfirm;
    let errShouldShow = false;
    if (!matched && (!!this.state.password || !!this.state.passwordConfirm)) {
      errShouldShow = true;
    }
    const recaptchaRef = React.createRef();
    return (
      <div style={classes.abody}>
        <Typography variant="display1">Reset Password</Typography>

        <form onSubmit={this.handleSubmit}>
          <TextField
            type="password"
            label="New password"
            onChange={this.updatePw}
            required
          />
          <br />
          <TextField
            type="password"
            label="Confirm password"
            onChange={this.updateConfirm}
            required
            style={{ marginBottom: "20px" }}
          />
          <br />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lfbp_wUAAAAAFcDrR8Owko9z36Ck2gpaE7zpQT7"
            onChange={this.setRecaptcha}
          />
          {!matched && (
            <span style={{ color: "red" }}>passwords do not match</span>
          )}
          <br />
          <Button
            type="submit"
            disabled={errShouldShow || bothEmpty || this.state.reCaptcha === ""}
            variant="contained"
          >
            Submit
          </Button>
        </form>
        <br />

        <Dialog open={this.state.showMsg} onClose={this.handleAlertClose}>
          <DialogTitle style={{ color: "red" }}>
            <span style={{ color: this.state.isErr ? "red" : "inherit" }}>
              {this.state.msgTitle}
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.msg}</DialogContentText>
          </DialogContent>
        </Dialog>

        <Typography>
          Password must be at least 8 characters, contain an uppercase,
          lowercase and a number
        </Typography>
      </div>
    );
  }
}

export default ResetPw;
