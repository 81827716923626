import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Check, KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.medication.generic}
        </TableCell>
        <TableCell>{row.restraint}</TableCell>
        <TableCell align="right">{row.startDate.substring(0, 10)}</TableCell>
        <TableCell align="right">
          {row.ceaseDate ? row.ceaseDate.substring(0, 10) : "Current"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Regular</TableCell>
                    <TableCell>Regular Dose</TableCell>
                    <TableCell>PRN</TableCell>
                    <TableCell>Indication</TableCell>
                    <TableCell>Indication Type</TableCell>
                    <TableCell>Behaviour</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="th" scope="row">
                        {historyRow.createdAt.substring(0, 10)}
                      </TableCell>
                      <TableCell>{historyRow.regular && <Check />}</TableCell>
                      <TableCell>{historyRow.regularDose}</TableCell>
                      <TableCell>{historyRow.prn && <Check />}</TableCell>
                      <TableCell>
                        {historyRow.indication && <Check />}
                      </TableCell>
                      <TableCell>{historyRow.indicationType}</TableCell>
                      <TableCell>{historyRow.behaviour}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

//update an existing prescription with indication only
const AddIndication = ({ histId, reload }) => {
  //update the history
  const [open, setOpen] = useState(false);
  const [indicationType, setIndicationType] = useState("");

  //post to blah
  const handlePostIndication = () => {
    fetch(`/v1/preshist/${histId}`, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({ indicationType: indicationType }),
    })
      .then((v) => {
        reload();
        setOpen(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //refresh (check if vals stay)
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Set indication</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={indicationType}
            onChange={(e) => setIndicationType(e.target.value)}
            label="Indication type"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={handlePostIndication}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setOpen(true)}>Set Indication</Button>
    </>
  );
};

export default function PrescriptionCurrentTable({ rows, reload }) {
  const [selectedRows, setSelectedRows] = useState({});

  const [toRender, setToRender] = useState([]);

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < rows.length; i++) {
      const row = rows[i];
      const index = i;
      const firstHist = row.history[0];
      const firstRow = (
        <TableRow key={firstHist.id}>
          <TableCell
            onClick={() =>
              setSelectedRows((old) => {
                return { ...old, [index]: !old[index] };
              })
            }
          >
            {!!selectedRows[index] ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            {firstHist.createdAt.substring(0, 10)}
          </TableCell>
          <TableCell>{row.medication.generic}</TableCell>
          <TableCell>{firstHist.regular && <Check />}</TableCell>
          <TableCell>{firstHist.regularDose}</TableCell>
          <TableCell>{firstHist.prn && <Check />}</TableCell>
          <TableCell>
            {firstHist.indication ? (
              <Check />
            ) : (
              <AddIndication histId={firstHist.id} reload={reload} />
            )}
          </TableCell>
          <TableCell>{firstHist.indicationType}</TableCell>
          <TableCell>{firstHist.behaviour}</TableCell>
        </TableRow>
      );
      temp.push(firstRow);

      const restHist = row.history.slice(1).map((hist) => (
        <TableRow key={hist.id}>
          <TableCell></TableCell>
          <TableCell component="th" scope="row">
            {hist.createdAt.substring(0, 10)}
          </TableCell>
          <TableCell>{row.medication.generic}</TableCell>
          <TableCell>{hist.regular && <Check />}</TableCell>
          <TableCell>{hist.regularDose}</TableCell>
          <TableCell>{hist.prn && <Check />}</TableCell>
          <TableCell>{hist.indication && <Check />}</TableCell>
          <TableCell>{hist.indicationType}</TableCell>
          <TableCell>{hist.behaviour}</TableCell>
        </TableRow>
      ));

      if (!!selectedRows[i] == true) {
        temp.push(...restHist);
      }
    }
    setToRender(temp);
  }, [rows.length, selectedRows, ...rows.map((row) => row.indication)]);

  return (
    <TableContainer component={Paper}>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        style={{ paddingLeft: "10px", paddingTop: "10px" }}
      >
        Current Prescriptions
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Medication</TableCell>
            <TableCell>Regular</TableCell>
            <TableCell>Regular Dose</TableCell>
            <TableCell>PRN</TableCell>
            <TableCell>Indication</TableCell>
            <TableCell>Indication Type</TableCell>
            <TableCell>Behaviour</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{toRender}</TableBody>
      </Table>
    </TableContainer>
  );
}
