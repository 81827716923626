import React from 'react';
import { 
    List, Datagrid, TextField, DateField
} from 'react-admin';


export const AuthLogsListed = props => (
    <List {...props} 
        bulkActionButtons={false}
        title="Auth Logs"
        exporter={false}
        >
        <Datagrid>
            <DateField source="date" sortable={false} showTime />
            <TextField source="message" sortable={false} />
        </Datagrid>
    </List>
);