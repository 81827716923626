import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Papa from "papaparse";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CheckIcon from "@material-ui/icons/CheckCircle";
import generics from "./generics";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from "@material-ui/core/SvgIcon";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Prompt } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import classnames from "classnames";
import saveAs from "file-saver";
import SaDataRow from "./sa-data-row";
import { Title } from "react-admin";

const styles = (theme) => ({
  root: {
    // width: '100%',
    // width: "1399px",
    // paddingLeft: "10px",
    // paddingTop: "10px",
    // paddingBottom: "10px",
    // marginTop: theme.spacing.unit * 3,
    // width: 'auto',
    // overflowX: 'auto',
    // overflow: 'hidden'
  },
  stickyHead: {
    // position: "fixed",
    // position: "sticky",
    // // position: "-webkit-sticky",
    // top: 0,
    // background: "white",
    // zIndex: 10
  },
  headMiddle: {
    top: "35px",
  },
  headBottom: {
    top: "162px",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  table: {
    // minWidth: 700,
    // width: "100%",
    borderLeftWidth: 1,
    // padding: "2px"
  },
  inputWidth: {
    // width: "78px",
    // maxWidth: "100px"
    width: "125px",
    // display: "block"
  },
  date: {
    width: "75px",
  },
  tableCell: {
    padding: "5px",
  },
  txtBottom: {
    verticalAlign: "bottom",
  },
  colSpanCenter: {
    "text-align": "center",
    // "border-color": "#E0E0E0",
    "border-left": "1px solid #E0E0E0",
    "border-right": "1px solid #E0E0E0",
  },
  leftCell: {
    "border-left": "1px solid #E0E0E0",
  },
  rightCell: {
    "border-right": "1px solid #E0E0E0",
  },
  allCell: {
    "border-top": "1px solid #E0E0E0",
    "border-right": "1px solid #E0E0E0",
    "border-bottom": "1px solid #E0E0E0",
    "border-left": "1px solid #E0E0E0",
  },
  boldBoth: {
    "border-left": "2px solid #E0E0E0",
    "border-right": "2px solid #E0E0E0",
  },
  boldLeft: {
    "border-left": "2px solid #E0E0E0",
  },
  boldRight: {
    "border-right": "2px solid #E0E0E0",
  },
  noTop: {
    "border-top": "none",
  },
  noBottom: {
    "border-bottom": "none",
  },
  submitBtn: {
    margin: "10px",
  },
});

const rowTemplate = {
  date: null,
  nameOrId: "",
  generic: "",
  regular: false,
  regularDose: 0,
  prn: false,
  prnNumDoses: null,
  // prnSinceDate: null,
  prnNumDays: null,
  indication: false,
  indicationType: "",
  behaviour: "",
  altRestraint: "",
  participate: "",
  decisionDate: null,
  repsInformed: false,
  freqMonitoring: "",
  lastReviewDate: null,
};

class SelfAssessment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoadedFromServer: false,
      addRowsNum: 1,
      errDialogOpen: false,
      saveSuccess: false,
      numResidents: null,
      isDraft: false,
      rows: [],
      loadErr: "",
      highlight: 0,
      hasBadPrint: false,
      showConfirmDelete: false,
    };

    this.genericsValKeyList = {};

    this.virginState = {};
    // let numPicker = this.numPicker.bind(this);
  }

  componentDidMount() {
    //add single row so table looks fine
    this.addRow();

    //get data from server
    //this will either be a draft or previously saved data
    this.genericsValKeyListFunc();
    this.getCurrentSA();
  }

  genericsValKeyListFunc = () => {
    Object.keys(generics).forEach((medId) => {
      const newKey = generics[medId].generic;
      this.genericsValKeyList[newKey] = Number(medId);
    });
  };

  genericsSelect = Object.keys(generics)
    .map((key) => ({
      value: key,
      label: generics[key].generic,
    }))
    .sort((a, b) => ("" + a.label).localeCompare(b.label));

  namesSelect = () => {
    let uniqNames = {};
    this.state.rows.forEach((rowObj, index) => {
      uniqNames[rowObj.nameOrId] = true;
    });
    return Object.keys(uniqNames).map((key, index) => ({
      label: key,
    }));
  };

  hasUnsavedChanges = () => {
    const virginRows = JSON.stringify(this.virginState.rows);
    const stateRows = JSON.stringify(this.state.rows);

    return virginRows !== stateRows;
  };

  addRow = () => {
    var newRows = [];
    for (var i = 0; i < this.state.addRowsNum; i++) {
      let newRow = JSON.parse(JSON.stringify(rowTemplate));
      newRow.date = this.formatDate(Date());
      newRows.push(newRow);
    }

    this.setState({
      rows: [...this.state.rows, ...newRows],
    });
  };

  updateAddRowsNum = (event) => {
    this.setState({
      addRowsNum: event.target.value,
    });
  };

  getCurrentSA = () => {
    const id = window.location.hash.split("/")[2];
    let fetchPath = "";
    if (this.props.readonly) {
      fetchPath = `v1/psas/${id}`;
    } else {
      fetchPath = `v1/psascurrent/${id}`;
    }
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            loadErr:
              "there was a problem fetching data from the server, please contact support",
          });
          return;
        } else {
          return res.json();
        }
      })
      .then((resJson) => {
        resJson.rows &&
          resJson.rows.forEach((row, i) => {
            if (resJson.rows[i].date) {
              resJson.rows[i].date = resJson.rows[i].date.substring(0, 10);
            }

            if (resJson.rows[i].decisionDate) {
              resJson.rows[i].decisionDate = resJson.rows[
                i
              ].decisionDate.substring(0, 10);
            }
            if (resJson.rows[i].lastReviewDate) {
              resJson.rows[i].lastReviewDate = resJson.rows[
                i
              ].lastReviewDate.substring(0, 10);
            }
            if (resJson.rows[i].generic) {
              resJson.rows[i].medicationId =
                this.genericsValKeyList[resJson.rows[i].generic];
            }
          });
        const tempVirginState = JSON.parse(JSON.stringify(resJson));
        this.virginState.rows = tempVirginState.rows || [
          JSON.parse(JSON.stringify(rowTemplate)),
        ];
        //scrub dates

        this.setState({
          numResidents: resJson.numResidents,
          isDraft: resJson.isDraft,
          rows: resJson.rows || [JSON.parse(JSON.stringify(rowTemplate))],
          dataLoadedFromServer: true,
          facility: resJson.facility,
        });
      })
      .catch((e) => {
        this.setState({
          loadErr:
            "there was a problem fetching data from the server, please contact support",
        });
        return;
      });
  };

  saveDraft = () => {
    const facilityId = window.location.hash.split("/")[2];
    const fetchPath = `v1/psascurrent/${facilityId}/draft`;
    let tempState = JSON.parse(JSON.stringify(this.state));
    tempState.isDraft = true;
    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(tempState),
    })
      .then((res) => {
        if (!res.ok) {
          //notify bad save
          this.setState({
            errDialogOpen: true,
            errDialogMsg: `There was a problem saving your draft, please contact support`,
          });
          return;
        }
        this.setState(
          {
            saveSuccess: true,
            isDraft: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                saveSuccess: false,
              });
            }, 1000);
          }
        );
        this.virginState.rows = JSON.parse(JSON.stringify(this.state.rows));
      })
      .catch((e) => {});
  };

  submitData = () => {
    //convert any empty string dates to null
    let toSend = JSON.parse(JSON.stringify(this.state));
    toSend.rows = toSend.rows.map((v, index) => {
      v.decisionDate = !!v.decisionDate ? new Date(v.decisionDate) : null;
      v.lastReviewDate = !!v.lastReviewDate ? new Date(v.lastReviewDate) : null;
      v.date = new Date(v.date);
      v.medicationId = Number(v.medicationId);
      return v;
    });

    const facilityId = window.location.hash.split("/")[2];
    const fetchPath = `v1/psascurrent/${facilityId}`;
    fetch(fetchPath, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(toSend),
    })
      .then((res) => {
        if (!res.ok) {
          //notify bad save
          this.setState({
            errDialogOpen: true,
            errDialogMsg: `There was a problem saving your self-assessment, please contact support`,
          });
          return;
        }
        return res.json();
      })
      .then((resp) => {
        this.virginState.rows = JSON.parse(JSON.stringify(this.state.rows));
        this.props.history.push(`/psas/${resp.saId}/auditresults`);
      })
      .catch((e) => {});
  };

  closeDialog = () => {
    this.setState({
      errDialogOpen: false,
    });
  };

  updateNumResidents = (event) => {
    this.setState({
      numResidents: Number(event.target.value),
    });
  };

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  //using 'yyyy-mm-dd' date, check whether valid
  isDateValid(strDate) {
    //empty dates are 'valid'
    if (!strDate) {
      return true;
    }

    //check for valid yyyy-mm-dd format
    const dateMatch = strDate.match(
      /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
    );
    if (dateMatch === null) {
      return false;
    }

    //date itself is valid or not
    const date = new Date(strDate);
    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return false;
      } else {
        return true;
        // date is valid
      }
    } else {
      return false;
      // not a date
    }
  }

  //if a row is unchanged from 'virgin' state, set return virgin date, otherwise new date
  getModifiedDate(row) {
    //get row without date
    //get virgin row without date
    //compare
    const keysOfConcern = [
      "nameOrId",
      "medicationId",
      "regular",
      "regularDose",
      "prn",
      "prnNumDoses",
      "prnNumDays",
      "indication",
      "indicationType",
      "behaviour",
      "altRestraint",
      "participate",
      "decisionDate",
      "repsInformed",
      "freqMonitoring",
      "lastReviewDate",
    ];
    const newDate = this.formatDate(Date());

    const matchedVRows = this.virginState.rows.filter((vRow) => {
      if (
        row.nameOrId === vRow.nameOrId &&
        row.medicationId === vRow.medicationId
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (matchedVRows.length === 0) {
      return newDate;
    }

    for (var key of keysOfConcern) {
      if (row[key] !== matchedVRows[0][key]) {
        return newDate;
      }
    }
    return matchedVRows[0].date;
  }

  handleRowValChange = (index) => (key, val) => {
    // if server bad response, don't allow form edit

    if (!this.state.dataLoadedFromServer) {
      return;
    }
    let tempRows = JSON.parse(JSON.stringify(this.state.rows));
    tempRows[index][key] = val;
    //update the 'last modified'
    tempRows[index].date = this.getModifiedDate(tempRows[index]);

    this.setState({
      rows: tempRows,
    });
  };

  allDatesAreValid = () => {
    const rowsLen = this.state.rows.length;
    for (var i = 0; i < rowsLen; i++) {
      const row = this.state.rows[i];
      // if (!this.isDateValid(row.decisionDate) || !this.isDateValid(row.lastReviewDate) || !this.isDateValid(row.prnSinceDate)) {
      if (
        !this.isDateValid(row.decisionDate) ||
        !this.isDateValid(row.lastReviewDate)
      ) {
        return false;
      }
    }
    return true;
  };

  closeInvalidDate = () => {
    this.setState({
      hasInvalidDate: false,
    });
  };

  closeBadPrint = () => {
    this.setState({
      hasBadPrint: false,
    });
  };

  print = () => {
    if (this.hasUnsavedChanges()) {
      this.setState({
        hasBadPrint: true,
      });
      return;
    }

    const psaId = window.location.hash.split("/")[2];
    const fetchPath = `v1/psas/${psaId}/excel`;
    fetch(fetchPath, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    })
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, "selfassessment.xlsx"))
      .catch((e) => {});
  };

  showConfirmDelete = (index) => {
    this.setState({
      showConfirmDelete: true,
      deleteIndex: index,
    });
  };

  handleConfirmDeleteClose = () => {
    this.setState({
      showConfirmDelete: false,
    });
  };

  deleteRow = () => {
    let rowsArr = [...this.state.rows];

    rowsArr.splice(this.state.deleteIndex, 1);
    this.setState({
      rows: rowsArr,
      showConfirmDelete: false,
    });
  };
  insertRow = (index) => {
    let rowsArr = JSON.parse(JSON.stringify(this.state.rows));
    const newRow = JSON.parse(JSON.stringify(rowTemplate));
    rowsArr.splice(index + 1, 0, newRow);
    this.setState({
      rows: rowsArr,
    });
  };

  postDataVerify = () => {
    //VALIDATE

    //chekc that all dates are valid
    if (!this.allDatesAreValid()) {
      this.setState({
        errDialogMsg:
          "One or more dates are invalid, please check for red underlines",
        errDialogOpen: true,
      });
      return;
    }

    //check if no rows
    if (this.state.rows.length === 0) {
      this.setState({
        errDialogMsg: "May not submit with no data rows",
        errDialogOpen: true,
      });
      return;
    }

    //must have generic selected
    for (var i = 0; i < this.state.rows.length; i++) {
      const row = this.state.rows[i];
      if (!row.medicationId) {
        this.setState({
          errDialogMsg: `No medication selected for name: ${row.nameOrId}`,
          errDialogOpen: true,
        });
        return;
      }
    }

    //check for duplicate name/medication id
    for (var i = 0; i < this.state.rows.length; i++) {
      const row1 = this.state.rows[i];
      const filtRows = this.state.rows.filter(
        (row2) =>
          row1.nameOrId === row2.nameOrId &&
          row1.medicationId === row2.medicationId
      );
      if (filtRows.length > 1) {
        this.setState({
          errDialogMsg: `${
            row1.nameOrId
          } has been entered more than once for the medication ${
            this.genericsValKeyList[row1.medicationId]
          }. This will affect results. Consolidate these two rows into a single entry to submit.`,
          errDialogOpen: true,
        });
        return;
      }
    }

    //check dose bounds
    for (var i = 0; i < this.state.rows.length; i++) {
      const row = this.state.rows[i];
      if (!row.regularDose) {
        continue; //ignore no-dose
      }

      const medObj = generics[row.medicationId];
      if (row.regularDose < medObj.lower || row.regularDose > medObj.upper) {
        this.setState({
          errDialogMsg: `${medObj.generic} dose out of bounds for patient ${row.nameOrId}`,
          errDialogOpen: true,
        });
        return;
      }
    }

    //reg must be checked if dose > 0
    for (var i = 0; i < this.state.rows.length; i++) {
      const row = this.state.rows[i];
      if (!row.regular && row.regularDose) {
        this.setState({
          errDialogMsg: `A regular total daily dose has been entered but the regular tick-box is unticked for ${row.nameOrId}. Tick regular if prescribed or deleted the amount in the total daily dose.`,
          errDialogOpen: true,
        });
        return;
      }
    }

    this.submitData();
  };

  handleCsv = ({ target }) => {
    Papa.parse(target.files[0], {
      complete: this.fillRowsWithCsv,
      header: true,
      skipEmptyLines: true,
    });
  };

  //replace rows with CSV data
  //if the column doesn't exist, keep existing if any
  fillRowsWithCsv = (result) => {
    const dataArr = result.data;
    let toSetArr = [];

    //get copy of state rows
    let cpyStateRows = JSON.parse(JSON.stringify(this.state.rows));

    //for each row in the CSV
    for (var i = 0; i < dataArr.length; i++) {
      let csvRow = JSON.parse(JSON.stringify(dataArr[i]));

      //convert to bool, num etc
      if (csvRow.regular !== undefined) {
        csvRow.regular = Boolean(csvRow.regular);
      }

      if (csvRow.regularDose !== undefined) {
        csvRow.regularDose = Number(csvRow.regularDose);
      }

      if (csvRow.prn !== undefined) {
        csvRow.prn = Boolean(csvRow.prn);
      }

      if (csvRow.prnNumDoses !== undefined) {
        csvRow.prnNumDoses = Number(csvRow.prnNumDoses);
      }

      if (csvRow.prnNumDays !== undefined) {
        csvRow.prnNumDays = Number(csvRow.prnNumDays);
      }

      if (csvRow.indication !== undefined) {
        csvRow.indication = Boolean(csvRow.indication);
      }

      if (csvRow.repsInformed !== undefined) {
        csvRow.repsInformed = Boolean(csvRow.repsInformed);
      }

      if (csvRow.decisionDate === "") {
        csvRow.decisionDate = null;
      }

      if (csvRow.lastReviewDate === "") {
        csvRow.lastReviewDate = null;
      }

      //transform generic names to be first char upper, rest lower case
      csvRow.generic =
        csvRow.generic.charAt(0).toUpperCase() +
        csvRow.generic.slice(1).toLowerCase();

      //ERROR CHECKING
      //generic is from list
      if (Object.keys(this.genericsValKeyList).indexOf(csvRow.generic) === -1) {
        //throw error
        this.setState({
          errDialogMsg: `Could not import csv, invalid generic name of ${
            csvRow.generic
          } in row ${i + 2} of file`,
          errDialogOpen: true,
        });
        return;
      }
      //check for matching
      //iterates over state to see if any match with current row
      let stateMatchedIndex = -1;
      for (var j = 0; j < cpyStateRows.length; j++) {
        const row = cpyStateRows[j];
        const matched =
          row.nameOrId === csvRow.nameOrId &&
          row.medicationId === csvRow.medicationId;
        if (matched) {
          stateMatchedIndex = j;
          break;
        }
      }

      //matched rows, overwrite staterow with csvrow
      if (stateMatchedIndex > -1) {
        //merge, favouring csv data
        csvRow = { ...cpyStateRows[stateMatchedIndex], ...csvRow };
        // if something has changed, update the date
        for (var key in csvRow) {
          if (key === "date") continue;
          if (csvRow[key] !== cpyStateRows[stateMatchedIndex][key]) {
            csvRow.date = this.formatDate(Date());
            break;
          }
        }
      } else {
        csvRow.date = this.formatDate(Date());
        csvRow = { ...JSON.parse(JSON.stringify(rowTemplate)), ...csvRow };
      }
      toSetArr.push(csvRow);
    }

    this.setState(
      {
        rows: [],
      },
      () => {
        this.setState({
          rows: toSetArr,
        });
      }
    );
  };

  sort = () => {
    let tempRows = JSON.parse(JSON.stringify(this.state.rows));
    tempRows.sort((a, b) => {
      return a.nameOrId.toLowerCase().localeCompare(b.nameOrId.toLowerCase());
    });
    this.setState({
      rows: tempRows,
    });
  };

  handleHighlight = (e) => {
    //highlight vals: 1, 2
    this.setState({
      highlight: e.target.value,
    });
  };

  render() {
    const { classes, readonly } = this.props;
    return (
      <>
        <Title title="Psychotropic self-assessment" />
        {!this.state.dataLoadedFromServer && !this.state.loadErr && (
          <>Loading...</>
        )}
        {this.state.loadErr && <Typography>{this.state.loadErr}</Typography>}

        <Paper
          className={classes.root}
          hidden={!this.state.dataLoadedFromServer}
        >
          {this.state.isDraft && (
            <div>
              <Typography color="error" variant="display1">
                DRAFT SELF-ASSESSMENT
              </Typography>
            </div>
          )}
          {/* <ValidatorForm
                    ref="form"
                    onSubmit={this.postDataVerify}
                    // onError={(errors) => this.handlePostErrors(errors)}
                    onError={this.postDataVerify}
                > */}

          <Tooltip title="download as excel file">
            <SvgIcon onClick={this.print} style={{ padding: "5px" }}>
              <path fill="none" d="M0 0h24v24H0V0z" />
              <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z" />
            </SvgIcon>
          </Tooltip>

          {readonly && (
            <Tooltip title="view audit results">
              <SvgIcon
                style={{ padding: "5px" }}
                onClick={() =>
                  this.props.history.push(
                    `${window.location.hash.split("#")[1]}/auditresults`
                  )
                }
              >
                <path d="M22.8 11.819l-1.667 1.66-6.781-6.762-2.992 2.993-5.922-5.835-1.41 1.418 7.336 7.242 3.001-3.002 5.351 5.358-1.716 1.708 6 1.221-1.2-6.001zm1.2 8.181v2h-24v-20h2v18h22z" />
              </SvgIcon>
            </Tooltip>
          )}

          <Typography variant="title">
            {this.state.facility ? this.state.facility.name : ""}
          </Typography>

          <br />
          {/* <TextValidator
                    className={classes.numResidents}
                    value={this.state.numResidents || ""}
                    label="Total residents"
                    disabled={readonly}
                    helperText="All currently admitted residents"
                    onChange={this.updateNumResidents}
                    validators={[`minNumber:0`]}
                    errorMessages={[`Must be > 0`]}
                    type="number"
                    required
                /> */}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="highlight">Highlight by</InputLabel>
            <Select
              value={this.state.highlight}
              onChange={this.handleHighlight}
              inputProps={{
                name: "highlight",
                id: "highlight",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={1}>Potential chemical restraint</MenuItem>
              <MenuItem value={2}>Pending informed consent</MenuItem>
            </Select>
          </FormControl>

          <Dialog open={this.state.errDialogOpen} onClose={this.closeDialog}>
            <DialogContent>
              <DialogContentText>{this.state.errDialogMsg}</DialogContentText>
            </DialogContent>
          </Dialog>

          <Dialog
            open={this.state.showConfirmDelete}
            onClose={this.handleConfirmDeleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>Confirm row delete</DialogTitle>
            <DialogActions>
              <Button onClick={this.handleConfirmDeleteClose}>Cancel</Button>
              <Button onClick={this.deleteRow}>Confirm</Button>
            </DialogActions>
          </Dialog>

          <Prompt
            message={(location) =>
              this.hasUnsavedChanges() &&
              !this.props.readonly &&
              this.state.rows.length !== 1
                ? "There is unsaved work, discard changes?"
                : true
            }
          />

          <Table
            stickyHeader
            padding={"none"}
            // style={{
            //     // width: "auto",
            //     width: "500px",
            //     tableLayout: "auto",
            //     backgroundColor: "white",
            //     borderCollapse: "separate"
            // }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  rowSpan={3}
                  className={classnames(classes.stickyHead)}
                ></TableCell>
                <TableCell
                  rowSpan={3}
                  className={classnames(classes.stickyHead)}
                ></TableCell>
                <TableCell
                  rowSpan={3}
                  className={classnames(
                    classes.stickyHead,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                >
                  Last modified
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.stickyHead,
                    classes.leftCell,
                    classes.tableCell,
                    classes.txtBottom,
                    classes.boldRight
                  )}
                  rowSpan={3}
                  onClick={this.sort}
                  style={{ cursor: "pointer" }}
                >
                  <Tooltip title="sort list by name">
                    <SvgIcon fontSize="inherit">
                      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                    </SvgIcon>
                  </Tooltip>
                  Name or Id
                </TableCell>
                <TableCell
                  colSpan={6}
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.stickyHead
                  )}
                  style={{ textAlign: "center" }}
                >
                  A.
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.stickyHead
                  )}
                  style={{ textAlign: "center" }}
                >
                  B.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.stickyHead
                  )}
                  style={{ textAlign: "center" }}
                >
                  C.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.stickyHead
                  )}
                  style={{ textAlign: "center" }}
                >
                  D.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.stickyHead
                  )}
                  style={{ textAlign: "center" }}
                >
                  E.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.stickyHead
                  )}
                  style={{ textAlign: "center" }}
                >
                  F.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell
                  )}
                  style={{ textAlign: "center" }}
                >
                  G.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell
                  )}
                  style={{ textAlign: "center" }}
                >
                  H.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell
                  )}
                  style={{ textAlign: "center" }}
                >
                  I.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  rowSpan={2}
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.tableCell,
                    classes.txtBottom,
                    classes.stickyHead,
                    classes.headMiddle,
                    classes.boldLeft
                  )}
                  style={{ width: "125px" }}
                >
                  Name(s) of psychotropic medication(s) (particularly
                  anti-psychotics and benzodiazepines).
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.leftCell,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  colSpan={2}
                  style={{ width: "110px" }}
                >
                  Reg Rx
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.leftCell,
                    classes.tableCell,
                    classes.noBottom
                  )}
                ></TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.leftCell,
                    classes.tableCell,
                    classes.txtBottom,
                    classes.boldRight,
                    classes.noBottom
                  )}
                  colSpan={2}
                >
                  Num PRN doses per X days
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.noBottom,
                    classes.tableCell
                  )}
                  colSpan={2}
                >
                  Is the medication(s) prescribed for a diagnosed condition,
                  mental disorder, physical illness or physical condition?{" "}
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  rowSpan={2}
                  style={{ width: "125px" }}
                >
                  Consumer behaviour relevant to the need for restraint that
                  informed the decision to prescribe psychotropic medication(s).
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  rowSpan={2}
                  style={{ width: "125px" }}
                >
                  Alternatives to restraint that have been used.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  rowSpan={2}
                  style={{ width: "125px" }}
                >
                  Opportunities to participate, and do things of interest to
                  them and frequency undertaken.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  rowSpan={2}
                >
                  Decision date – Practitioner’s decision to use restraint
                  (recorded in care services plan).
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  rowSpan={2}
                  style={{ width: "50px" }}
                >
                  Consumer representative(s) informed prior to restraint use, or
                  as soon as possible, if not practicable.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  rowSpan={2}
                  style={{ width: "75px" }}
                >
                  Frequency of monitoring for signs of distress or harm and
                  feedback to practitioner.
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headMiddle,
                    classes.stickyHead,
                    classes.boldBoth,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  rowSpan={2}
                  style={{ padding: "5px" }}
                >
                  Date of last review when consideration was given to ceasing or
                  minimising use.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={classnames(
                    classes.headBottom,
                    classes.stickyHead,
                    classes.tableCell,
                    classes.txtBottom,
                    classes.allCell
                  )}
                >
                  Reg
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headBottom,
                    classes.stickyHead,
                    classes.tableCell,
                    classes.txtBottom,
                    classes.allCell
                  )}
                >
                  Daily dose
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headBottom,
                    classes.stickyHead,
                    classes.noTop,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                >
                  PRN Rx Y/N
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headBottom,
                    classes.stickyHead,
                    classes.tableCell,
                    classes.txtBottom,
                    classes.allCell,
                    classes.noTop
                  )}
                >
                  Doses
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headBottom,
                    classes.stickyHead,
                    classes.tableCell,
                    classes.txtBottom,
                    classes.allCell,
                    classes.boldRight,
                    classes.noTop
                  )}
                >
                  Days
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headBottom,
                    classes.stickyHead,
                    classes.noTop,
                    classes.tableCell,
                    classes.txtBottom,
                    classes.boldLeft
                  )}
                >
                  Y/N
                </TableCell>
                <TableCell
                  className={classnames(
                    classes.headBottom,
                    classes.stickyHead,
                    classes.allCell,
                    classes.tableCell,
                    classes.txtBottom
                  )}
                  style={{ width: "125px", padding: "5px" }}
                >
                  Listed Primary Indication
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.rows.map(
                (row, index) => (
                  <></>
                )
                // <SaDataRow
                //     highlight={this.state.highlight}
                //     key={index}
                //     index={index}
                //     row={row}
                //     classes={classes}
                //     insertRow={this.insertRow}
                //     readonly={this.props.readonly}
                //     handleRowValChange={this.handleRowValChange(index)}
                //     isDateValid={this.isDateValid}
                //     generics={generics}
                //     genericsSelect={this.genericsSelect}
                //     namesSelect={this.namesSelect}
                //     showConfirmDelete={this.showConfirmDelete}
                // />
              )}
            </TableBody>
          </Table>

          {/* </ValidatorForm> */}
        </Paper>
      </>
    );
  }
}

SelfAssessment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelfAssessment);
