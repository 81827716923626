import React from 'react'
import {Typography} from '@material-ui/core'

export const DashboardAd = props => (
    <div>
        <Typography variant="body2" style={{ maxWidth: "700px"}}>
        <span style={{fontWeight: "bold",}}>This is a demonstration page.</span> Please contact Choice Aged Care on 1300 275 908 or email rmmr@choiceagedcare.com.au to arrange access any of the PPASS-3 Tool modules.
        </Typography>
        <br/>
        <Typography variant="body2" style={{maxWidth: "700px"}}>
        The dashboard view provides a quick overview of the most recent audits including psychotropics, polypharmacy and antimicrobial stewardship results. An actionable items section provides information on the status of each facility and whether there are audits or items requiring follow-up.
        </Typography>
        <br/>
        <img src={require('../files/Dashboard.png')} />

        
    </div>
);