import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";

export const CeaseDialog = ({ open, handleCease, dialogDet }) => {
  const { nameOrId, medication, decisionDate } = dialogDet;
  //decisiondate is the date the medication was started
  const close = () => {
    setCeaseDate((new Date()).toISOString().slice(0, 10));
    setDoSetCeased(false);
    handleCease();
  };

  const confirm = () => {
    const theDate = doSetCeased ? ceaseDate : null;
    handleCease(true, theDate);
    setCeaseDate((new Date()).toISOString().slice(0, 10));
    setDoSetCeased(false);
  };

  const maxCease = (new Date()).toISOString().slice(0, 10);

  const [ceaseDate, setCeaseDate] = React.useState((new Date()).toISOString().slice(0, 10));
  const [doSetCeased, setDoSetCeased] = React.useState(false);
 
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Confirm cease of medication`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Please confirm that ${nameOrId}'s ${medication} has been ceased whilst admitted.`}
          <br />
          <br />
          {`If ${nameOrId} was discharged or has deceased while this medication was in therapy, `}
          <span style={{ textDecoration: "underline" }}>do not cease</span>
          {` and set this resident as deceased/discharged in Resident Managment or by clicking the Resident's name.`}
          <br />
          <br />
          <span style={{ color: "red" }}>
            If this row was made by mistake, please use the delete button on the
            right of the row
          </span>
          <br/>
          <br/>

          <FormControlLabel
            control={
              <Checkbox
                checked={doSetCeased}
                onChange={(e) => setDoSetCeased(e.target.checked)}
                name="checkedB"
                color="primary"
                
              />
            }
            label="(optional) Set accurate cease date"
          />

          {doSetCeased && (
            <>
            <br/>
            <br/>
            <TextField 
              type="date"
              value={ceaseDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setCeaseDate(e.target.value)}
              inputProps={{
                min: decisionDate,
                max: maxCease,
              }}
  
            />
            </>
          )}


          
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={confirm} variant="contained" color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
