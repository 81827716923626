import React from "react";
import { CheckboxGroupInput } from "react-admin";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  TextField,
  Toolbar,
  SaveButton,
  FormDataConsumer
} from "react-admin";
import { authLevel } from "../../funcs/funcs";

const SaveOnlyToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={authLevel() === "external"} />
  </Toolbar>
);

export const ResidentEdit = (props) => {
  const transform = (data) => ({
    ...data,
    deceased: data.deceased ? new Date().toISOString() : null,
  });

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm toolbar={<SaveOnlyToolbar />}>
        <TextInput source="nameOrId" label="Name or ID" />
        <TextInput source="urn" label="URN (optional)" />
        {authLevel() === "super" && (
          // <TextInput source="externalId" label="External ID (api)" />
          <TextField source="externalId" label="External ID (api)" />
        )}
        
        <TextField source="facility.name" label="Facility" />
        <TextField source="facility.organisation.name" label="Organisation" />
        <TextField source="amsRowsOpen" label="Number of open AMS rows" />
        <TextField source="psaRowsOpen" label="Number of open PSA rows" />
        <FormDataConsumer>
                 {({ formData, ...rest }) => 
                     <BooleanInput source="deceased" label="Deceased/discharged" disabled={!!formData.externalId} />
                 }
             </FormDataConsumer>
        
        
      </SimpleForm>
    </Edit>
  );
};
