import { SimpleForm, Edit, TextInput } from "react-admin";
import React from "react";
import { NullableBooleanInput, Toolbar, SaveButton } from "react-admin";
import { FormDataConsumer, required } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-final-form";
import { ReferenceInput } from "react-admin";
import { AutocompleteInput } from "react-admin";
import CatheterTable from "./CatheterTable";
import NoCatheterTable from "./NoCatheterTable";
import Undertakings from "./Undertakings";
import { authLevel } from "../../funcs/funcs";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={authLevel() === "external"} />
  </Toolbar>
);

const InlineReferenceInput = ({
  optionText,
  label,
  source,
  reference,
  ...props
}) => {
  return (
    <div style={{ display: "inline-flex", marginRight: "1rem" }}>
      <ReferenceInput
        label={label}
        source={source}
        reference={reference}
        {...props}
      >
        <AutocompleteInput optionText={optionText} {...props} />
      </ReferenceInput>
    </div>
  );
};

//clears the bools when switching catheter opts
const ClearCathType = (props) => {
  const form = useForm();
  const handleClearAssessmentBools = () => {
    form.change("dysuria", undefined);
    form.change("flank", undefined);
    form.change("rever", undefined);
    form.change("confusion", undefined);
    form.change("frequency", undefined);
    form.change("urgency", undefined);
    form.change("incontinence", undefined);
    form.change("pain", undefined);
    form.change("blood", undefined);
    form.change("noSigns", undefined);
    form.change("rigors", undefined);
  };
  return (
    <NullableBooleanInput
      source={props.source}
      onChange={handleClearAssessmentBools}
      validate={[required()]}
    />
  );
};

export const UtiEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceInput
          label="Facility"
          source="facilityId"
          reference="facilities"
          filter={{ module: "tdontd" }}
          formClassName={classes.inlineBlock}
          validate={[required()]}
        >
          <AutocompleteInput
            optionText="name"
            options={{ style: { display: "inline-flex", marginRight: "1rem" } }}
          />
        </ReferenceInput>
        <TextInput source="userFullName" label="Your name" validate={[required()]} />
        <TextInput source="userRole" label="Your role" validate={[required()]}/>
        <TextInput source="residentName" label="Resident name" validate={[required()]} />

        <TextInput source="pulse" />
        <TextInput source="bp" />
        <TextInput source="respRate" />
        <TextInput source="temp" />
        <ClearCathType source="catheter" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            switch (formData.catheter) {
              case null:
                return <></>;
              case true:
                return (
                  <>
                    <CatheterTable style={{ width: "1000px" }} />
                    <Undertakings style={{ width: "1000px" }} />
                  </>
                );
              case false:
                return (
                  <>
                    <NoCatheterTable style={{ width: "1000px" }} />
                    <Undertakings style={{ width: "1000px" }} />
                  </>
                );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
