import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import { ResourceSelect } from "../../components/ResourceSelect";

const TdontdTableAll = ({ rows, dispatch }) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Agent</TableCell>
            <TableCell>Dose</TableCell>
            <TableCell>Route</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell>Start date</TableCell>
            <TableCell>
              Stop or review date? Indicate which is documented
            </TableCell>
            <TableCell>What is the stop or review date?</TableCell>
            <TableCell>Condition being treated: Site involved</TableCell>
            <TableCell>For urinary tract sites, more details</TableCell>
            <TableCell>
              For urinary tract sites, Indication: Treatment or Prophylaxis
            </TableCell>
            <TableCell>
              For 3 and 6-month audits only. Has resident received this
              antibiotic continuously since previous audit?
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, i) => (
            <TableRow>
              <TableCell>{row.medication.generic}</TableCell>
              <TableCell>
                <TextField
                  value={row.dose || ""}
                  error={!row.dose}
                  onChange={(e) => {
                    dispatch({
                      type: "allRowVal",
                      index: i,
                      payload: { dose: e.target.value },
                    });
                  }}
                />
              </TableCell>
              <TableCell>{row.route}</TableCell>
              <TableCell>
                <Select
                  value={row.frequency}
                  error={!row.frequency}
                  onChange={(e) => {
                    dispatch({
                      type: "allRowVal",
                      index: i,
                      payload: { frequency: e.target.value },
                    });
                  }}
                >
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="BD">BD</MenuItem>
                  <MenuItem value="TDS">TDS</MenuItem>
                  <MenuItem value="QID">QID</MenuItem>
                </Select>
              </TableCell>
              <TableCell>{row.startDate}</TableCell>
              <TableCell>
                <Select
                  value={row.stopOrReview || ""}
                  error={!row.stopOrReview}
                  onChange={(e) => {
                    dispatch({
                      type: "allRowVal",
                      index: i,
                      payload: { stopOrReview: e.target.value },
                    });
                  }}
                >
                  <MenuItem value="Stop">Stop</MenuItem>
                  <MenuItem value="Review">Review</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <TextField
                  type="date"
                  value={row.stopOrReviewDate || ""}
                  error={!row.stopOrReviewDate}
                  onChange={(e) => {
                    dispatch({
                      type: "allRowVal",
                      index: i,
                      payload: { stopOrReviewDate: e.target.value },
                    });
                  }}
                />
              </TableCell>
              <TableCell>{row.site.name}</TableCell>
              <TableCell>
                {row.siteId !== 13 ? "n/a" : row.siteDetails}
              </TableCell>
              <TableCell>{row.treatmentOrProphy}</TableCell>
              <TableCell>
                <Select
                  value={row.continuous || ""}
                  error={!row.continuous}
                  onChange={(e) => {
                    dispatch({
                      type: "allRowVal",
                      index: i,
                      payload: { continuous: e.target.value },
                    });
                  }}
                >
                  <MenuItem value="Y">Y</MenuItem>
                  <MenuItem value="N">N</MenuItem>
                  <MenuItem value="Not known">Not known</MenuItem>
                  <MenuItem value="Not applicable (Baseline audit)">
                    Not applicable (Baseline audit)
                  </MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TdontdTableAll;
