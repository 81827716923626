import { TextInput } from "react-admin";
import React from "react";
import { FormDataConsumer, BooleanInput, DateInput } from "react-admin";
import { Typography, Divider } from "@material-ui/core";

const InlineBool = (props) => (
  <div style={{ display: "inline-flex", marginRight: "1rem", width: "250px" }}>
    <BooleanInput {...props} />
  </div>
);

const InlineDate = (props) => (
  <div style={{ display: "inline-flex", marginRight: "1rem" }}>
    <DateInput {...props}/>
  </div>
);

const TheDivider = () => (
  <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
);

const Undertakings = ({style, ...rest}) => {
  return (
    <div {...style}>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <Typography
              variant="h4"
              gutterBottom
              style={{ paddingTop: "25px" }}
            >
              Actions
            </Typography>

            <InlineBool source="cultureSent" label="Culture sent" />

            <InlineDate
              source="cultureSentDate"
              disabled={!formData.cultureSent}
            />

            <Typography>Recommended if UTI possible</Typography>
            <Typography>
              Preferred collection techniques: MSU, clean-catch (e.g., if
              incontinent). Transport to lab within 2 hours or refrigerate
              (4-10°C until transported).
            </Typography>

            <TheDivider />

            <InlineBool
              source="dipstick"
              label="Was urine Dipstick performed?"
            />
            <InlineDate source="dipstickDate" disabled={!formData.dipstick} />
            <Typography>
              Do not perform unless UTI possible, this is not recommended
              without discussion with GP.
            </Typography>
            <TheDivider />

            <InlineBool source="gpReview" label="GP Review requested" />
            <InlineDate
              source="gpReviewDate"
              label="GP Review Date"
              disabled={!formData.gpReview}
            />
            <TheDivider />

            <InlineBool source="hydration" label="Hydration assessed" />
            <InlineDate
              source="hydrationDate"
              label="Hydration assessed date"
              disabled={!formData.hydration}
            />
            <Typography>
              Assess hydration status and encourage fluid intake if dehydrated
            </Typography>
            <TheDivider />

            <InlineBool source="antibiotics" />
            <InlineDate
              source="antibioticsDate"
              disabled={!formData.antibiotics}
            />
            <TextInput
              source="antibioticsPrescription"
              label="Document prescription"
              disabled={!formData.antibiotics}
            />
            <Typography>
              Were antibiotics prescribed? If so, document prescription (e.g.
              trimethoprim 300 mg orally nocte for 3-days)
            </Typography>
            <TheDivider />

            <InlineBool source="cultureFollowup" />
            <InlineDate
              source="cultureFollowupDate"
              disabled={!formData.cultureFollowup}
            />
            <Typography>
              Urine culture results followed up? Lab results usually available
              within 72 hours. Nursing staff should follow up and discuss with
              GP (and resident) culture results, review clinical progress and
              antibiotic plan.{" "}
            </Typography>
          </>
        )}
      </FormDataConsumer>
    </div>
  );
};

export default Undertakings;
