import React from 'react';
import { List, Datagrid, TextField, EmailField, DateField, Filter, ReferenceInput, AutocompleteInput, EditButton } from 'react-admin';

const FacilityFilter = (props) => (
    <Filter {...props}>
         <ReferenceInput label="Facility" source="facilityId" reference="facilities" >
             <AutocompleteInput optionText="name" />
         </ReferenceInput>
    </Filter>
)

export const ExtUsersListed = props => (
    <List {...props} 
        bulkActionButtons={false}
        title="Users"
        exporter={false}
        filters={<FacilityFilter/>}
        >
        <Datagrid>
            <TextField source="firstName" sortable={false} />
            <TextField source="lastName" sortable={false} />
            <EmailField source="email" sortable={false} />
            <DateField source="lastActive" sortable={false} showTime/>
            {(window.localStorage.getItem("level") === "super" && window.localStorage.getItem("pharmacist") !== "true" ) &&
                <EditButton />
            }

        </Datagrid>
    </List>
);