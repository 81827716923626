import React from 'react';
import { 
    Edit, 
    SimpleForm, 
    TextInput,
    TextField,
} from 'react-admin';

export const OrgEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);