import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ReCAPTCHA from "react-google-recaptcha";


const classes = {
    abody: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '6em',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
};


class ForgotPw extends Component {

    constructor(props) {
        super(props)
        this.state = {
            username: "",
            msg: "",
            reCaptcha: ""
        }
    }


    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({
            msg: "Please wait..."
        })
        fetch(`passwordresetreq`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'applicaton/json'
            },
            body: JSON.stringify(
                {
                    username: this.state.username,
                    reCaptcha: this.state.reCaptcha
                })
        }).then(resp => {
            window.grecaptcha.reset();
            return resp.text()
        }).then(msg => {
            this.setState({
                msg: msg
            })
        })

    }

    updateUsername = (e) => {
        this.setState({
            username: e.target.value
        })
    }

    setRecaptcha = val => {
        this.setState({
            reCaptcha: val
        })
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }


    render() {
        const recaptchaRef = React.createRef();
        return (
            <div style={classes.abody}>
                <Typography variant="display1">
                    Password reset
                </Typography>
                
                <form onSubmit={this.handleSubmit} required>
                    <TextField
                        error={this.state.username === "" ? false : !this.validateEmail(this.state.username)}
                        style={{marginBottom: "10px"}}
                        label="Email"
                        helperText="enter your email address"
                        onChange={this.updateUsername}
                    /><br/>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lfbp_wUAAAAAFcDrR8Owko9z36Ck2gpaE7zpQT7"
                        onChange={this.setRecaptcha}
                    />
                    <Button type="submit" variant="contained" style={{marginTop: "10px"}} disabled={!(this.validateEmail(this.state.username) && this.state.reCaptcha !== "")}>Submit</Button>
                </form>
                <br/>
                <Typography>
                    {this.state.msg}
                </Typography>
            </div>
        );
    }
}

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme

export default ForgotPw;





