import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@material-ui/core";
import PrescriptionHistoryTable from "./prescription-history-table";
import { useNotify, useRedirect } from "react-admin";
import ReviewHistory from "./review-history-table";
import PrescriptionCurrentTable from "./prescription-current-table";

const CreateCr = ({}) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const [actions, setActions] = useState([]);
  const [pharmacistComment, setPharmacistComment] = useState("");

  const [resident, setResident] = useState({
    currentPsaPrescriptions: [],
    ceasedPsaPrescriptions: [],
    crReviews: [],
    nameOrId: "",
    facility: {
      name: "",
    },
  });

  const initSetResident = (res) => {
    //set whether the prescription is currently, never, or previously been under restraint
    if (!res.ceasedPsaPrescriptions) {
      return;
    }

    const temp = res.ceasedPsaPrescriptions.map((pres) => {
      if (!pres.history[0].indication) {
        return {
          ...pres,
          restraint: "Restraint",
        };
      }
      const everRestraint = pres.history.some((hist) => !hist.indication);
      return {
        ...pres,
        restraint: everRestraint ? "Previously" : "Never",
      };
    });
    setResident({
      ...res,
      ceasedPsaPrescriptions: temp,
    });
  };

  const initActions = (currentPrescs) => {
    if (!currentPrescs) {
      return;
    }
    const tempActions = currentPrescs.map((presc) => {
      return {
        psaPrescriptionHistoryId: presc.history[0].id,
        recommendation: "",
        medication: presc.medication,
        medicationId: presc.medication.medicationId,
        isRestraint: !presc.history[0].indication,
      };
    });
    setActions(tempActions);
  };

  const setAction = (index) => (e) => {
    let newActions = [...actions];
    newActions[index].recommendation = e.target.value;
    setActions(newActions);
  };

  const getData = () => {
    //get resident from url
    const resId = window.location.hash.split("/")[2];
    //get medications from api
    const fetchUrl = `/v1/residents/${resId}`;
    fetch(fetchUrl, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        authorization: localStorage.getItem("token"),
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then((resp) => {
        // setResident(resp)
        initSetResident(resp);
        initActions(resp.currentPsaPrescriptions);
      });
  };

  useEffect(() => {
    getData();
    //  resident.restraintCurrentHist.prescription.medication
  }, []);

  const submit = () => {
    const fetchUrl = `/v1/crreviews`;
    const toPost = {
      pharmacistComment: pharmacistComment,
      residentId: resident.id,
      actions: actions,
    };
    fetch(fetchUrl, {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(toPost),
    })
      .then((res) => {
        if (!res.ok) {
          //show error
          notify("Could not post new review", {type: "warning"});
          return;
        }
        //redirect to residents list
        notify("Success", {type: "success"});
        redirect("/crcreate");
      })
      .catch((err) => {
        console.log(err)
        notify("Could not post new review", {type: "warning"});
      });
  };

  return (
    <div>
      <Typography>Resident: {resident.nameOrId}</Typography>
      <Typography>Facility: {resident.facility.name}</Typography>
      <TextField
        id="standard-multiline-flexible"
        label="Pharmacist comment"
        multiline
        value={pharmacistComment}
        onChange={(e) => setPharmacistComment(e.target.value)}
        style={{ width: "600px", paddingTop: "10px", paddingBottom: "10px" }}
      />
      <br />

      {actions.map((action, i) => {
        return (
          <div style={{ paddingBottom: "10px" }}>
            {action.medication.generic}:&nbsp;&nbsp;&nbsp;
            {action.isRestraint && (
              <TextField
                value={action.recommendation}
                onChange={setAction(i)}
              />
            )}
          </div>
        );
      })}

      <Button variant="contained" color="primary" onClick={submit}>
        Submit
      </Button>
      <div style={{ paddingTop: "10px" }}>
        <PrescriptionCurrentTable
          rows={resident.currentPsaPrescriptions}
          reload={getData}
        />
      </div>
      <div style={{ paddingTop: "10px" }}>
        <PrescriptionHistoryTable rows={resident.ceasedPsaPrescriptions} />
      </div>

      <div style={{ paddingTop: "10px" }}>
        <ReviewHistory reviews={resident.crReviews} />
      </div>
    </div>
  );
};

export default CreateCr;
