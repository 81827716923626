import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
  useDataProvider,
} from "react-admin";

import { Line } from "react-chartjs-2";

const NumResGraph = (props) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!props.facilityId) return;
    dataProvider
      .getOne("numresidentsgraph", { id: props.facilityId })
      .then((resp) => {
        if (!resp.data) return;
        setData({
          datasets: [
            {
              lineTension: 0,
              data: resp.data.map((numRes) => ({
                y: numRes.numResidents,
                x: new Date(numRes.date.substring(0, 10) + ":"),
              })),
            },
          ],
        });
      });
  }, [props.facilityId]);

  const options = {
    line: {
      tension: 0,
    },
    bezierCurve: false,
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "month",
            tooltipFormat: "YYYY-MM-DD",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  //do the reac-tadmin stuff for 'get history'
  return (
    <div style={{ maxWidth: "1000px", maxHeight: "400" }}>
      <Line data={data} options={options} width={800} height={200} />
    </div>
  );
};

const FacilityFilter = (props) => {
  if (props.filterValues && props.filterValues.facilityId) {
    // facId = props.filterValues.facilityId
    props.setFacilityId(props.filterValues.facilityId);
  }
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Organisation"
        source="organisationId"
        reference="organisations"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Facility"
        source="facilityId"
        reference="facilities"
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export const NumResidentsListed = (props) => {
  const [facilityId, setFacilityId] = useState(0);

  return (
    <div>
      <NumResGraph facilityId={facilityId} />

      <List
        {...props}
        bulkActionButtons={false}
        title="Num Residents"
        exporter={false}
        filters={<FacilityFilter setFacilityId={setFacilityId} />}
      >
        <Datagrid>
          <TextField source="facility.name" label="Facility" sortable={false} />
          <TextField
            source="facility.organisation.name"
            label="Organisation"
            sortable={false}
          />
          <DateField source="date" label="Date" sortable={false} />
          <TextField
            source="numResidents"
            label="Num residents"
            sortable={false}
          />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  );
};
