import React from 'react';
import { List, Datagrid, TextField, Filter, ReferenceInput, AutocompleteInput } from 'react-admin';

const FacilityFilter = (props) => (
    <Filter {...props}>
        
        <ReferenceInput label="Organisation" source="organisationId" reference="organisations" >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Facility" source="facilityId" reference="facilities" filter={{module: "ams"}}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
)

export const AmsListed = (props) => {
    return (
        <>
            <List {...props}
                bulkActionButtons={false}
                title="AMS"
                exporter={false}
                filters={<FacilityFilter/>}
            >
                <Datagrid rowClick={(id, basePath, record) => `/ams/${id}`}>
                    <TextField source="name" sortable={false} label="Facility" />
                    <TextField source="organisation.name" sortable={false} label="Organisation" />
                </Datagrid>
            </List>
        </>
    )
};