import React from "react";
import {
  Edit,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  TextField,
  CheckboxGroupInput,
} from "react-admin";

export const FacEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="extUsername" label="TDONTD external username" />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label="Organisation"
            source="organisationId"
            reference="organisations"
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <CheckboxGroupInput
        source="uiModules"
        choices={[
          { id: 1, name: "dashboard" },
          { id: 2, name: "psa" },
          { id: 3, name: "ams" },
          { id: 4, name: "cr" },
          { id: 5, name: "poly" },
          { id: 6, name: "microbe" },
          { id: 8, name: "tdontd" },
        ]}
      />
      <CheckboxGroupInput source="psaApiFieldsDisabled" 
          choices={[
            { id: "regular", name: "Regular" },
            { id: "regularDose", name: "Regular Dose" },
            { id: "prn", name: "PRN" },
            { id: "indication", name: "Is medication use identified as a restraint" },
            {id: "indicationType", name: "Reason for prescribing"},
            {id: "prnNumDoses", name: "PRN Num Doses"},
            {id: "prnNumDays", name: "PRN Num Days"},
            {id: "decisionDate", name: "Date first prescribed"},
            {id: "lastReviewDate", name: "Date of last formal medication review"},
            {id: "prnCircumstance", name: "If PRN, circumstances of use"},
            {id: "informedConsentDate", name: "Informed Consent Date"},
            {id: "informedConsentHow", name: "Informed Consent How"},
            {id: "behaviourPlanImplemented", name: "Behaviour Plan Implemented"},
            {id: "behaviourPlanDate", name: "Behaviour Plan Date"},
            {id: "effectivenessMonitoring", name: "Effectiveness and side effects"},
            // {id: "startDated", name: "Start date"},
            {id: "ceaseDate", name: "Cease Date"},
          ]}
          label="Self-assessment edit fields disabled when externalID is set for resident"
        />

      <CheckboxGroupInput 
        source="psaMedicationClasses"
        parse={value => {
          console.log("parse VAL:", value);
          return value.map(v => {
            return {
              id: v,
            }
          })
          // return value
        }}
        format={value => {
          if (value === null) {
            return value
          }
          console.log("format VAL:", value);
          return value.map(v => v.id)
        }}
        label="Medication classes available for self-assessments"
        choices={[
          {id: 1, name: "Antipsychotic"},
          {id: 2, name: "Benzodiazepine"},
          {id: 3, name: "Antidepressant"},
          {id: 4, name: "Antiepileptic"},
          // {id: 5, name: "Anthelmintic"},
          // {id: 6, name: "Antibacterial"},
          // {id: 7, name: "Antifungal"},
          // {id: 8, name: "Antiprotazoal"},
          // {id: 9, name: "Antiviral"},
          {id: 10, name: "Lithium carbonate"},
          {id: 11, name: "Antidementia"},
          {id: 12, name: "Opioids"},
          {id: 13, name: "Hypnotics"},
          {id: 15, name: "Cannabinoid"},
          {id: 16, name: "Antiandrogen"},
          {id: 17, name: "Stimulant"},
          {id: 18, name: "Dopamine antagonist"},
          {id: 19, name: "5HT3 antagonist"},
          {id: 20, name: "Antiparkinsonian"},
        ]}
      />
    </SimpleForm>
  </Edit>
);
