import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { WarningRounded, Description } from "@material-ui/icons";
import removeDoc from "../../files/Removal_of_residents.pdf";
import {
  DialogTitle,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
} from "@material-ui/core";

export const DeleteRequestDialog = ({
  open,
  handleDeleteRequest,
  handleCease,
  handleDecease,
  dialogDet,
  setDialogDet,
}) => {
  const [reason, setReason] = useState(undefined);
  const [deleteReason, setDeleteReason] = useState("");

  const handleSetDeleteReason = (e) => {
    setDeleteReason(e.target.value);
  };

  useEffect(() => {
    setDialogDet((prev) => {
      return {
        ...prev,
        reason: deleteReason,
      };
    });
  }, [deleteReason]);

  const doHandleDeleteRequest = () => {
    handleDeleteRequest(true);
  };

  const doHandleDecease = () => {
    handleDecease(true);
  };

  const doHandleCease = () => {
    handleCease(true);
  };

  const cancelDeleteRequest = () => {
    setReason(undefined);
    setDeleteReason("");
    handleDeleteRequest(false);
  };

  return (
    <Dialog
      open={open}
      onClose={cancelDeleteRequest}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Confirm row removal`}</DialogTitle>
      <DialogContent>
        <div style={{ width: "600px" }}></div>
        <WarningRounded color="error" fontSize="large" />
        <DialogContentText id="alert-dialog-description">
          <span style={{ color: "red" }}>
            If unsure, please refer to this document
          </span>

          <a
            href={removeDoc}
            download
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <IconButton color="primary" component="span">
              <Description />
            </IconButton>
          </a>
          <br />
        </DialogContentText>

        <FormControl component="fieldset">
          <FormLabel component="legend">Select removal reason</FormLabel>
          <RadioGroup
            aria-label="reason"
            name="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            <FormControlLabel
              value="ceased"
              control={<Radio />}
              label="Ceased medication"
            />
            <FormControlLabel
              value="deceased"
              control={<Radio />}
              label="Deceased / discharged"
            />
            <FormControlLabel
              value="delete"
              control={<Radio />}
              label="Delete request for errors"
            />
          </RadioGroup>
        </FormControl>
        <div style={{ clear: "both" }}>
          <br />
          {reason === "deceased" && (
            <span style={{ color: "red" }}>
              {`
            Confirm that ${dialogDet.nameOrId} is deceased or has been discharged. All other prescriptions for ${dialogDet.nameOrId} will also be removed.`}
            </span>
          )}
          {reason === "ceased" && (
            <span style={{ color: "red" }}>
              {`
            Confirm that ${dialogDet.nameOrId} is/was no longer taking ${dialogDet.medication} while admitted`}
            </span>
          )}
          {reason === "delete" && (
            <>
              <span
                style={{ color: "red" }}
              >{`This row should only be deleted if it has been entered in error. 
            All associated history will also be deleted and may impact generated reports.`}</span>
              <br />
            </>
          )}
          <br />
          {reason === "delete" && (
            <>
              <TextField
                autoFocus
                label="Reason for delete request"
                required
                fullWidth
                value={deleteReason}
                onChange={(e) => handleSetDeleteReason(e)}
                placeholder="required"
              />
              <br />
              <br />
              <span>
                An email will be sent for a request to delete this row and you
                will receive a response when complete
              </span>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDeleteRequest}>Cancel</Button>
        {reason === "deceased" && (
          <Button onClick={doHandleDecease} variant="contained" color="primary">
            Set resident as Deceased/Discharged
          </Button>
        )}
        {reason === "ceased" && (
          <Button onClick={doHandleCease} variant="contained" color="primary">
            Set prescription as ceased
          </Button>
        )}
        {reason === "delete" && (
          <Button
            onClick={doHandleDeleteRequest}
            variant="contained"
            color="primary"
            autoFocus
            disabled={!(deleteReason.length > 0)}
          >
            Request row delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
