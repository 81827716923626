// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import fetch from 'cross-fetch';
import React from "react";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDataProvider } from "react-admin";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { withStyles } from "@material-ui/core/styles";
import { Popper } from "@material-ui/core";

const filtOpts = createFilterOptions();

const styles = (theme) => ({
  popper: {
    maxWidth: "fit-content",
  },
});

const CustPopper = (props) => (
  <Popper {...props} style={styles.popper} placement="bottom-start" />
);

export function ResourceSelect({
  displayKey,
  idKey, //for the row obj
  innerIdKey, //for resp obj: default 'id'
  resource,
  // selectedValue,
  creatable,
  clearOnBlur,
  style,
  // filter,
  onChange,
  disabled,
  objKey,
  value,
  width,
  getOptionDisabled = () => false,
  ...rest
}) {
  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [gotResp, setGotResp] = React.useState(false);
  // const loading = open && options.length === 0;
  const loading = open && !gotResp;
  // const [selected, setSelected] = React.useState(value)
  const [filter, setFilter] = React.useState(rest.filter);

  React.useEffect(() => {
    setFilter(rest.filter);
    // setSelected({[displayKey]: ""})
  }, [JSON.stringify(rest.filter)]);

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      const response = await dataProvider.getList(resource, {
        pagination: {
          page: 1,
          perPage: 999,
        },
        sort: {},
        filter: filter,
      });

      if (active) {
        setGotResp(true);
        setOptions(response.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      noOptionsText="No options"
      autoHighlight
      autoSelect
      getOptionDisabled={getOptionDisabled}
      PopperComponent={CustPopper}
      fullWidth
      disabled={disabled}
      disableClearable
      style={style}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setGotResp(false);
      }}
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          //on blur, set value
          // setSelected({
          //     [displayKey]: newValue,
          // });
        } else if (newValue && newValue.inputValue) {
          //create new
          // setSelected({[displayKey]: newValue.inputValue});
          onChange({
            [idKey]: 0,
            [objKey]: {
              [displayKey]: newValue.inputValue,
            },
          });
        } else {
          //from list
          // setSelected(newValue);
          onChange({
            [idKey]: newValue[innerIdKey],
            [objKey]: newValue,
          });
        }
      }}
      clearOnBlur={clearOnBlur}
      freeSolo
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }
        if (typeof option === "string") {
          return option;
        }
        if (options.inputValue) {
          return option.inputValue;
        }
        return option[displayKey] || "";
      }}
      options={options}
      loading={loading}
      filterOptions={(options, params) => {
        const filtered = filtOpts(options, params);

        const hasExactMatch =
          filtered.filter((result) => {
            if (
              result[displayKey].toLowerCase() ===
              params.inputValue.toLowerCase()
            ) {
              return true;
            }
            return false;
          }).length > 0;

        if (creatable && params.inputValue !== "" && !hasExactMatch) {
          filtered.push({
            inputValue: params.inputValue,
            [displayKey]: `Create: "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      renderOption={(option, { inputValue }) => {
        const matches = match(option[displayKey], inputValue);
        const parts = parse(option[displayKey], matches);

        if (option[displayKey].startsWith("Create")) {
          return (
            <div>
              <span>{option[displayKey]}</span>
            </div>
          );
        }

        return (
          <div style={{ width: "300px" }}>
            {parts.map((part, index) => {
              return (
                <span
                  key={index}
                  style={{
                    width: "200px",
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              );
            })}
          </div>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            style={{ width: width }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}

ResourceSelect.defaultProps = {
  innerIdKey: "id",
  filter: {},
  value: {},
  displayKey: "",
  idKey: "",
  resource: "",
  objKey: "",
};
