// in src/customRoutes.js
import React from "react";
import { Route, Redirect } from "react-router";
import ForgotPw from "./unauthed/forgotPw";
import ResetPw from "./unauthed/resetPw";
import Landing from "./modules/dashboard/landing";
import PsychotroicSelfassessment from "./modules/psa/psychotropic-selfassessment";
import AuditResults from "./modules/psa/audit-results";
// import Ams from './ams'
// import AmsResults from './ams-results';
import {
  Ams,
  AmsResults,
  PolyResults,
  MicrobeShow,
  Psa,
  // TdontdCreate,
} from "./modules";
// import PolyResults from './poly-result';
import { AmsAd } from "./altmodules/ams";
import { PsaAd } from "./altmodules/psa";
import { DashboardAd } from "./altmodules/dashboard";
import { PolyAd } from "./altmodules/poly";
import { CrAd } from "./altmodules/cr";
import PsaResults from "./modules/psa/psa-results";
import CreateCr from "./modules/cr/create-cr";
import { Export } from "./modules/export/export";
import { TdontdItemCreate } from "./modules/tdontd/tdontd-item-create";
import { TdontdItemEdit } from "./modules/tdontd/tdontd-item-edit";
import TdontdExternalLogin from './unauthed/TdontdExternalLogin';
import TdontdExternal from './unauthed/TdontdExternal';

export default [
  <Route exact path="/forgotpw" component={ForgotPw} noLayout />,
  <Route exact path="/undefined" render={() => <Redirect to="/login" />} />,
  <Route path="/resetpw" component={ResetPw} noLayout />,
  <Route path="/tdontd-external-login" component={TdontdExternalLogin} noLayout />,
  <Route path="/tdontd-external" render={ props => {
    return <TdontdExternal {...props} 
    resource={'tdontd'} 
    />
  }} noLayout />,
  // <Route exact path="/psascurrent/:facilityId" component={PsychotroicSelfassessment} />,
  <Route exact path="/psa/:facilityId" component={Psa} />,
  <Route exact path="/psa/:facilityId/results" component={PsaResults} />,
  <Route
    exact
    path="/psa/:facilityId/results/print"
    component={PsaResults}
    noLayout
  />,
  <Route exact path="/ams/:facilityId" component={Ams} />,
  <Route exact path="/ams/:facilityId/results" component={AmsResults} />,
  <Route
    exact
    path="/ams/:facilityId/results/print"
    component={AmsResults}
    noLayout
  />,
  // <Route exact path="/psas/:saId" render={(props)=><PsychotroicSelfassessment {...props} readonly={true}/>} />,
  // <Route exact path="/psas/:saId/auditresults" component={AuditResults} />,
  // <Route exact path="/psas/:saId/auditresults/print" component={AuditResults} noLayout/>,
  <Route exact path="/dashboards/:orgId" component={Landing} />,
  <Route exact path="/dashboards/:orgId/print" component={Landing} noLayout />,
  <Route exact path="/polyresult/:facId" component={PolyResults} />,
  <Route
    exact
    path="/polyresult/:facId/print"
    component={PolyResults}
    noLayout
  />,
  <Route exact path="/microbe/:visitId/show" component={MicrobeShow} />,
  <Route
    exact
    path="/microbe/:visitId/show/print"
    component={MicrobeShow}
    noLayout
  />,

  <Route exact path="/crcreate/:residentId/newcr" component={CreateCr} />,

  <Route exact path="/export" component={Export} />,

  <Route path="/tdontd-item/create" component={TdontdItemCreate} />,
  <Route path="/tdontd-item/:tdontdItemId" component={TdontdItemEdit} />,

  // ad pages
  <Route exact path="/amsad" component={AmsAd} />,
  <Route exact path="/psaad" component={PsaAd} />,
  <Route exact path="/dashboardad" component={DashboardAd} />,
  <Route exact path="/polyad" component={PolyAd} />,
  <Route exact path="/crad" component={CrAd} />,
];
