import React, { useContext } from 'react'

import {
    TableCell,
    TextField,
    Checkbox
} from '@material-ui/core'

import {
    isDateValid
} from './funcs'


import IndicationAutocomplete from './IndicationAutocomplete'
import TextMaskCustom from '../../components/TextMaskCustom'
import { ApiDisabledContext } from './psa'

export const PsaHistoryInputs = ({
    regular,
    regularDose,
    prn,
    indication,
    indicationType,
    behaviour,
    prnNumDoses,
    prnNumDays,
    altRestraint,
    participate,
    decisionDate,
    repsInformed,
    freqMonitoring,
    lastReviewDate,
    onChange,
    headInput,
    indications,
    prnCircumstance,
    informedConsentDate,
    informedConsentHow,
    behaviourPlanDate,
    behaviourPlanImplemented,
    effectivenessMonitoring,
    comments
}) => {

    const sanitiseNumInput = (numStr) => {
        if (numStr === "0") {
            return 0
        }
        const numStrLen = numStr.length
        if (numStr[numStrLen-1] === ".") {
            return numStr
        }
        return Number(numStr) || numStr
    }

    const ctx = useContext(ApiDisabledContext)

    return (
        <React.Fragment>
            <TableCell className={headInput}>
                <Checkbox
                    checked={regular}
                    onChange={(e) => onChange({regular: e.target.checked})}
                    disabled={ctx.apiDisabled("regular")}
                />
            </TableCell>
            <TableCell className={headInput}>
                <TextField
                    value={regularDose == 0 ? "" : regularDose}
                    onChange={(e) => onChange({regularDose: sanitiseNumInput(e.target.value) || 0})}
                    disabled={ctx.apiDisabled("regularDose")}
                />
            </TableCell>
            <TableCell className={headInput}>
                <Checkbox
                    checked={prn}
                    onChange={e => onChange({prn: e.target.checked})}
                    disabled={ctx.apiDisabled("prn")}
                />
            </TableCell>
            <TableCell className={headInput}>
                <TextField 
                    value={prnNumDoses}
                    onChange={(e) => onChange({prnNumDoses: e.target.value === "" ? null : sanitiseNumInput(e.target.value)})}
                    disabled={ctx.apiDisabled("prnNumDoses")}
                />
            </TableCell>
            <TableCell className={headInput}>
                <TextField 
                    value={prnNumDays || ""}
                    onChange={(e) => onChange({prnNumDays: sanitiseNumInput(e.target.value) || null})}
                    disabled={ctx.apiDisabled("prnNumDays")}
                />
            </TableCell>
            <TableCell className={headInput}>
                {/* <Checkbox 
                    checked={indication}
                    onChange={e => onChange({indication: e.target.checked})}
                /> */}
                <IndicationAutocomplete
                    indications={indications}
                    value={indicationType}
                    onChange={onChange}
                    disabled={ctx.apiDisabled("indicationType")}
                />
            </TableCell>
            <TableCell className={headInput}>
                <TextField 
                    value={prnCircumstance || ""}
                    onChange={(e) => onChange({prnCircumstance: e.target.value})}
                    multiline
                    disabled={ctx.apiDisabled("prnCircumstance")}
                />
                
            </TableCell>
            <TableCell className={headInput}>
                <TextField 
                    value={informedConsentDate}
                    onChange={(e) => onChange({informedConsentDate: e.target.value || null})}
                    error={!isDateValid(informedConsentDate)}
                    InputProps={{
                        inputComponent: TextMaskCustom
                    }}
                    disabled={ctx.apiDisabled("informedConsentDate")}
                />

                {/* <TextField 
                    value={behaviour}
                    onChange={(e) => onChange({behaviour: e.target.value})}
                    style={{width: "150px"}}
                    multiline
                /> */}
            </TableCell>
            <TableCell className={headInput}>
                {/* <TextField 
                    value={altRestraint}
                    style={{width: "150px"}}
                    onChange={(e) => onChange({altRestraint: e.target.value})}
                    multiline
                /> */}
                 <TextField 
                    value={informedConsentHow}
                    style={{width: "150px"}}
                    onChange={(e) => onChange({informedConsentHow: e.target.value})}
                    multiline
                    disabled={ctx.apiDisabled("informedConsentHow")}
                />
            </TableCell>
            <TableCell className={headInput}>
                {/* identified as restraint */}
                <Checkbox 
                    checked={!indication}
                    onChange={e => onChange({indication: !e.target.checked})}
                    disabled={ctx.apiDisabled("indication")}
                />
                {/* <TextField 
                    value={participate}
                    style={{width: "150px"}}
                    onChange={(e) => onChange({participate: e.target.value})}
                    multiline
                /> */}
            </TableCell>
            <TableCell className={headInput}>
                 <Checkbox 
                    checked={behaviourPlanImplemented}
                    onChange={e => onChange({behaviourPlanImplemented: e.target.checked})}
                    disabled={ctx.apiDisabled("behaviourPlanImplemented")}
                />
                {/* <TextField 
                    value={decisionDate}
                    onChange={(e) => onChange({decisionDate: e.target.value || null})}
                    error={!isDateValid(decisionDate)}
                    InputProps={{
                        inputComponent: TextMaskCustom
                    }}
                /> */}
            </TableCell>
            <TableCell  align="center" className={headInput}>
                <TextField 
                    value={behaviourPlanDate}
                    onChange={(e) => onChange({behaviourPlanDate: e.target.value || null})}
                    error={!isDateValid(behaviourPlanDate)}
                    InputProps={{
                        inputComponent: TextMaskCustom
                    }}
                    disabled={ctx.apiDisabled("behaviourPlanDate")}
                />
                {/* <Checkbox 
                    checked={repsInformed}
                    onChange={e => onChange({repsInformed: e.target.checked})}
                /> */}
            </TableCell>
            <TableCell className={headInput}>
                <Checkbox 
                    checked={effectivenessMonitoring}
                    onChange={e => onChange({effectivenessMonitoring: e.target.checked})}
                    disabled={ctx.apiDisabled("effectivenessMonitoring")}
                />
                {/* <TextField 
                    value={freqMonitoring}
                    onChange={(e) => onChange({freqMonitoring: e.target.value})}
                    multiline
                /> */}
            </TableCell>
            <TableCell className={headInput}>
                <TextField 
                    value={lastReviewDate}
                    onChange={(e) => onChange({lastReviewDate: e.target.value || null})}
                    error={!isDateValid(lastReviewDate)}
                    InputProps={{
                        inputComponent: TextMaskCustom
                    }}
                    disabled={ctx.apiDisabled("lastReviewDate")}
                />
            </TableCell>
            <TableCell className={headInput}>
                <TextField 
                    value={comments || ""}
                    onChange={(e) => onChange({comments: e.target.value})}
                    multiline
                />
                
            </TableCell>

            
        </React.Fragment>
    )
}