import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";


const classes = {
    abody: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '6em',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
};


const TdontdExternalLogin = (props) => {


    // constructor(props) {
    //     super(props)
    //     this.state = {
    //         username: "",
    //         msg: "",
    //         reCaptcha: ""
    //     }
    // }

    const [name, setName] = useState("");
    const [msg, setMsg] = useState("");
    const [reCaptcha, setReCaptcha] = useState("");

    const history = useHistory()



    const handleSubmit = (e) => {
        e.preventDefault()
        fetch(`/ext/tdontd-external-login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'applicaton/json'
            },
            body: JSON.stringify(
                {
                    name: name,
                    reCaptcha: reCaptcha
                })
        }).then(resp => {
            if (!resp.ok) {
                setMsg("Unknown username")
            }
            window.grecaptcha.reset();
            return resp.json();
        }).then(json => {
            console.log(json)
            localStorage.setItem("token", json.token)
            // history.push()
            // JSON.parse(msg)
            history.push("/tdontd-external")
        }).catch(err => {
            setMsg("Unknown username")
        })

    }

const recaptchaRef = React.createRef();
return (
    <div style={classes.abody}>
        <Typography variant="display1">
            TDONTD External Login
        </Typography>
        
        <form onSubmit={handleSubmit} required>
            <TextField
                style={{marginBottom: "10px"}}
                label="Username"
                helperText="enter your supplied username"
                onChange={(e) => setName(e.target.value)}
            /><br/>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lfbp_wUAAAAAFcDrR8Owko9z36Ck2gpaE7zpQT7"
                onChange={(value) => setReCaptcha(value)}
            />
            <Button type="submit" variant="contained" style={{marginTop: "10px"}} disabled={!reCaptcha || !name}>Submit</Button>
        </form>
        <br/>
        <Typography>
            {msg}
        </Typography>
    </div>
    );
    
}

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme

export default TdontdExternalLogin;





