import React from 'react';
import {
    Edit,
    SimpleForm,
    NumberInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    Toolbar,
    SaveButton,
    DeleteButton
} from 'react-admin'
import CancelButton from '../../components/CancelButton'
import {dateParser} from '../../funcs/funcs'

export const PolyEdit = (props) => (
    <Edit
        title="Edit polypharmacy submission"
        {...props}
    >
        
        <SimpleForm toolbar={<PolyEditToolbar/>}>
        {/* <SimpleForm > */}

            <DateInput source="date" defaultValue={new Date()} parse={dateParser} validate={[required()]}/>
            <ReferenceInput 
                label="Select facility" 
                source="facilityId" 
                reference="facilities" 
                validate={[required()]}
                filter={{module: "poly"}}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="numResidentsReg" helperText="Number of residents on 9 or more regular medications" label="Regular" validate={[required()]}/>
        </SimpleForm>
    </Edit>

);

const PolyEditToolbar = props => (
    <Toolbar {...props} style={{flex: 1, display: "flex", justifyContent: "space-between"}}>
        <SaveButton />
        <CancelButton />
        <DeleteButton/>

    </Toolbar>
)