import React from 'react';



export const Growth = ({
    growthVal
}) => {

    const numElems = 9
    const size = 18
    const width = size * numElems

    return (
        <div
            style={{
                width: `${width}px`,
                borderStyle: "solid",
                borderWidth: "1px",
                backgroundImage: `linear-gradient(to right, green, yellow, red)`
                
            }}
        >
            {[...Array(numElems).keys()].map(i => (
                <div style={{
                    backgroundColor: growthVal <= i && "white",
                    float: "left",
                    width:  `${size}px`,
                    height: `${size}px`,
                    borderStyle: "solid",
                    borderWidth: "1px",
                    boxSizing: "border-box",
                }}>
                
            </div>
            ))}

        
        <div>

        </div>

        </div>
    )
    
}