import React from 'react'
import {Typography} from '@material-ui/core'

export const PsaAd = props => (
    <div>
        <Typography variant="body2" style={{ maxWidth: "700px"}}>
        <span style={{fontWeight: "bold",}}>This is a demonstration page.</span> Please contact Choice Aged Care on 1300 275 908 or email rmmr@choiceagedcare.com.au to arrange access any of the PPASS-3 Tool modules.
        </Typography>
        <br/>
        <Typography variant="body2" style={{maxWidth: "700px"}}>
        Complete the psychotropic self-assessments in the format outlined by the Aged Care Quality and Safety Commission. 
        Psychotropic, chemical restraint and separate antipsychotic, benzodiazepine and antidepressant usage is benchmarked against the Red-USe trial results, the Choice Aged Care client average and the organisations average.
        </Typography>
        <br/>
        
        <img src={require('../files/PSA_table.png')} />
        <img src={require('../files/PSA_results.png')} />
    </div>
);