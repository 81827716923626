import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  AutocompleteInput,
  ReferenceInput,
  required,
} from "react-admin";

import React from "react";

export const TdontdAuditCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          filter={{ module: "tdontd" }}
          label="Facility"
          source="facilityId"
          reference="facilities"
          validate={[required()]}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DateInput source="auditDate" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
