import React, { useEffect, useReducer, useState } from "react";
import { SelectInput } from "react-admin";

import { Table, Paper, TextField, Button, Typography } from "@material-ui/core";
import TdontdTableAll from "./tdontd-table-all";
import TdontdTableUti from "./tdontd-table-uti";
import { useHistory } from "react-router";
import { useNotify } from "ra-core";
import TdontdItemHeader from "./tdontd-item-header";
import { authLevel } from "../../funcs/funcs";

function reducer(state, action) {
  switch (action.type) {
    case "utiRowVal":
      //action.index
      //action.payload
      return {
        ...state,
        rowsUti: [
          ...state.rowsUti.map((row, i) =>
            i === action.index ? { ...row, ...action.payload } : row
          ),
        ],
      };

    case "allRowVal":
      return {
        ...state,
        rowsAll: [
          ...state.rowsAll.map((row, i) =>
            i === action.index ? { ...row, ...action.payload } : row
          ),
        ],
      };

    case "setAll":
      return { ...state, ...action.payload };

    case "save":
      return { ...state };

    case "updateRoot":
      return {
        ...state,
        ...action.payload,
      };
    case "updateResident":
      return {
        ...state,
        resident: {
          ...state.resident,
          ...action.payload,
        },
      };
    case "updateUser":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
  }
}

const initialState = {
  facility: {},
  audit: {},
  resident: {},
  user: {},
  rowsUti: [],
  rowsAll: [],
};

export const TdontdItemEdit = (props) => {
  const id = props.match.params.tdontdItemId;
  const { goBack } = useHistory();

  const [state, dispatch] = useReducer(reducer, initialState);
  const notify = useNotify();

  useEffect(() => {
    getData();
  }, []);

  const handleSave = () => {
    fetch(`/v1/tdontd-item/${id}`, {
      method: "PUT",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(state),
    })
      .then((resp) => {
        if (!resp.ok) {
          notify("Could not update", {type: "warning"});
        } else {
          notify("Success", {type: "success"});
          goBack();
        }
      })
      .catch((e) => {
        notify("Could not update", {type: "warning"});
      });
  };

  const getData = () => {
    //using audit date
    fetch(`/v1/tdontd-item/${id}`, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    })
      .then((resp) =>
        resp.json().then((theResp) => {
          dispatch({ type: "setAll", payload: theResp });
        })
      )
      .catch((e) => {
        //do something
      });
  };

  return (
    <div>
      <Paper>
        <TdontdItemHeader
          resident={state.resident}
          user={state.user}
          audit={state.audit}
          dispatch={dispatch}
          racsId={state.racsId}
          catheter={state.catheter}
        />
        <br />
        <TdontdTableAll rows={state.rowsAll} dispatch={dispatch} />
        <br />
        <br />
        {state.rowsUti.length > 0 && (
          <>
            <TdontdTableUti rows={state.rowsUti} dispatch={dispatch} />
            <br />
            <br />
          </>
        )}

        <Button
          disabled={authLevel() === "external"}
          onClick={handleSave}
          style={{ margin: "10px" }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </Paper>
    </div>
  );
};
