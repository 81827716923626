import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { DeleteOutlined, Edit } from "@material-ui/icons";
import { Tick } from "../../components/Tick";
import { DeleteDialog } from "./delete-dialog";
import { useNotify } from "react-admin";
import { ACTIONS } from "./ams";
import { authLevel } from "../../funcs/funcs";

const AmsRowLockedMem = ({ row, dispatch, getRows, history }) => {
  const facilityId = window.location.hash.split("/")[2];
  const notify = useNotify();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    const fetchPath = `v1/ams/${facilityId}/${row.id}`;
    fetch(fetchPath, {
      method: "DELETE",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((res) => {
      if (res.status !== 200) {
        notify("Could not delete, contact support", {type: "warning"});
      } else {
        notify("Row deleted", {type: "success"});
        getRows();
      }
      setDeleteOpen(false);
    });
  };

  return (
    <TableRow key={row.id}>
      <TableCell>
        <IconButton
          size="small"
          disabled={authLevel() === "external"}
          onClick={() =>
            dispatch({ type: ACTIONS.TOGGLE_UNLOCKED, payload: { id: row.id } })
          }
        >
          <Edit />
        </IconButton>
      </TableCell>
      <TableCell>
        <DeleteDialog
          open={deleteOpen}
          handleDeleteClose={handleDeleteClose}
          handleDelete={handleDelete}
          name={row.resident.nameOrId}
          medication={row.medication.generic}
        />
        <Tooltip title="Delete row">
          <IconButton
            disabled={authLevel() === "external"}
            size="small"
            onClick={() => setDeleteOpen(true)}
          >
            <DeleteOutlined />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          // open={!!row.resident.deceased}
          // title="Deceased"
          title={!!row.resident.deceased ? "Deceased" : ""}
          disableFocusListener
        >
          <Typography
            variant="body2"
            onClick={() => history.push(`/residents/${row.residentId}`)}
          >{`${row.resident.nameOrId || ""}${
            !!row.resident.deceased ? "*" : ""
          }`}</Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.medication.generic}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.route}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">{row.prn ? <Tick /> : <></>}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.site.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.indication.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.signsSymptoms}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">
          {row.pathologyRequested ? <Tick /> : <></>}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.startDate}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">
          {row.stopReviewDateListed ? <Tick /> : <></>}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.ceaseDate}</Typography>
      </TableCell>
    </TableRow>
  );
};

export const AmsRowLocked = React.memo(
  AmsRowLockedMem,
  (prevProps, nextProps) => {
    return prevProps.row.id === nextProps.row.id;
    // return true
  }
);
