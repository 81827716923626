import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Check} from '@material-ui/icons'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
// });

// export default function ReviewHistory(props) {
//   return (
//     <div>
//         {props.reviews.map(review => 
//             <>
//             <br/><br/>
//             <div>
//                 Date: {review.createdAt}
//             </div>
//             <div>
//                 Pharmacist comment: {review.pharmacistComment}
//             </div>
//             {review.actions.map(action => 
//                 <>
//                 <div>medication: {action.psaPrescriptionHistory.psaPrescription.medication.generic}</div>
//                 <div>recommendation: {action.recommendation}</div>
//                 <div>outcome: {action.outcome || "None"}</div>
//                 </>
//             )}
//             </>
//         )}
        
//     </div>
//   );
// }

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });
  
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.createdAt.substring(0, 10)}
          </TableCell>
        <TableCell>{row.pharmacistComment}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Medication</TableCell>
                      <TableCell>Pharmacist recommendation</TableCell>
                      <TableCell>Outcome</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.actions.map((action) => (
                      <TableRow key={action.id}>
                          <TableCell>{action.medication.generic}</TableCell>
                          <TableCell>{action.recommendation}</TableCell>
                          <TableCell>{action.outcome}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  export default function ReviewHistory(props) {
    return (
      <TableContainer component={Paper}>
          <Typography variant="h6" gutterBottom component="div" style={{paddingLeft: "10px", paddingTop: "10px"}}>
                  Previous CR Reviews
            </Typography>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Date created</TableCell>
              <TableCell>Pharmacist comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.reviews.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }