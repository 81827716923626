import * as React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NewCrField = ({ source, record = {} }) => <Link to={{ pathname: `/crcreate/${record.id}/newcr` }}><span>new CR</span></Link>;

NewCrField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default NewCrField;




